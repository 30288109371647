/* Backdrop to dim the background */

.popup-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10;
}

/* Main calculation result container */
.calculation-result {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: auto;
  max-height: 80vh;
  overflow: scroll;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;
  z-index: 20;
  text-align: center;
  font-family: 'Arial', sans-serif;
  animation: slideIn 0.3s ease-in-out;
}

.logo-calculation {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 10vh;
  min-height: 100px;
}

.logo-calculation img {
  height: 50%;
  width: auto;
}

/* Animate the popup for a smoother appearance */
@keyframes slideIn {
  from {
    transform: translate(-50%, -60%);
    opacity: 0;
  }

  to {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}

/* Wrapper for result boxes */
.result-boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  margin-top: 20px;
}

/* Individual result box */
.result-box {
  flex: 1 1 calc(50% - 10px);
  /* Responsive two-column layout */
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
  transition:
    transform 0.2s,
    box-shadow 0.2s;
  cursor: pointer;
}

.result-box-h {
  font-size: 1.2rem;
  font-weight: 600;
  color: black;
}

.result-box strong {
  font-size: 1.2rem;
  font-weight: 600;
  color: black;
}

/* Hover effect to make the box interactive */
.result-box:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

/* Green box */
.result-box.green {
  background-color: #d4edda;
  border: 1px solid #28a745;
  color: #155724;
}

/* Yellow box */
.result-box.yellow {
  background-color: #fff3cd;
  border: 1px solid #ffc107;
  color: #856404;
}

/* Red box */
.result-box.red {
  background-color: #f8d7da;
  border: 1px solid #dc3545;
  color: #721c24;
}

/* White box (for missing data) */
.result-box.white {
  background-color: #e2e3e5;
  border: 1px solid #d6d8db;
  color: #6c757d;
}

/* Overall result conclusion message */
.overall-result-conclusion {
  height: 8vh;
  width: 80%;
  margin-top: 30px;
  margin-left: 10%;
  font-size: 20px;
  font-weight: bold;
  border-radius: 50px;
  overflow: hidden;
  display: flex;
  align-items: center;
  animation: resultSlide 2s ease-in-out;
}

.overall-result-conclusion div {
  height: 100%;
}

@keyframes resultSlide {
  from {
    width: 0%;
  }

  to {
    width: 80%;
  }
}

.overall-result-conclusion p {
  position: fixed;
  left: 10%;
  width: 80%;
  text-shadow: 1px 0px 1px white;
  text-align: center;
  background: transparent;
}

.btns-calculation-result {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 5%;
}

.btns-calculation-result button {
  margin-top: 20px;
  padding: 12px 25px;
  background-color: rgba(7, 51, 108, 1);
  border: none;
  border-radius: 8px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btns-calculation-result button:hover {
  background-color: rgba(7, 51, 108, 0.9);
  transform: scale(1.02);
}

/* Responsive adjustments */
@media (max-width: 768px) and (min-width: 550px) {
  .result-box {
    flex: 1 1 100%;
    /* Full-width on small screens */
  }

  .logo-calculation {
    height: 18vw;
    min-height: 0;
  }

  .overall-result-conclusion {
    width: 100%;
    margin-left: 0;
  }

  @keyframes resultSlide {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  .overall-result-conclusion p {
    font-size: 0.9rem;
  }
}

@media (max-width: 550px) {
  .result-box {
    flex: 1 1 100%;
    /* Full-width on small screens */
  }

  .logo-calculation {
    height: 18vw;
    min-height: 0;
  }

  .overall-result-conclusion {
    width: 100%;
    margin-left: 0;
  }

  @keyframes resultSlide {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  .overall-result-conclusion p {
    font-size: 0.8rem;
    padding: 0 5%;
  }
}
