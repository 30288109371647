.terms-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
}

.terms-container h1 {
  color: rgba(5, 106, 76, 1);
  font-weight: 700;
  text-align: center;
  margin-bottom: 30px;
}

.terms-container p {
  margin: 10px 0;
  font-size: 1em;
  color: #333;
}

.terms-container ul {
  list-style: none;
  padding-left: 20px;
}

.terms-container ul li {
  margin-bottom: 20px;
}

.terms-container ul ul {
  list-style-type: disc;
  padding-left: 40px;
}

.terms-container ul ul li {
  margin-bottom: 10px;
}

.terms-container h2 {
  color: rgba(7, 51, 108, 1);
  font-size: 1.4em;
  margin-bottom: 15px;
  margin-top: 30px;
}

.terms-container strong {
  color: #000;
}

.terms-container a {
  color: rgba(5, 106, 76, 1);
  text-decoration: none;
}

.terms-container a:hover {
  text-decoration: underline;
}
