/* Calculation result container */
.calculation-result-mortgage {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: auto;
  max-height: 80vh;
  overflow: scroll;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  padding: 10px 12%;
  z-index: 20;
  text-align: center;
  font-family: 'Arial', sans-serif;
  animation: slideIn 0.3s ease-in-out;
}

/* Heading styling */
.calculation-result-mortgage h1 {
  font-size: 28px;
  margin-bottom: 20px;
  color: rgba(7, 51, 108, 1);
  letter-spacing: 1.2px;
  font-weight: bold;
}

/* Paragraph styling */
/* Styling for the result container paragraphs */
.calculation-result-mortgage p {
  font-size: 18px;
  margin: 10px 0;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Animation for value appearance */
@keyframes popIn {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* Valid value style (colorful gradient) */
.animated-value.valid {
  background: linear-gradient(90deg, #00c6ff, #0072ff); /* Blue gradient */
  background-clip: none;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  animation: popIn 0.6s ease-in-out;
  transition: all 0.3s ease;
}

/* Invalid value style */
.animated-value.invalid {
  color: #ff4d4d; /* Vibrant red for error */
  font-weight: bold;
  animation: popIn 0.6s ease-in-out;
  transition: all 0.3s ease;
}

.calculation-result-mortgage p span {
  font-size: 20px;
  font-weight: 600;
  text-align: start;
}
/* Advice section styling */
.advice-section {
  font-size: 18px;
  margin: 10px 0;
  padding: 12px; /* Add padding for a better layout */
  border-radius: 8px; /* Rounded corners */
  color: black; /* Dark text for readability */
  position: relative; /* Positioning for icon */
}

/* Additional styles for the span */
.advice-section span {
  border-radius: 40px;
  width: 100%;
  padding: 10px;
  font-weight: bold; /* Make the advice label bold */
}

/* Optional: style for the tips text */
.advice-section span:last-child {
  font-weight: normal; /* Normal weight for the actual tips */
}

/* Button styling */
.calculation-result-mortgage button {
  background: rgba(7, 51, 108, 1);
  color: #fff;
  border: none;
  padding: 12px 20px;
  margin-top: 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition:
    all 0.3s ease-in-out,
    transform 0.2s;
}

.calculation-result-mortgage button:hover {
  transform: scale(1.05); /* Slight zoom-in */
}
@media (min-width: 550px) and (max-width: 1200px) {
  .calculation-result-mortgage {
    padding: 10px 7%;
  }
  .calculation-result-mortgage p span {
    font-size: 1.2rem;
    border-radius: 10px;
    text-align: start;
  }
}

@media (max-width: 550px) {
  .calculation-result-mortgage {
    padding: 10px 1%;
  }
  .calculation-result-mortgage p span {
    font-size: 0.9rem;
    border-radius: 4px;
    text-align: start;
  }
}
