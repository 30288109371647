.chat-list-slider,
.chat-list-slider-active,
.select-chat-btn {
  height: 0;
  width: 0;
  visibility: hidden;
}
.chats-page-holder {
  height: 100%;
  width: 100%;
  padding: calc(10 * var(--vw-unit));
  display: flex;
  flex-direction: column;
  gap: calc(5 * var(--vw-unit));
}
.chats-main-heading {
  font-size: calc(16 * var(--vw-unit));
  font-weight: 600;
}
.chats-page-content {
  display: flex;
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
}

.chat-list {
  width: 30%;
  border-right: calc(1 * var(--vw-unit)) solid #ddd;
  background-color: #fff;
  overflow-y: auto;
}
.chat-list-heading {
  width: 100%;
  height: calc(50 * var(--vw-unit));
  background-color: rgb(13, 71, 161, 1);
  color: white;
  font-size: calc(18 * var(--vw-unit));
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-list-data {
  height: 90%;
  padding: calc(20 * var(--vw-unit)) calc(10 * var(--vw-unit));
  overflow-y: auto;
}

.chat-item {
  padding: calc(10 * var(--vw-unit));
  margin-bottom: calc(10 * var(--vw-unit));
  cursor: pointer;
  transition: background-color 0.3s ease;
  background: transparent;
  border-bottom: calc(1 * var(--vw-unit)) solid rgba(216, 216, 216, 1);
}

.chat-item:hover {
  background-color: #f0f0f0;
}

.chat-item.active {
  background-color: rgba(243, 244, 246, 1);
  border-bottom: calc(1 * var(--vw-unit)) solid gray;
}

.chat-item p {
  margin: 0;
  font-size: calc(16 * var(--vw-unit));
  font-weight: bold;
  color: #333;
}

.chat-item small {
  font-size: calc(12 * var(--vw-unit));
  display: block;
  color: #777;
  margin-top: calc(5 * var(--vw-unit));
}

.chat-messages {
  width: 70%;
  padding: 0 calc(40 * var(--vw-unit));
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.messages-container {
  height: 80%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: calc(10 * var(--vw-unit)) calc(20 * var(--vw-unit));
  margin: calc(20 * var(--vw-unit)) 0;
  border: calc(1 * var(--vw-unit)) solid rgba(223, 224, 235, 1);
}

.message-item {
  margin-bottom: calc(15 * var(--vw-unit));
  font-size: calc(16 * var(--vw-unit));
}

.my-message {
  text-align: right;
}
.my-message .message-details {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  height: auto;
  gap: calc(10 * var(--vw-unit));
}
.my-message .message-text {
  height: auto;
  width: auto;
  max-width: 50%;
  background-color: rgb(13, 71, 161, 1);
  padding: calc(10 * var(--vw-unit));
  border-radius: calc(8 * var(--vw-unit));
  color: white;
  text-align: center;
  font-size: calc(14 * var(--vw-unit));
  font-weight: 500;
  word-break: break-all;
}

.their-message {
  text-align: left;
}
.their-message .message-details {
  display: flex;
  align-items: center;
  height: auto;
  gap: calc(10 * var(--vw-unit));
}
.their-message .message-text {
  height: auto;
  width: auto;
  max-width: 50%;
  background-color: rgba(154, 236, 218, 0.2);
  padding: calc(10 * var(--vw-unit));
  border-radius: calc(8 * var(--vw-unit));
  color: rgb(13, 71, 161, 1);
  text-align: center;
  font-size: calc(14 * var(--vw-unit));
  font-weight: 500;
}

.me-icon,
.you-icon {
  height: calc(40 * var(--vw-unit));
}

.message-time {
  font-size: calc(12 * var(--vw-unit));
  color: #888;
  margin-left: calc(10 * var(--vw-unit));
}

.message-input-container {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  height: calc(50 * var(--vw-unit));
  width: 100%;
}

.message-input {
  flex-grow: 1;
  padding: calc(10 * var(--vw-unit));
  font-size: calc(16 * var(--vw-unit));
  border: calc(1 * var(--vw-unit)) solid #ccc;
  border-radius: calc(5 * var(--vw-unit));
}

.send-button {
  padding: calc(10 * var(--vw-unit)) calc(15 * var(--vw-unit));
  margin-left: calc(10 * var(--vw-unit));
  background-color: rgb(13, 71, 161, 1);
  color: white;
  border: none;
  border-radius: calc(5 * var(--vw-unit));
  cursor: pointer;
}

.send-button:disabled {
  background-color: #ccc;
}

.send-button:hover:enabled {
  transform: scale(1.02);
}

.payments-pop-chats {
  position: fixed;
  height: 77%;
  width: 76%;
  background: linear-gradient(180deg, #e2fff9 0%, #e7f5ff 103.19%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: calc(20 * var(--vw-unit));
  z-index: 20;
}
.payments-pop-chats p {
  font-size: calc(25 * var(--vw-unit));
  font-weight: 600;
  color: black;
}
.payments-pop-chats button {
  height: calc(60 * var(--vw-unit));
  width: calc(150 * var(--vw-unit));
  border: none;
  background-color: rgb(13, 71, 161, 1);
  color: white;
  border-radius: calc(5 * var(--vw-unit));
  font-size: calc(20 * var(--vw-unit));
}

.select-chat-message,
.no-chats-message {
  margin-top: calc(50 * var(--vw-unit));
  text-align: center;
  font-size: calc(16 * var(--vw-unit));
  color: rgb(13, 71, 161, 1);
  font-weight: 600;
}

@media (min-width: 550px) and (max-width: 1200px) {
  .chat-list {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  .chat-list-slider,
  .chat-list-slider-active,
  .select-chat-btn {
    visibility: visible;
  }

  .chats-page-holder {
    gap: calc(10 * var(--vw-tab));
  }

  .payments-pop-chats {
    position: fixed;
    height: 77%;
    width: 76%;
    background: linear-gradient(180deg, #e2fff9 0%, #e7f5ff 103.19%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: calc(20 * var(--vw-tab));
    z-index: 20;
  }
  .payments-pop-chats p {
    font-size: calc(22 * var(--vw-tab));
    font-weight: 600;
    color: black;
  }
  .payments-pop-chats button {
    height: calc(60 * var(--vw-tab));
    width: calc(150 * var(--vw-tab));
    border: none;
    background-color: rgb(13, 71, 161, 1);
    color: white;
    border-radius: calc(5 * var(--vw-tab));
    font-size: calc(20 * var(--vw-tab));
  }
  .chats-main-heading {
    font-size: calc(16 * var(--vw-tab));
    margin-left: calc(15 * var(--vw-tab));
  }
  .select-chat-btn {
    position: absolute;
    right: calc(15 * var(--vw-tab));
    top: calc(10 * var(--vw-tab));
    height: calc(38 * var(--vw-tab));
    width: calc(120 * var(--vw-tab));
    padding: calc(3 * var(--vw-tab)) calc(10 * var(--vw-tab));
    border: none;
    border-radius: calc(4 * var(--vw-tab));
    background-color: rgb(13, 71, 161, 1);
    color: white;
    font-size: calc(14 * var(--vw-tab));
    font-weight: 500;
  }
  .chat-list-slider {
    position: fixed;
    right: calc(-510 * var(--vw-tab));
    height: 70%;
    width: calc(500 * var(--vw-tab));
    background-color: white;
    border: calc(1 * var(--vw-tab)) solid rgb(13, 71, 161, 1);
    border-radius: calc(8 * var(--vw-tab));
    z-index: 10;
    overflow: hidden;
    transition: right 0.3s ease-in-out;
  }
  .chat-list-slider-active {
    position: fixed;
    right: 0;
    height: 70%;
    width: calc(500 * var(--vw-tab));
    background-color: white;
    border: calc(0.3 * var(--vw-tab)) solid rgb(13, 71, 161, 1);
    box-shadow: calc(20 * var(--vw-tab)) calc(20 * var(--vw-tab))
      calc(20 * var(--vw-tab)) calc(20 * var(--vw-tab)) rgba(216, 216, 216, 1);
    border-radius: calc(8 * var(--vw-tab));
    z-index: 10;
    transition: right 0.3s ease-in-out;
    overflow: hidden;
  }
  .chat-list-heading {
    height: calc(50 * var(--vw-tab));
    font-size: calc(18 * var(--vw-tab));
  }
  .chat-list-data {
    height: 90%;
    padding: calc(10 * var(--vw-tab));
  }
  .chat-item {
    padding: calc(30 * var(--vw-tab));
    height: calc(80 * var(--vw-tab));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: transparent;
  }
  .chat-item.active {
    background-color: rgba(154, 236, 218, 0.2);
  }
  .chat-item p {
    font-size: calc(18 * var(--vw-tab));
  }
  .chat-item small {
    font-size: calc(12 * var(--vw-tab));
  }
  .chat-messages {
    width: 100%;
    height: 99%;
    padding: calc(5 * var(--vw-tab)) calc(20 * var(--vw-tab));
    background-color: #fff;
    display: flex;
    flex-direction: column;
  }

  .messages-container {
    height: 85%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: calc(10 * var(--vw-tab)) calc(20 * var(--vw-tab));
    margin: calc(20 * var(--vw-tab)) 0;
    border: calc(1 * var(--vw-tab)) solid rgba(223, 224, 235, 1);
  }

  .message-item {
    margin-bottom: calc(15 * var(--vw-tab));
    font-size: calc(16 * var(--vw-tab));
  }

  .my-message {
    text-align: right;
  }
  .my-message .message-details {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    height: auto;
    gap: calc(10 * var(--vw-tab));
  }
  .my-message .message-text {
    height: auto;
    width: auto;
    max-width: 50%;
    background-color: rgb(13, 71, 161, 1);
    padding: calc(10 * var(--vw-tab));
    border-radius: calc(8 * var(--vw-tab));
    color: white;
    text-align: center;
    font-size: calc(14 * var(--vw-tab));
    font-weight: 500;
  }

  .their-message {
    text-align: left;
  }
  .their-message .message-details {
    display: flex;
    align-items: center;
    height: auto;
    gap: calc(10 * var(--vw-tab));
  }
  .their-message .message-text {
    height: auto;
    width: auto;
    max-width: 50%;
    background-color: rgba(154, 236, 218, 0.2);
    padding: calc(10 * var(--vw-tab));
    border-radius: calc(8 * var(--vw-tab));
    color: rgb(13, 71, 161, 1);
    text-align: center;
    font-size: calc(14 * var(--vw-tab));
    font-weight: 500;
  }

  .me-icon,
  .you-icon {
    height: calc(40 * var(--vw-tab));
  }

  .message-time {
    font-size: calc(12 * var(--vw-tab));
    color: #888;
    margin-left: calc(10 * var(--vw-tab));
  }

  .message-input-container {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    height: calc(50 * var(--vw-tab));
    width: 100%;
  }

  .message-input {
    flex-grow: 1;
    padding: calc(10 * var(--vw-tab));
    font-size: calc(16 * var(--vw-tab));
    border: calc(1 * var(--vw-tab)) solid #ccc;
    border-radius: calc(5 * var(--vw-tab));
  }

  .send-button {
    padding: calc(10 * var(--vw-tab)) calc(15 * var(--vw-tab));
    margin-left: calc(10 * var(--vw-tab));
    background-color: rgb(13, 71, 161, 1);
    color: white;
    border: none;
    border-radius: calc(5 * var(--vw-tab));
    cursor: pointer;
  }

  .send-button:disabled {
    background-color: #ccc;
  }

  .send-button:hover:enabled {
    transform: scale(1.02);
  }

  .payments-pop-chats {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: #333;
    z-index: 20;
  }

  .select-chat-message,
  .no-chats-message {
    margin-top: calc(50 * var(--vw-tab));
    text-align: center;
    font-size: calc(16 * var(--vw-tab));
    color: rgb(13, 71, 161, 1);
    font-weight: 600;
  }
}

@media (max-width: 550px) {
  .chat-list {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  .chat-list-slider,
  .chat-list-slider-active,
  .select-chat-btn {
    visibility: visible;
  }

  .chats-page-holder {
    position: fixed;
    top: calc(125 * var(--vw-tab));
    gap: calc(20 * var(--vw-mob));
    padding-top: calc(25 * var(--vw-tab));
  }
  .chats-main-heading {
    font-size: calc(16 * var(--vw-mob));
    margin-left: calc(15 * var(--vw-mob));
  }
  .chats-page-content {
    height: 100%;
  }
  .payments-pop-chats {
    position: fixed;
    background: linear-gradient(180deg, #e2fff9 0%, #e7f5ff 103.19%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: calc(20 * var(--vw-mob));
    z-index: 20;
  }
  .payments-pop-chats p {
    font-size: calc(22 * var(--vw-mob));
    font-weight: 600;
    color: black;
    text-align: center;
  }
  .payments-pop-chats button {
    height: calc(60 * var(--vw-mob));
    width: calc(150 * var(--vw-mob));
    border: none;
    background-color: rgb(13, 71, 161, 1);
    color: white;
    border-radius: calc(5 * var(--vw-mob));
    font-size: calc(20 * var(--vw-mob));
  }
  .select-chat-btn {
    position: absolute;
    right: calc(15 * var(--vw-mob));
    top: calc(30 * var(--vw-mob));
    height: calc(38 * var(--vw-mob));
    width: calc(120 * var(--vw-mob));
    padding: calc(3 * var(--vw-mob)) calc(10 * var(--vw-mob));
    border: none;
    border-radius: calc(4 * var(--vw-mob));
    background-color: rgb(13, 71, 161, 1);
    color: white;
    font-size: calc(14 * var(--vw-mob));
    font-weight: 500;
  }
  .chat-list-slider {
    position: fixed;
    right: calc(-510 * var(--vw-mob));
    height: 70%;
    width: calc(350 * var(--vw-mob));
    background-color: white;
    border: calc(1 * var(--vw-mob)) solid rgb(13, 71, 161, 1);
    border-radius: calc(8 * var(--vw-mob));
    z-index: 10;
    overflow: hidden;
    transition: right 0.3s ease-in-out;
  }
  .chat-list-slider-active {
    position: fixed;
    right: 0;
    height: 70%;
    width: calc(350 * var(--vw-mob));
    background-color: white;
    border: calc(0.3 * var(--vw-mob)) solid rgb(13, 71, 161, 1);
    box-shadow: calc(20 * var(--vw-mob)) calc(20 * var(--vw-mob))
      calc(20 * var(--vw-mob)) calc(20 * var(--vw-mob)) rgba(216, 216, 216, 1);
    border-radius: calc(8 * var(--vw-mob));
    z-index: 10;
    transition: right 0.3s ease-in-out;
    overflow: hidden;
  }
  .chat-list-heading {
    height: calc(50 * var(--vw-mob));
    font-size: calc(18 * var(--vw-mob));
  }
  .chat-list-data {
    height: 90%;
    padding: calc(10 * var(--vw-mob));
  }
  .chat-item {
    padding: calc(30 * var(--vw-mob));
    height: calc(80 * var(--vw-mob));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: transparent;
  }
  .chat-item.active {
    background-color: rgba(154, 236, 218, 0.2);
  }
  .chat-item p {
    font-size: calc(18 * var(--vw-mob));
  }
  .chat-item small {
    font-size: calc(12 * var(--vw-mob));
  }
  .chat-messages {
    width: 100%;
    height: 80%;
    padding: calc(5 * var(--vw-mob)) calc(20 * var(--vw-mob));
    background-color: #fff;
    display: flex;
    flex-direction: column;
  }

  .messages-container {
    height: 85%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: calc(10 * var(--vw-mob)) calc(20 * var(--vw-mob));
    margin: calc(20 * var(--vw-mob)) 0;
    border: calc(1 * var(--vw-mob)) solid rgba(223, 224, 235, 1);
  }

  .message-item {
    margin-bottom: calc(15 * var(--vw-mob));
    font-size: calc(16 * var(--vw-mob));
  }

  .my-message {
    text-align: right;
  }
  .my-message .message-details {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    height: auto;
    gap: calc(10 * var(--vw-mob));
  }
  .my-message .message-text {
    height: auto;
    width: auto;
    max-width: 50%;
    background-color: rgb(13, 71, 161, 1);
    padding: calc(10 * var(--vw-mob));
    border-radius: calc(8 * var(--vw-mob));
    color: white;
    text-align: center;
    font-size: calc(14 * var(--vw-mob));
    font-weight: 500;
  }

  .their-message {
    text-align: left;
  }
  .their-message .message-details {
    display: flex;
    align-items: center;
    height: auto;
    gap: calc(10 * var(--vw-mob));
  }
  .their-message .message-text {
    height: auto;
    width: auto;
    max-width: 50%;
    background-color: rgba(154, 236, 218, 0.2);
    padding: calc(10 * var(--vw-mob));
    border-radius: calc(8 * var(--vw-mob));
    color: rgb(13, 71, 161, 1);
    text-align: center;
    font-size: calc(14 * var(--vw-mob));
    font-weight: 500;
  }

  .me-icon,
  .you-icon {
    height: calc(40 * var(--vw-mob));
  }

  .message-time {
    font-size: calc(12 * var(--vw-mob));
    color: #888;
    margin-left: calc(10 * var(--vw-mob));
  }

  .message-input-container {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    height: calc(50 * var(--vw-mob));
    width: 100%;
  }

  .message-input {
    flex-grow: 1;
    padding: calc(10 * var(--vw-mob));
    font-size: calc(14 * var(--vw-mob));
    border: calc(1 * var(--vw-mob)) solid #ccc;
    border-radius: calc(5 * var(--vw-mob));
  }

  .send-button {
    padding: calc(10 * var(--vw-mob)) calc(15 * var(--vw-mob));
    margin-left: calc(10 * var(--vw-mob));
    background-color: rgb(13, 71, 161, 1);
    color: white;
    border: none;
    border-radius: calc(5 * var(--vw-mob));
    cursor: pointer;
  }

  .send-button:disabled {
    background-color: #ccc;
  }

  .send-button:hover:enabled {
    transform: scale(1.02);
  }

  .payments-pop-chats {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: #333;
    z-index: 20;
  }

  .select-chat-message,
  .no-chats-message {
    margin-top: calc(50 * var(--vw-mob));
    text-align: center;
    font-size: calc(16 * var(--vw-mob));
    color: rgb(13, 71, 161, 1);
    font-weight: 600;
  }
}
