@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

:root {
  --vw-unit: calc((100 / 1440) * 1vw);
  --vw-tab: calc((100 / 768) * 1vw);
  --vw-mob: calc((100 / 390) * 1vw);
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

*::-webkit-scrollbar {
  width: 0;
  height: 0;
  color: transparent;
}

body {
  height: auto;
}

p {
  padding: 0;
  margin: 0;
}

.menu-close-main,
.menu-icon-main {
  visibility: hidden;
}

.dashboard-page {
  width: 100vw;
  height: calc(100vh); /* Full viewport height */
  min-height: 520px; /* Minimum height of 600px */
  max-height: 900px; /* Maximum height of 900px */
  overflow-y: hidden;
}

.session-expired {
  height: 80vh;
  width: 80vw;
  left: 10vw;
  top: 10vh;
  background-image: linear-gradient(
    45deg,
    rgb(184, 239, 228),
    rgba(231, 245, 255, 1)
  );
  box-shadow: calc(-8 * var(--vw-unit)) calc(8 * var(--vw-unit))
    calc(34 * var(--vw-unit)) 0 rgba(0, 0, 0, 0.08);
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: calc(40 * var(--vw-unit));
  z-index: 1000;
  border-radius: calc(8 * var(--vw-unit));
}
.session-expired p {
  color: rgb(13, 71, 161);
  font-size: calc(35 * var(--vw-unit));
  font-weight: 600;
}
.session-expired button {
  height: calc(40 * var(--vw-unit));
  width: calc(220 * var(--vw-unit));
  border: none;
  border-radius: calc(5 * var(--vw-unit));
  background-color: rgb(13, 71, 161);
  color: white;
  font-size: calc(20 * var(--vw-unit));
}
.tutorial-holder {
  position: absolute;
  top: calc(70 * var(--vw-unit));
  left: calc(220 * var(--vw-unit));
  height: calc(520 * var(--vw-unit));
  width: auto;
  border: none;
  border-radius: calc(8 * var(--vw-unit));
  box-shadow: calc(-8 * var(--vw-unit)) calc(8 * var(--vw-unit))
    calc(34 * var(--vw-unit)) 0 rgba(0, 0, 0, 0.08);
  outline: calc(5000 * var(--vw-unit)) solid rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  background-color: white;
  z-index: 10;
}
#tab-tutorial {
  height: 0;
  width: 0;
  visibility: hidden;
}
#mobile-tutorial {
  height: 0;
  width: 0;
  visibility: hidden;
}
.tutorial-bar {
  height: 8%;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: calc(5 * var(--vw-unit)) solid rgba(0, 0, 0, 0.2);
}
.tutorial-btn {
  height: calc(37 * var(--vw-unit));
  width: calc(100 * var(--vw-unit));
  border: none;
  border-radius: calc(4 * var(--vw-unit));
  color: rgb(13, 71, 161);
  background-color: white;
  font-size: calc(15 * var(--vw-unit));
  font-weight: 600;
}
.tutorial-btn-active {
  height: calc(37 * var(--vw-unit));
  width: calc(100 * var(--vw-unit));
  border: none;
  border-radius: calc(4 * var(--vw-unit));
  background-color: white;
  color: rgba(41, 205, 178, 1);
  font-size: calc(16 * var(--vw-unit));
  font-weight: 600;
}
.tutorial-img-holder {
  height: 92%;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.tutorial-img {
  height: 100%;
  animation: fadeIn 1s ease-in-out;
  border: calc(2 * var(--vw-unit)) solid rgba(110, 240, 219, 1);
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.left-arrow-holder {
  position: absolute;
  left: calc(-60 * var(--vw-unit));
  top: 8%;
  display: flex;
  height: 92%;
  align-items: center;
}
.left-arrow-holder img {
  height: calc(60 * var(--vw-unit));

  cursor: pointer;
}
.right-arrow-holder {
  position: absolute;
  top: 8%;
  right: calc(-60 * var(--vw-unit));
  display: flex;
  height: 92%;
  align-items: center;
  z-index: 20;
}
.right-arrow-holder img {
  height: calc(60 * var(--vw-unit));
  cursor: pointer;
}

.left-menu-container {
  width: calc(290 * var(--vw-unit));
  height: 100%;
}

.logo-dashboard-img {
  position: absolute;
  left: calc(54 * var(--vw-unit));
  top: max(5%, 20 * var(--vw-unit));
  width: calc(190 * var(--vw-unit));
}

.menu-container-dashboard {
  position: absolute;
  top: max(14%, 80 * var(--vw-unit));
  height: max(70%, 300 * var(--vw-unit));
  width: calc(291 * var(--vw-unit));
  display: flex;
  flex-direction: column;
  gap: calc(20 * var(--vw-unit));
}

.menu-item-box {
  height: 8%;
  width: 100%;
  padding: calc(10 * var(--vw-unit)) 0 calc(10 * var(--vw-unit))
    calc(32 * var(--vw-unit));
  display: flex;
  align-items: center;
  cursor: pointer;
}

.menu-item-box img {
  width: calc(18 * var(--vw-unit));
  margin-left: calc(0 * var(--vw-unit));
}

.menu-item-box p {
  font-size: calc(20 * var(--vw-unit));
  font-weight: 500;
  color: rgba(46, 46, 46, 1);
  margin-left: calc(16 * var(--vw-unit));
}

.menu-item-box-active {
  height: 9%;
  width: 100%;
  padding: calc(10 * var(--vw-unit)) 0 calc(10 * var(--vw-unit))
    calc(32 * var(--vw-unit));
  background-color: rgb(13, 71, 161);
  display: flex;
  align-items: center;
  cursor: pointer;
}

.menu-item-box-active img {
  width: calc(20 * var(--vw-unit));
  margin-left: calc(0 * var(--vw-unit));
}

.menu-item-box-active p {
  font-size: calc(21 * var(--vw-unit));
  font-weight: 500;
  color: rgba(110, 240, 219, 1);
  margin-left: calc(16 * var(--vw-unit));
}

.provider-search-hover,
.buyer-post-hover {
  position: fixed;
  visibility: hidden;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: calc(10 * var(--vw-unit));
  z-index: 1010;
}

#menuitem-provider-search:hover {
  .provider-search-hover {
    visibility: visible;
    top: calc(155 * var(--vw-unit));
    left: calc(140 * var(--vw-unit));
    background-color: rgba(110, 240, 219, 1);
  }

  .provider-search-hover p {
    font-family:
      system-ui,
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      Roboto,
      Oxygen,
      Ubuntu,
      Cantarell,
      'Open Sans',
      'Helvetica Neue',
      sans-serif;
    font-style: italic;
    font-size: calc(10 * var(--vw-unit));
    margin: 0 calc(10 * var(--vw-unit)) 0 calc(10 * var(--vw-unit));
    padding: 0;
    align-self: center;
    color: black;
  }
}

#menuitem-buyer-post:hover {
  .buyer-post-hover {
    visibility: visible;
    top: calc(220 * var(--vw-unit));
    left: calc(140 * var(--vw-unit));
    background-color: rgba(110, 240, 219, 1);
  }

  .buyer-post-hover p {
    font-family:
      system-ui,
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      Roboto,
      Oxygen,
      Ubuntu,
      Cantarell,
      'Open Sans',
      'Helvetica Neue',
      sans-serif;
    font-style: italic;
    font-size: calc(10 * var(--vw-unit));
    margin: 0 calc(10 * var(--vw-unit)) 0 calc(10 * var(--vw-unit));
    padding: 0;
    align-self: center;
    color: black;
  }
}

.logout-container-box {
  position: absolute;
  top: max(93%, 550px);
  height: min(8%, 50 * var(--vw-unit));
  width: 20%;
  padding: calc(10 * var(--vw-unit)) 0 calc(10 * var(--vw-unit))
    calc(32 * var(--vw-unit));
  display: flex;
  align-items: center;
  cursor: pointer;
}

.logout-container-box img {
  height: 80%;
  margin-left: calc(0 * var(--vw-unit));
}

.logout-container-box p {
  font-size: calc(20 * var(--vw-unit));
  font-weight: 500;
  color: rgba(46, 46, 46, 1);
  margin-left: calc(16 * var(--vw-unit));
}

.top-bar-main {
  position: absolute;
  top: 0;
  right: 0;
  height: max(12%, 80 * var(--vw-unit));
  width: calc(1150 * var(--vw-unit));
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.top-menu-container {
  width: calc(318 * var(--vw-unit));
  height: 100%;
  margin-right: calc(32 * var(--vw-unit));
  display: flex;
  align-items: center;
}
.tutorial-icon-m {
  height: 0;
  width: 0;
  visibility: hidden;
}

.top-post-button {
  visibility: hidden;
  height: calc(36 * var(--vw-unit));
  width: calc(116 * var(--vw-unit));
  border: none;
  border-radius: calc(4 * var(--vw-unit));
  display: flex;
  align-items: center;
  justify-content: center;
  gap: calc(10 * var(--vw-unit));
  background-color: rgb(13, 71, 161);
  font-size: calc(14 * var(--vw-unit));
  font-weight: 500;
  color: white;
  margin-right: calc(20 * var(--vw-unit));
}

.top-post-button img {
  height: calc(18 * var(--vw-unit));
}

.top-chats-icon {
  height: calc(44 * var(--vw-unit));
  margin-right: calc(30 * var(--vw-unit));
  margin-left: calc(20 * var(--vw-unit));
  display: flex;
  align-items: center;
  cursor: pointer;
}

.chats-count-main {
  position: absolute;
  top: 30%;
  right: calc(130 * var(--vw-unit));
  height: calc(15 * var(--vw-unit));
  width: calc(35 * var(--vw-unit));
  padding: 0;
  display: flex;
  justify-content: start;
  align-items: center;
  color: rgb(13, 71, 161);
  z-index: 0;
  font-weight: 800;
  font-size: calc(15 * var(--vw-unit));
}

.top-chats-icon img {
  height: calc(28 * var(--vw-unit));
}

.top-notifications-icon {
  height: calc(44 * var(--vw-unit));
  margin-right: calc(40 * var(--vw-unit));
  display: flex;
  align-items: center;
  cursor: pointer;
}

.notifications-count-main {
  position: absolute;
  top: calc(21 * var(--vw-unit));
  right: calc(80 * var(--vw-unit));
  height: calc(15 * var(--vw-unit));
  width: calc(35 * var(--vw-unit));
  padding: 0;
  display: flex;
  justify-content: start;
  align-items: center;
  color: rgb(13, 71, 161);
  z-index: 0;
  font-weight: 800;
  font-size: calc(15 * var(--vw-unit));
}

.top-notifications-icon img {
  height: calc(28 * var(--vw-unit));
}

.profile-img-main {
  height: calc(40 * var(--vw-unit));
  width: calc(40 * var(--vw-unit));
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
}

.profile-img-main img {
  height: 100%;
  border-radius: 50%;
  align-self: center;
}

.content-container-main {
  position: absolute;
  top: max(12%, 80 * var(--vw-unit));
  left: calc(290 * var(--vw-unit));
  height: max(87%, 500 * var(--vw-unit));
  width: calc(1112 * var(--vw-unit));
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: scroll;
  background-color: rgba(249, 249, 249, 1);
  padding: calc(1 * var(--vw-unit));
  border-right: none;
}

@media (min-width: 550px) and (max-width: 1200px) {
  body {
    height: calc(900 * var(--vw-tab));
    overflow: scroll;
  }

  .dashboard-page {
    width: 100%;
  }
  .session-expired p {
    color: rgb(13, 71, 161);
    font-size: calc(35 * var(--vw-tab));
    font-weight: 600;
  }
  .session-expired button {
    height: calc(40 * var(--vw-tab));
    width: calc(220 * var(--vw-tab));
    border: none;
    border-radius: calc(5 * var(--vw-tab));
    background-color: rgb(13, 71, 161);
    color: white;
    font-size: calc(20 * var(--vw-tab));
  }
  .tutorial-holder {
    position: absolute;
    top: calc(70 * var(--vw-tab));
    left: calc(60 * var(--vw-tab));
    height: auto;
    width: calc(100% - (120 * var(--vw-tab)));
    border: none;
    border-radius: calc(8 * var(--vw-tab));
    box-shadow: calc(-8 * var(--vw-tab)) calc(8 * var(--vw-tab))
      calc(34 * var(--vw-tab)) 0 rgba(0, 0, 0, 0.08);
    outline: calc(5000 * var(--vw-tab)) solid rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    background-color: white;
    z-index: 10;
  }
  #tab-tutorial {
    height: auto;
    width: auto;
    visibility: visible;
  }
  #web-tutorial {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  #mobile-tutorial {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  .tutorial-bar {
    height: 8%;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: calc(5 * var(--vw-tab)) solid rgba(0, 0, 0, 0.2);
  }
  .tutorial-btn {
    height: calc(37 * var(--vw-tab));
    width: calc(100 * var(--vw-tab));
    border: none;
    border-radius: calc(4 * var(--vw-tab));
    color: rgb(13, 71, 161);
    background-color: white;
    font-size: calc(15 * var(--vw-tab));
    font-weight: 600;
  }
  .tutorial-btn-active {
    height: calc(37 * var(--vw-tab));
    width: calc(100 * var(--vw-tab));
    border: none;
    border-radius: calc(4 * var(--vw-tab));
    background-color: white;
    color: rgba(41, 205, 178, 1);
    font-size: calc(16 * var(--vw-tab));
    font-weight: 600;
  }
  .tutorial-img-holder {
    height: 92%;
    display: flex;
    justify-content: center;
    overflow: hidden;
  }
  .tutorial-img {
    width: 98%;
    animation: fadeIn 1s ease-in-out;
    border: calc(2 * var(--vw-tab)) solid rgba(110, 240, 219, 1);
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .left-arrow-holder {
    left: calc(-50 * var(--vw-tab));
    z-index: 15;
  }
  .left-arrow-holder img {
    height: calc(50 * var(--vw-tab));
  }
  .right-arrow-holder {
    right: calc(-50 * var(--vw-tab));
    z-index: 15;
  }
  .right-arrow-holder img {
    height: calc(50 * var(--vw-tab));
  }

  .menu-close-main {
    visibility: visible;
    position: absolute;
    top: calc(19 * var(--vw-tab));
    left: calc(315 * var(--vw-tab));
    color: rgba(46, 46, 46, 1);
    font-size: calc(20 * var(--vw-tab));
    font-weight: 600;
    z-index: 1000;
  }

  .left-menu-container {
    position: absolute;
    left: -600px;
    width: calc(360 * var(--vw-tab));
    height: calc(1111 * var(--vw-tab));
    background-color: white;
    z-index: 1000;
    transition: left 0.5s ease-in-out;
  }

  .left-menu-container-active {
    position: absolute;
    left: 0;
    width: calc(360 * var(--vw-tab));
    min-height: calc(900 * var(--vw-tab));
    height: 100%;
    background-color: white;
    z-index: 100;
    transition: left 0.5s ease-in-out;
  }

  .logo-dashboard-img {
    position: absolute;
    left: calc(77 * var(--vw-tab));
    top: calc(59 * var(--vw-tab));
    width: calc(207 * var(--vw-tab));
  }

  .menu-container-dashboard {
    position: absolute;
    top: calc(119 * var(--vw-tab));
    height: calc(533 * var(--vw-tab));
    width: calc(360 * var(--vw-tab));
    display: flex;
    flex-direction: column;
    gap: calc(20 * var(--vw-tab));
  }

  .menu-item-box {
    height: calc(44 * var(--vw-tab));
    width: 100%;
    padding: calc(10 * var(--vw-tab)) 0 calc(10 * var(--vw-tab))
      calc(32 * var(--vw-tab));
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .menu-item-box img {
    width: calc(18 * var(--vw-tab));
    margin-left: calc(0 * var(--vw-tab));
  }

  .menu-item-box p {
    font-size: calc(21 * var(--vw-tab));
    font-weight: 500;
    color: rgba(46, 46, 46, 1);
    margin-left: calc(16 * var(--vw-tab));
  }

  .menu-item-box-active {
    height: calc(44 * var(--vw-tab));
    width: 100%;
    padding: calc(10 * var(--vw-tab)) 0 calc(10 * var(--vw-tab))
      calc(32 * var(--vw-tab));
    background-color: rgb(13, 71, 161);
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .menu-item-box-active img {
    width: calc(20 * var(--vw-tab));
    margin-left: calc(0 * var(--vw-tab));
  }

  .menu-item-box-active p {
    font-size: calc(21 * var(--vw-tab));
    font-weight: 500;
    color: rgba(110, 240, 219, 1);
    margin-left: calc(16 * var(--vw-tab));
  }
  .buyer-post-hover,
  .provider-search-hover {
    visibility: hidden !important;
  }

  .logout-container-box {
    position: absolute;
    top: calc(730 * var(--vw-tab));
    height: calc(44 * var(--vw-tab));
    width: calc(330 * var(--vw-tab));
    padding: calc(10 * var(--vw-tab)) 0 calc(10 * var(--vw-tab))
      calc(32 * var(--vw-tab));
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .logout-container-box img {
    height: calc(24 * var(--vw-tab));
    margin-left: calc(0 * var(--vw-tab));
  }

  .logout-container-box p {
    font-size: calc(21 * var(--vw-tab));
    font-weight: 500;
    color: rgba(46, 46, 46, 1);
    margin-left: calc(16 * var(--vw-tab));
  }

  .top-bar-main {
    position: absolute;
    top: 0;
    right: 0;
    height: calc(80 * var(--vw-tab));
    width: calc(768 * var(--vw-tab));
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    z-index: 10;
  }

  .menu-icon-main {
    visibility: visible;
    position: absolute;
    left: calc(32 * var(--vw-tab));
    top: calc(25 * var(--vw-tab));
    width: calc(25 * var(--vw-tab));
    z-index: 20;
  }

  .top-menu-container {
    width: calc(300 * var(--vw-tab));
    height: calc(44 * var(--vw-tab));
    margin-right: calc(3 * var(--vw-tab));
    display: flex;
    align-items: center;
  }

  .top-post-button {
    visibility: hidden;
    height: calc(30 * var(--vw-tab));
    width: calc(100 * var(--vw-tab));
    border: none;
    border-radius: calc(4 * var(--vw-tab));
    display: flex;
    align-items: center;
    justify-content: center;
    gap: calc(10 * var(--vw-tab));
    background-color: rgb(13, 71, 161);
    font-size: calc(12 * var(--vw-tab));
    font-weight: 500;
    color: white;
    margin-right: calc(20 * var(--vw-tab));
  }

  .top-post-button img {
    height: calc(14 * var(--vw-tab));
  }

  .top-chats-icon {
    height: calc(44 * var(--vw-tab));
    margin-right: calc(40 * var(--vw-tab));
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .chats-count-main {
    position: absolute;
    top: calc(23 * var(--vw-tab));
    right: calc(110 * var(--vw-tab));
    height: calc(15 * var(--vw-tab));
    width: calc(35 * var(--vw-tab));
    padding: 0;
    display: flex;
    justify-content: start;
    align-items: center;
    color: rgb(13, 71, 161);
    z-index: 30;
    font-weight: 800;
    font-size: calc(13 * var(--vw-tab));
  }

  .top-chats-icon img {
    height: calc(24 * var(--vw-tab));
  }

  .top-notifications-icon {
    height: calc(44 * var(--vw-tab));
    margin-right: calc(40 * var(--vw-tab));
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .notifications-count-main {
    position: absolute;
    top: calc(23 * var(--vw-tab));
    right: calc(52 * var(--vw-tab));
    height: calc(15 * var(--vw-tab));
    width: calc(35 * var(--vw-tab));
    padding: 0;
    display: flex;
    justify-content: start;
    align-items: center;
    color: rgb(13, 71, 161);
    z-index: 30;
    font-weight: 800;
    font-size: calc(13 * var(--vw-tab));
  }

  .top-notifications-icon img {
    height: calc(24 * var(--vw-tab));
  }

  .profile-img-main {
    height: calc(36 * var(--vw-tab));
    width: calc(36 * var(--vw-tab));
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
  }

  .profile-img-main img {
    height: 100%;
    border-radius: 50%;
    align-self: center;
  }

  .content-container-main {
    position: absolute;
    top: calc(80 * var(--vw-tab));
    left: 0;
    height: calc(1020 * var(--vw-tab));
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: scroll;
    background-color: rgba(249, 249, 249, 1);
    padding: calc(1 * var(--vw-tab));
    border-right: none;
  }
}

@media (max-width: 550px) {
  body {
    height: calc(800 * var(--vw-mob));
    overflow: scroll;
  }

  .dashboard-page {
    width: 100%;
  }

  .session-expired p {
    color: rgb(13, 71, 161);
    font-size: calc(30 * var(--vw-mob));
    font-weight: 600;
  }
  .session-expired button {
    height: calc(40 * var(--vw-mob));
    width: calc(220 * var(--vw-mob));
    border: none;
    border-radius: calc(5 * var(--vw-mob));
    background-color: rgb(13, 71, 161);
    color: white;
    font-size: calc(20 * var(--vw-mob));
  }

  .tutorial-holder {
    position: absolute;
    top: calc(70 * var(--vw-tab));
    left: calc(50 * var(--vw-tab));
    height: auto;
    width: calc(100% - (100 * var(--vw-tab)));
    border: none;
    border-radius: calc(8 * var(--vw-tab));
    box-shadow: calc(-8 * var(--vw-tab)) calc(8 * var(--vw-tab))
      calc(34 * var(--vw-tab)) 0 rgba(0, 0, 0, 0.08);
    outline: calc(5000 * var(--vw-tab)) solid rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    background-color: white;
    z-index: 10;
  }
  #mobile-tutorial {
    height: auto;
    width: auto;
    visibility: visible;
  }
  #web-tutorial {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  #tab-tutorial {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  .tutorial-bar {
    height: 8%;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: calc(5 * var(--vw-tab)) solid rgba(0, 0, 0, 0.2);
  }
  .tutorial-btn {
    height: calc(37 * var(--vw-mob));
    width: calc(100 * var(--vw-mob));
    border: none;
    border-radius: calc(4 * var(--vw-mob));
    color: rgb(13, 71, 161);
    background-color: white;
    font-size: calc(15 * var(--vw-mob));
    font-weight: 600;
  }
  .tutorial-btn-active {
    height: calc(37 * var(--vw-mob));
    width: calc(100 * var(--vw-mob));
    border: none;
    border-radius: calc(4 * var(--vw-mob));
    background-color: white;
    color: rgba(41, 205, 178, 1);
    font-size: calc(16 * var(--vw-mob));
    font-weight: 600;
  }
  .tutorial-img-holder {
    height: 92%;
    display: flex;
    justify-content: center;
    overflow: hidden;
  }
  .tutorial-img {
    width: 98%;
    animation: fadeIn 1s ease-in-out;
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .left-arrow-holder {
    left: calc(-25 * var(--vw-mob));
    z-index: 15;
  }
  .left-arrow-holder img {
    height: calc(50 * var(--vw-mob));
  }
  .right-arrow-holder {
    right: calc(-25 * var(--vw-mob));
    z-index: 15;
  }
  .right-arrow-holder img {
    height: calc(50 * var(--vw-mob));
  }

  .menu-close-main {
    visibility: visible;
    position: absolute;
    top: calc(20 * var(--vw-mob));
    left: calc(262 * var(--vw-mob));
    color: rgba(46, 46, 46, 1);
    font-size: calc(20 * var(--vw-mob));
    font-weight: 600;
    z-index: 1000;
  }

  .left-menu-container {
    position: absolute;
    left: -500px;
    width: calc(300 * var(--vw-mob));
    height: calc(860 * var(--vw-mob));
    background-color: white;
    z-index: 100;
    transition: left 0.5s ease-in-out;
  }

  .left-menu-container-active {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(300 * var(--vw-mob));
    height: calc(860 * var(--vw-mob));
    background-color: white;
    z-index: 100;
    transition: left 0.5s ease-in-out;
  }

  .logo-dashboard-img {
    position: absolute;
    left: calc(59 * var(--vw-mob));
    top: calc(58 * var(--vw-mob));
    width: calc(178 * var(--vw-mob));
  }

  .menu-container-dashboard {
    position: absolute;
    top: calc(122 * var(--vw-mob));
    height: calc(548 * var(--vw-mob));
    width: calc(300 * var(--vw-mob));
    display: flex;
    flex-direction: column;
    gap: calc(16 * var(--vw-mob));
  }

  .menu-item-box {
    height: calc(44 * var(--vw-mob));
    width: 100%;
    padding: calc(10 * var(--vw-mob)) 0 calc(10 * var(--vw-mob))
      calc(32 * var(--vw-mob));
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .menu-item-box img {
    width: calc(18 * var(--vw-mob));
    margin-left: calc(0 * var(--vw-mob));
  }

  .menu-item-box p {
    font-size: calc(16 * var(--vw-mob));
    font-weight: 500;
    color: rgba(46, 46, 46, 1);
    margin-left: calc(16 * var(--vw-mob));
  }

  .menu-item-box-active {
    height: calc(44 * var(--vw-mob));
    width: 100%;
    padding: calc(10 * var(--vw-mob)) 0 calc(10 * var(--vw-mob))
      calc(32 * var(--vw-mob));
    background-color: rgb(13, 71, 161);
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .menu-item-box-active img {
    width: calc(20 * var(--vw-mob));
    margin-left: calc(0 * var(--vw-mob));
  }

  .menu-item-box-active p {
    font-size: calc(16 * var(--vw-mob));
    font-weight: 500;
    color: rgba(110, 240, 219, 1);
    margin-left: calc(16 * var(--vw-mob));
  }
  .buyer-post-hover,
  .provider-search-hover {
    visibility: hidden !important;
  }

  .logout-container-box {
    position: absolute;
    top: calc(690 * var(--vw-mob));
    height: calc(44 * var(--vw-mob));
    width: 100%;
    padding: calc(10 * var(--vw-mob)) 0 calc(10 * var(--vw-mob))
      calc(32 * var(--vw-mob));
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .logout-container-box img {
    height: calc(20 * var(--vw-mob));
    margin-left: calc(0 * var(--vw-mob));
  }

  .logout-container-box p {
    font-size: calc(16 * var(--vw-mob));
    font-weight: 500;
    color: rgba(46, 46, 46, 1);
    margin-left: calc(16 * var(--vw-mob));
  }

  .top-bar-main {
    position: absolute;
    top: 0;
    right: 0;
    height: calc(80 * var(--vw-mob));
    width: calc(390 * var(--vw-mob));
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    z-index: 0;
  }

  .menu-icon-main {
    visibility: visible;
    position: absolute;
    left: calc(16 * var(--vw-mob));
    top: calc(25 * var(--vw-mob));
    width: calc(25 * var(--vw-mob));
    z-index: 1;
  }

  .top-menu-container {
    width: calc(190 * var(--vw-mob));
    height: calc(44 * var(--vw-mob));
    margin-right: calc(10 * var(--vw-mob));
    display: flex;
    align-items: center;
  }

  .tutorial-icon-m {
    visibility: hidden;
    height: 50%;
    width: auto;
  }

  .top-post-button {
    visibility: hidden;
    height: 0;
    width: 0;
    border: none;
    border-radius: calc(4 * var(--vw-mob));
    display: flex;
    align-items: center;
    justify-content: center;
    gap: calc(10 * var(--vw-mob));
    background-color: rgb(13, 71, 161);
    font-size: calc(14 * var(--vw-mob));
    font-weight: 500;
    color: white;
    margin-right: calc(20 * var(--vw-mob));
  }

  .top-post-button img {
    height: calc(18 * var(--vw-mob));
  }

  .top-chats-icon {
    height: calc(44 * var(--vw-mob));
    margin-right: calc(26 * var(--vw-mob));
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .chats-count-main {
    position: absolute;
    top: calc(25 * var(--vw-mob));
    right: calc(95 * var(--vw-mob));
    height: calc(15 * var(--vw-mob));
    width: calc(35 * var(--vw-mob));
    padding: 0;
    display: flex;
    justify-content: start;
    align-items: center;
    color: rgb(13, 71, 161);

    font-weight: 800;
    font-size: calc(12 * var(--vw-mob));
  }

  .top-chats-icon img {
    height: calc(20 * var(--vw-mob));
  }

  .top-notifications-icon {
    height: calc(44 * var(--vw-mob));
    margin-right: calc(40 * var(--vw-mob));
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .notifications-count-main {
    position: absolute;
    top: calc(25 * var(--vw-mob));
    right: calc(54 * var(--vw-mob));
    height: calc(15 * var(--vw-mob));
    width: calc(35 * var(--vw-mob));
    padding: 0;
    display: flex;
    justify-content: start;
    align-items: center;
    color: rgb(13, 71, 161);
    z-index: 30;
    font-weight: 800;
    font-size: calc(12 * var(--vw-mob));
  }

  .top-notifications-icon img {
    height: calc(20 * var(--vw-mob));
  }

  .profile-img-main {
    height: calc(30 * var(--vw-mob));
    width: calc(30 * var(--vw-mob));
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
  }

  .profile-img-main img {
    height: 100%;
    border-radius: 50%;
    align-self: center;
  }

  .content-container-main {
    position: absolute;
    top: calc(80 * var(--vw-mob));
    left: 0;
    height: calc(780 * var(--vw-mob));
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: scroll;
    background-color: rgba(249, 249, 249, 1);
    padding: calc(1 * var(--vw-mob));
    border-right: none;
  }
}
