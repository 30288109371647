@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

:root {
  --vw-tab: calc((100 / 1440) * 1vw);
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

p {
  padding: 0;
  margin: 0;
}

.profile-page {
  width: 100%;
}

.profile-heading {
  position: absolute;
  top: calc(16 * var(--vw-unit));
  left: calc(25 * var(--vw-unit));
  font-size: calc(18 * var(--vw-unit));
  font-weight: 600;
}

.page-content-profile {
  position: absolute;
  left: calc(25 * var(--vw-unit));
  top: calc(60 * var(--vw-unit));
  height: calc(640 * var(--vw-unit));
  width: calc(1087 * var(--vw-unit));
  background-color: white;
}

.header-container-profile {
  height: calc(100 * var(--vw-unit));
  width: calc(1039 * var(--vw-unit));
  margin-top: calc(24 * var(--vw-unit));
  margin-left: calc(24 * var(--vw-unit));
  border: calc(1 * var(--vw-unit)) solid rgba(7, 51, 108, 0.4);
  border-radius: calc(4 * var(--vw-unit));
  display: flex;
  align-items: center;
}

.intro-profile-container {
  height: calc(60 * var(--vw-unit));
  width: calc(240 * var(--vw-unit));
  margin-left: calc(32 * var(--vw-unit));
  margin-right: calc(645 * var(--vw-unit));
  display: flex;
  align-items: center;
  gap: calc(16 * var(--vw-unit));
}

.intro-profile-img {
  height: calc(75 * var(--vw-unit));
  width: calc(75 * var(--vw-unit));
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.intro-profile-content {
  display: flex;
  align-items: center;
}

.intro-profile-head {
  font-size: calc(16 * var(--vw-unit));
  font-weight: 600;
  color: rgba(7, 51, 108, 1);
}

.profile-header-button {
  height: calc(40 * var(--vw-unit));
  width: calc(90 * var(--vw-unit));
  border-radius: calc(4 * var(--vw-unit));
  border: calc(0.5 * var(--vw-unit)) solid rgba(7, 51, 108, 1);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: white;
}

.mobile-verify-btn-profile {
  height: calc(30 * var(--vw-unit));
  width: calc(90 * var(--vw-unit));
  border-radius: calc(4 * var(--vw-unit));
  border: calc(0.5 * var(--vw-unit)) solid rgba(7, 51, 108, 1);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: rgba(7, 51, 108, 1);
  color: white;
  font-size: calc(14 * var(--vw-unit));
}

.profile-header-button img {
  height: calc(16 * var(--vw-unit));
}

.profile-header-button p {
  font-size: calc(14 * var(--vw-unit));
  font-weight: 500;
  color: rgba(7, 51, 108, 1);
}
.image-edit-profile {
  position: absolute;
  height: calc(100 * var(--vw-unit));
  width: calc(1039 * var(--vw-unit));
  border-radius: calc(4 * var(--vw-unit));
  border: calc(1 * var(--vw-unit)) solid rgba(7, 51, 108, 0.4);
  background-color: white;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: calc(10 * var(--vw-unit));
}
.image-edit-profile input {
  border: calc(1 * var(--vw-unit)) solid rgba(7, 51, 108, 1);
  border-radius: calc(4 * var(--vw-unit));
  font-size: calc(16 * var(--vw-unit));
  color: rgba(7, 51, 108, 1);
  font-weight: 500;
}
.image-save-profile {
  height: calc(30 * var(--vw-unit));
  width: calc(150 * var(--vw-unit));
  border: none;
  border-radius: calc(4 * var(--vw-unit));
  background-color: rgba(7, 51, 108, 1);
  color: white;
  font-size: calc(16 * var(--vw-unit));
  font-weight: 600;
  margin-left: calc(30 * var(--vw-unit));
}
.image-cancel-profile {
  height: calc(30 * var(--vw-unit));
  width: calc(150 * var(--vw-unit));
  border: calc(1 * var(--vw-unit)) solid rgba(7, 51, 108, 1);
  border-radius: calc(4 * var(--vw-unit));
  background: transparent;
  color: rgba(7, 51, 108, 1);
  font-size: calc(16 * var(--vw-unit));
  font-weight: 600;
}

.profile-details-container {
  height: calc(380 * var(--vw-unit));
  width: calc(1039 * var(--vw-unit));
  margin-left: calc(24 * var(--vw-unit));
  margin-top: calc(50 * var(--vw-unit));
  border: calc(0.5 * var(--vw-unit)) solid rgba(7, 51, 108, 0.4);
  border-radius: calc(4 * var(--vw-unit));
}

.profile-first-name {
  position: absolute;
  top: calc(200 * var(--vw-unit));
  left: calc(56 * var(--vw-unit));
  height: calc(46 * var(--vw-unit));
  width: calc(200 * var(--vw-unit));
}

.profile-last-name {
  position: absolute;
  top: calc(200 * var(--vw-unit));
  left: calc(600 * var(--vw-unit));
  height: calc(46 * var(--vw-unit));
  width: calc(200 * var(--vw-unit));
}

.profile-name-head,
.profile-lastname-head,
.profile-email-head,
.profile-mobile-head,
.profile-bio-head,
.profile-member-head {
  font-size: calc(12 * var(--vw-unit));
  font-weight: 400;
  color: rgba(144, 144, 144, 1);
}

.profile-name-content,
.profile-lastname-content,
.profile-email-content,
.profile-mobile-content,
.profile-bio-content,
.profile-member-content {
  font-size: calc(16 * var(--vw-unit));
  font-weight: 600;
  color: rgba(7, 51, 108, 1);
}

.profile-content-edit {
  position: absolute;
  top: calc(200 * var(--vw-unit));
  left: calc(940 * var(--vw-unit));
  height: calc(40 * var(--vw-unit));
  width: calc(90 * var(--vw-unit));
  border-radius: calc(4 * var(--vw-unit));
  border: calc(0.5 * var(--vw-unit)) solid rgba(7, 51, 108, 1);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: white;
}

.profile-content-edit img {
  height: calc(16 * var(--vw-unit));
}

.profile-content-edit p {
  font-size: calc(14 * var(--vw-unit));
  font-weight: 500;
  color: rgba(7, 51, 108, 1);
}

.profile-email {
  position: absolute;
  top: calc(280 * var(--vw-unit));
  left: calc(56 * var(--vw-unit));
  height: calc(46 * var(--vw-unit));
  width: calc(260 * var(--vw-unit));
}

.profile-mobile {
  position: absolute;
  top: calc(280 * var(--vw-unit));
  left: calc(600 * var(--vw-unit));
  height: calc(46 * var(--vw-unit));
  width: calc(260 * var(--vw-unit));
}

.profile-bio {
  position: absolute;
  top: calc(380 * var(--vw-unit));
  left: calc(56 * var(--vw-unit));
  height: calc(46 * var(--vw-unit));
  width: calc(360 * var(--vw-unit));
}

.profile-member {
  position: absolute;
  top: calc(380 * var(--vw-unit));
  left: calc(600 * var(--vw-unit));
  height: calc(46 * var(--vw-unit));
  width: calc(250 * var(--vw-unit));
}

.profile-seller-verify {
  position: absolute;
  top: calc(480 * var(--vw-unit));
  left: calc(56 * var(--vw-unit));
}
.profile-seller-verify button {
  height: calc(50 * var(--vw-unit));
  padding: 0 calc(30 * var(--vw-unit));
  border: none;
  border-radius: calc(4 * var(--vw-unit));
  background-color: rgba(7, 51, 108, 1);
  color: white;
  font-size: calc(16 * var(--vw-unit));
  font-weight: 600;
}
.profile-premium-brokers {
  position: absolute;
  top: calc(480 * var(--vw-unit));
  left: calc(595 * var(--vw-unit));
}
.profile-premium-brokers button {
  height: calc(30 * var(--vw-unit));

  padding: 0 calc(10 * var(--vw-unit));
  border: none;
  border-radius: calc(4 * var(--vw-unit));
  background-color: rgba(7, 51, 108, 1);
  color: white;
  font-size: calc(12 * var(--vw-unit));
  font-weight: 600;
}
.verified-seller {
  height: calc(30 * var(--vw-unit));
  display: flex;
  align-items: center;
  gap: calc(10 * var(--vw-unit));
}
.verified-seller h2 {
  margin: 0;
  padding: 0;
  font-size: calc(16 * var(--vw-unit));
  font-weight: 600;
  color: rgba(7, 51, 108, 1);
}
.verified-seller img {
  height: 80%;
}

.edit-details-profile {
  position: absolute;
  top: calc(164 * var(--vw-unit));
  left: calc(24 * var(--vw-unit));
  height: calc(410 * var(--vw-unit));
  width: calc(1039 * var(--vw-unit));
  margin: 0;
  padding: calc(20 * var(--vw-unit));
  border: calc(1 * var(--vw-unit)) solid rgba(7, 51, 108, 0.4);
  border-radius: calc(4 * var(--vw-unit));
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: calc(2 * var(--vw-unit));
  z-index: 100;
}
.edit-details-profile label {
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: calc(16 * var(--vw-unit));
  color: rgba(7, 51, 108, 1);
}
.edit-details-profile input,
.mobile-edit-profile {
  margin: 0;
  height: calc(40 * var(--vw-unit));
  width: calc(500 * var(--vw-unit));
  margin-bottom: calc(15 * var(--vw-unit));
  padding-left: calc(10 * var(--vw-unit));
  border: calc(1 * var(--vw-unit)) solid rgba(7, 51, 108, 0.4);
  font-size: calc(16 * var(--vw-unit));
}
.edit-details-profile input:focus {
  outline: calc(5 * var(--vw-unit)) solid rgba(220, 249, 255, 1);
}
.profile-changes-cancel {
  position: absolute;
  top: calc(350 * var(--vw-unit));
  left: calc(140 * var(--vw-unit));
  height: calc(48 * var(--vw-unit));
  width: calc(102 * var(--vw-unit));
  border: calc(1 * var(--vw-unit)) solid rgba(7, 51, 108, 1);
  border-radius: calc(4 * var(--vw-unit));
  background: transparent;
  font-weight: 500;
  font-size: calc(16 * var(--vw-unit));
  color: rgba(7, 51, 108, 1);
}

.profile-changes-save {
  position: absolute;
  top: calc(350 * var(--vw-unit));
  left: calc(260 * var(--vw-unit));
  height: calc(48 * var(--vw-unit));
  width: calc(102 * var(--vw-unit));
  border: calc(1 * var(--vw-unit)) solid rgba(7, 51, 108, 1);
  border-radius: calc(4 * var(--vw-unit));
  background-color: rgba(7, 51, 108, 1);
  font-weight: 500;
  font-size: calc(16 * var(--vw-unit));
  color: white;
}

.verify-seller-pop {
  position: absolute;
  top: calc(164 * var(--vw-unit));
  left: calc(24 * var(--vw-unit));
  height: calc(410 * var(--vw-unit));
  width: calc(1039 * var(--vw-unit));
  margin: 0;
  padding: calc(20 * var(--vw-unit));
  border: calc(1 * var(--vw-unit)) solid rgba(7, 51, 108, 0.4);
  border-radius: calc(4 * var(--vw-unit));
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: calc(2 * var(--vw-unit));
  z-index: 100;
}
.verify-seller-pop label {
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: calc(16 * var(--vw-unit));
  color: rgba(7, 51, 108, 1);
}
.verify-seller-pop input {
  margin: 0;
  height: calc(40 * var(--vw-unit));
  width: calc(500 * var(--vw-unit));
  margin-bottom: calc(15 * var(--vw-unit));
  padding-left: calc(10 * var(--vw-unit));
  border: calc(1 * var(--vw-unit)) solid rgba(7, 51, 108, 0.4);
  font-size: calc(16 * var(--vw-unit));
}
.verify-seller-pop input:focus {
  outline: calc(5 * var(--vw-unit)) solid rgba(220, 249, 255, 1);
}
.seller-details-cancel {
  position: absolute;
  top: calc(300 * var(--vw-unit));
  left: calc(140 * var(--vw-unit));
  height: calc(48 * var(--vw-unit));
  width: calc(102 * var(--vw-unit));
  border: calc(1 * var(--vw-unit)) solid rgba(7, 51, 108, 1);
  border-radius: calc(4 * var(--vw-unit));
  background: transparent;
  font-weight: 500;
  font-size: calc(16 * var(--vw-unit));
  color: rgba(7, 51, 108, 1);
}
.seller-details-submit {
  position: absolute;
  top: calc(300 * var(--vw-unit));
  left: calc(260 * var(--vw-unit));
  height: calc(48 * var(--vw-unit));
  width: calc(102 * var(--vw-unit));
  border: calc(1 * var(--vw-unit)) solid rgba(7, 51, 108, 1);
  border-radius: calc(4 * var(--vw-unit));
  background-color: rgba(7, 51, 108, 1);
  font-weight: 500;
  font-size: calc(16 * var(--vw-unit));
  color: white;
}

@media (min-width: 550px) and (max-width: 1200px) {
  .profile-page {
    width: 100%;
  }

  .profile-heading {
    position: absolute;
    top: calc(16 * var(--vw-tab));
    left: calc(25 * var(--vw-tab));
    font-size: calc(18 * var(--vw-tab));
    font-weight: 600;
  }

  .page-content-profile {
    position: absolute;
    left: calc(25 * var(--vw-tab));
    top: calc(60 * var(--vw-tab));
    height: calc(840 * var(--vw-tab));
    width: 94%;
    background-color: white;
  }

  .header-container-profile {
    height: calc(100 * var(--vw-tab));
    width: 92%;
    margin-top: calc(24 * var(--vw-tab));
    margin-left: calc(24 * var(--vw-tab));
    margin-right: 0;
    border: calc(1 * var(--vw-tab)) solid rgba(7, 51, 108, 0.4);
    border-radius: calc(4 * var(--vw-tab));
    display: flex;
    align-items: center;
  }

  .intro-profile-container {
    height: calc(60 * var(--vw-tab));
    width: calc(240 * var(--vw-tab));
    margin-left: calc(32 * var(--vw-tab));
    margin-right: calc(645 * var(--vw-tab));
    display: flex;
    gap: calc(16 * var(--vw-tab));
  }

  .intro-profile-img {
    height: 100%;
    width: calc(60 * var(--vw-tab));
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .intro-profile-img img {
    max-height: 100%;
    border-radius: 50%;
  }

  .intro-profile-content {
    display: flex;
    align-items: center;
  }

  .intro-profile-head {
    font-size: calc(16 * var(--vw-tab));
    font-weight: 600;
    color: rgba(7, 51, 108, 1);
  }

  .profile-header-button {
    visibility: hidden;
    position: absolute;
    left: 0;
    height: calc(40 * var(--vw-tab));
    width: calc(90 * var(--vw-tab));
    border-radius: calc(4 * var(--vw-tab));
    border: calc(0.5 * var(--vw-tab)) solid rgba(7, 51, 108, 1);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: white;
  }
  .mobile-verify-btn-profile {
    height: calc(30 * var(--vw-tab));
    width: calc(90 * var(--vw-tab));
    border-radius: calc(4 * var(--vw-tab));
    border: calc(0.5 * var(--vw-tab)) solid rgba(7, 51, 108, 1);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: rgba(7, 51, 108, 1);
    color: white;
    font-size: calc(14 * var(--vw-tab));
  }

  .profile-header-button img {
    height: calc(16 * var(--vw-tab));
  }

  .profile-header-button p {
    font-size: calc(14 * var(--vw-tab));
    font-weight: 500;
    color: rgba(7, 51, 108, 1);
  }
  .image-edit-profile {
    position: absolute;
    height: calc(100 * var(--vw-tab));
    width: 92%;
    padding: calc(15 * var(--vw-tab));
    border-radius: calc(4 * var(--vw-tab));
    border: calc(1 * var(--vw-tab)) solid rgba(7, 51, 108, 0.4);
    background-color: white;
    z-index: 50;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: calc(10 * var(--vw-tab));
  }
  .image-edit-profile input {
    border: calc(1 * var(--vw-tab)) solid rgba(7, 51, 108, 1);
    border-radius: calc(4 * var(--vw-tab));
    font-size: calc(16 * var(--vw-tab));
    color: rgba(7, 51, 108, 1);
    font-weight: 500;
  }
  .image-save-profile {
    height: calc(30 * var(--vw-tab));
    width: calc(150 * var(--vw-tab));
    border: none;
    border-radius: calc(4 * var(--vw-tab));
    background-color: rgba(7, 51, 108, 1);
    color: white;
    font-size: calc(14 * var(--vw-tab));
    font-weight: 600;
    margin-left: calc(30 * var(--vw-tab));
  }
  .image-cancel-profile {
    height: calc(30 * var(--vw-tab));
    width: calc(150 * var(--vw-tab));
    border: calc(1 * var(--vw-tab)) solid rgba(7, 51, 108, 1);
    border-radius: calc(4 * var(--vw-tab));
    background: transparent;
    color: rgba(7, 51, 108, 1);
    font-size: calc(14 * var(--vw-tab));
    font-weight: 600;
  }

  .profile-details-container {
    height: calc(360 * var(--vw-tab));
    width: 92%;
    margin-left: calc(24 * var(--vw-tab));
    margin-top: calc(50 * var(--vw-tab));
    border: calc(0.5 * var(--vw-tab)) solid rgba(7, 51, 108, 0.4);
    border-radius: calc(4 * var(--vw-tab));
  }

  .profile-first-name {
    position: absolute;
    top: calc(200 * var(--vw-tab));
    left: calc(56 * var(--vw-tab));
    height: calc(46 * var(--vw-tab));
    width: calc(200 * var(--vw-tab));
  }
  .profile-last-name {
    position: absolute;
    top: calc(200 * var(--vw-tab));
    left: calc(400 * var(--vw-tab));
    height: calc(46 * var(--vw-tab));
    width: calc(200 * var(--vw-tab));
  }

  .profile-name-head,
  .profile-lastname-head,
  .profile-email-head,
  .profile-mobile-head,
  .profile-bio-head,
  .profile-member-head {
    font-size: calc(12 * var(--vw-tab));
    font-weight: 400;
    color: rgba(144, 144, 144, 1);
  }

  .profile-name-content,
  .profile-lastname-content,
  .profile-email-content,
  .profile-mobile-content,
  .profile-bio-content,
  .profile-member-content {
    font-size: calc(16 * var(--vw-tab));
    font-weight: 600;
    color: rgba(7, 51, 108, 1);
  }

  .profile-content-edit {
    position: absolute;
    top: calc(200 * var(--vw-tab));
    left: calc(570 * var(--vw-tab));
    height: calc(40 * var(--vw-tab));
    width: calc(90 * var(--vw-tab));
    border-radius: calc(4 * var(--vw-tab));
    border: calc(0.5 * var(--vw-tab)) solid rgba(7, 51, 108, 1);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: white;
  }

  .profile-content-edit img {
    height: calc(16 * var(--vw-tab));
  }

  .profile-content-edit p {
    font-size: calc(14 * var(--vw-tab));
    font-weight: 500;
    color: rgba(7, 51, 108, 1);
  }

  .profile-email {
    position: absolute;
    top: calc(280 * var(--vw-tab));
    left: calc(56 * var(--vw-tab));
    height: calc(46 * var(--vw-tab));
    width: calc(260 * var(--vw-tab));
  }

  .profile-mobile {
    position: absolute;
    top: calc(280 * var(--vw-tab));
    left: calc(400 * var(--vw-tab));
    height: calc(46 * var(--vw-tab));
    width: calc(260 * var(--vw-tab));
  }

  .profile-bio {
    position: absolute;
    top: calc(380 * var(--vw-tab));
    left: calc(56 * var(--vw-tab));
    height: calc(46 * var(--vw-tab));
    width: calc(360 * var(--vw-tab));
  }

  .profile-member {
    position: absolute;
    top: calc(380 * var(--vw-tab));
    left: calc(400 * var(--vw-tab));
    height: calc(46 * var(--vw-tab));
    width: calc(250 * var(--vw-tab));
  }
  .profile-seller-verify {
    position: absolute;
    top: calc(460 * var(--vw-tab));
    left: calc(56 * var(--vw-tab));
  }
  .profile-seller-verify button {
    height: calc(35 * var(--vw-tab));
    padding: 0 calc(30 * var(--vw-tab));
    border: none;
    border-radius: calc(4 * var(--vw-tab));
    background-color: rgba(7, 51, 108, 1);
    color: white;
    font-size: calc(12 * var(--vw-tab));
    font-weight: 600;
  }
  .profile-premium-brokers {
    top: calc(460 * var(--vw-tab));
    left: calc(400 * var(--vw-tab));
  }
  .profile-premium-brokers button {
    height: calc(30 * var(--vw-tab));
    padding: 0 calc(10 * var(--vw-tab));
    border: none;
    border-radius: calc(4 * var(--vw-tab));
    background-color: rgba(7, 51, 108, 1);
    color: white;
    font-size: calc(12 * var(--vw-tab));
    font-weight: 600;
  }
  .verified-seller {
    height: calc(30 * var(--vw-tab));
    display: flex;
    align-items: center;
    gap: calc(10 * var(--vw-tab));
  }
  .verified-seller h2 {
    margin: 0;
    padding: 0;
    font-size: calc(16 * var(--vw-tab));
    font-weight: 600;
    color: rgba(7, 51, 108, 1);
  }
  .verified-seller img {
    height: 60%;
  }
  .edit-details-profile {
    position: absolute;
    top: calc(164 * var(--vw-tab));
    left: calc(24 * var(--vw-tab));
    height: calc(410 * var(--vw-tab));
    width: 92%;
    margin: 0;
    padding: calc(20 * var(--vw-tab));
    border: calc(1 * var(--vw-tab)) solid rgba(7, 51, 108, 0.4);
    border-radius: calc(4 * var(--vw-tab));
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: calc(2 * var(--vw-tab));
    z-index: 60;
  }
  .edit-details-profile label {
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: calc(16 * var(--vw-tab));
    color: rgba(7, 51, 108, 1);
  }
  .edit-details-profile input,
  .mobile-edit-profile {
    margin: 0;
    height: calc(40 * var(--vw-tab));
    width: calc(500 * var(--vw-tab));
    margin-bottom: calc(15 * var(--vw-tab));
    padding-left: calc(10 * var(--vw-tab));
    border: calc(1 * var(--vw-tab)) solid rgba(7, 51, 108, 0.4);
    font-size: calc(16 * var(--vw-tab));
  }
  .edit-details-profile input:focus {
    outline: calc(5 * var(--vw-tab)) solid rgba(220, 249, 255, 1);
  }

  .profile-changes-cancel {
    position: absolute;
    top: calc(490 * var(--vw-tab));
    left: calc(340 * var(--vw-tab));
    height: calc(48 * var(--vw-tab));
    width: calc(102 * var(--vw-tab));
    margin-top: calc(20 * var(--vw-tab));
    border: calc(1 * var(--vw-tab)) solid rgba(7, 51, 108, 1);
    border-radius: calc(4 * var(--vw-tab));
    background: transparent;
    font-weight: 500;
    font-size: calc(16 * var(--vw-tab));
    color: rgba(7, 51, 108, 1);
  }

  .profile-changes-save {
    position: absolute;
    top: calc(490 * var(--vw-tab));
    left: calc(460 * var(--vw-tab));
    height: calc(48 * var(--vw-tab));
    width: calc(102 * var(--vw-tab));
    margin-top: calc(20 * var(--vw-tab));
    border: calc(1 * var(--vw-tab)) solid rgba(7, 51, 108, 1);
    border-radius: calc(4 * var(--vw-tab));
    background-color: rgba(7, 51, 108, 1);
    font-weight: 500;
    font-size: calc(16 * var(--vw-tab));
    color: white;
  }
  .verify-seller-pop {
    position: absolute;
    top: calc(164 * var(--vw-tab));
    left: calc(24 * var(--vw-tab));
    height: calc(410 * var(--vw-tab));
    width: 92%;
    margin: 0;
    padding: calc(20 * var(--vw-tab));
    border: calc(1 * var(--vw-tab)) solid rgba(7, 51, 108, 0.4);
    border-radius: calc(4 * var(--vw-tab));
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: calc(2 * var(--vw-tab));
    z-index: 60;
  }
  .verify-seller-pop label {
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: calc(16 * var(--vw-tab));
    color: rgba(7, 51, 108, 1);
  }
  .verify-seller-pop input {
    margin: 0;
    height: calc(40 * var(--vw-tab));
    width: calc(500 * var(--vw-tab));
    margin-bottom: calc(15 * var(--vw-tab));
    padding-left: calc(10 * var(--vw-tab));
    border: calc(1 * var(--vw-tab)) solid rgba(7, 51, 108, 0.4);
    font-size: calc(16 * var(--vw-tab));
  }
  .verify-seller-pop input:focus {
    outline: calc(5 * var(--vw-tab)) solid rgba(220, 249, 255, 1);
  }
  .seller-details-cancel {
    position: absolute;
    top: calc(490 * var(--vw-tab));
    left: calc(340 * var(--vw-tab));
    height: calc(48 * var(--vw-tab));
    width: calc(102 * var(--vw-tab));
    margin-top: calc(20 * var(--vw-tab));
    border: calc(1 * var(--vw-tab)) solid rgba(7, 51, 108, 1);
    border-radius: calc(4 * var(--vw-tab));
    background: transparent;
    font-weight: 500;
    font-size: calc(16 * var(--vw-tab));
    color: rgba(7, 51, 108, 1);
  }
  .seller-details-submit {
    position: absolute;
    top: calc(490 * var(--vw-tab));
    left: calc(460 * var(--vw-tab));
    height: calc(48 * var(--vw-tab));
    width: calc(102 * var(--vw-tab));
    margin-top: calc(20 * var(--vw-tab));
    border: calc(1 * var(--vw-tab)) solid rgba(7, 51, 108, 1);
    border-radius: calc(4 * var(--vw-tab));
    background-color: rgba(7, 51, 108, 1);
    font-weight: 500;
    font-size: calc(16 * var(--vw-tab));
    color: white;
  }
}

@media (max-width: 550px) {
  .profile-page {
    width: 100%;
    height: 90%;
  }

  .profile-heading {
    position: absolute;
    top: calc(16 * var(--vw-mob));
    left: calc(16 * var(--vw-mob));
    font-size: calc(16 * var(--vw-mob));
    font-weight: 600;
  }

  .page-content-profile {
    position: absolute;
    left: 2%;
    top: calc(60 * var(--vw-mob));
    height: calc(700 * var(--vw-mob));
    width: 96%;
    background-color: white;
    overflow: scroll;
    overflow-x: hidden;
  }

  .header-container-profile {
    height: calc(100 * var(--vw-mob));
    width: calc(310 * var(--vw-mob));
    margin-top: calc(24 * var(--vw-mob));
    margin-left: calc(24 * var(--vw-mob));
    border: calc(1 * var(--vw-mob)) solid rgba(7, 51, 108, 0.4);
    border-radius: calc(4 * var(--vw-mob));
    display: flex;
    align-items: center;
    margin-right: 0;
  }

  .intro-profile-container {
    height: calc(60 * var(--vw-mob));
    width: calc(240 * var(--vw-mob));
    margin-left: calc(32 * var(--vw-mob));
    margin-right: calc(645 * var(--vw-mob));
    display: flex;
    gap: calc(16 * var(--vw-mob));
  }

  .intro-profile-img {
    height: calc(70 * var(--vw-mob));
    width: calc(70 * var(--vw-mob));
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .intro-profile-content {
    display: flex;
    align-items: center;
  }

  .intro-profile-head {
    font-size: calc(16 * var(--vw-mob));
    font-weight: 600;
    color: rgba(7, 51, 108, 1);
  }

  .profile-header-button {
    position: absolute;
    left: 0;
    height: calc(40 * var(--vw-mob));
    width: calc(90 * var(--vw-mob));
    border-radius: calc(4 * var(--vw-mob));
    border: calc(0.5 * var(--vw-mob)) solid rgba(7, 51, 108, 1);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: white;
    visibility: hidden;
  }
  .mobile-verify-btn-profile {
    height: calc(30 * var(--vw-mob));
    width: calc(90 * var(--vw-mob));
    border-radius: calc(4 * var(--vw-mob));
    border: calc(0.5 * var(--vw-mob)) solid rgba(7, 51, 108, 1);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: rgba(7, 51, 108, 1);
    color: white;
    font-size: calc(14 * var(--vw-mob));
  }

  .profile-header-button img {
    height: calc(16 * var(--vw-mob));
  }

  .profile-header-button p {
    font-size: calc(14 * var(--vw-mob));
    font-weight: 500;
    color: rgba(7, 51, 108, 1);
  }

  .image-edit-profile {
    position: absolute;
    height: calc(194 * var(--vw-tab));
    width: 82.4%;
    padding: calc(15 * var(--vw-tab));
    border-radius: calc(4 * var(--vw-tab));
    border: calc(1 * var(--vw-tab)) solid rgba(7, 51, 108, 0.4);
    background-color: white;
    z-index: 50;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: calc(10 * var(--vw-tab));
  }
  .image-edit-profile input {
    border: calc(1 * var(--vw-tab)) solid rgba(7, 51, 108, 1);
    border-radius: calc(4 * var(--vw-tab));
    font-size: calc(16 * var(--vw-tab));
    color: rgba(7, 51, 108, 1);
    font-weight: 500;
  }
  .image-save-profile {
    height: calc(30 * var(--vw-tab));
    width: calc(150 * var(--vw-tab));
    border: none;
    border-radius: calc(4 * var(--vw-tab));
    background-color: rgba(7, 51, 108, 1);
    color: white;
    font-size: calc(14 * var(--vw-tab));
    font-weight: 600;
    margin-left: calc(30 * var(--vw-tab));
  }
  .image-cancel-profile {
    height: calc(30 * var(--vw-tab));
    width: calc(150 * var(--vw-tab));
    border: calc(1 * var(--vw-tab)) solid rgba(7, 51, 108, 1);
    border-radius: calc(4 * var(--vw-tab));
    background: transparent;
    color: rgba(7, 51, 108, 1);
    font-size: calc(14 * var(--vw-tab));
    font-weight: 600;
  }

  .profile-details-container {
    height: calc(900 * var(--vw-mob));
    width: calc(310 * var(--vw-mob));
    margin-left: calc(24 * var(--vw-mob));
    margin-top: calc(20 * var(--vw-mob));
    margin-bottom: calc(20 * var(--vw-mob));
    border: calc(0.5 * var(--vw-mob)) solid rgba(7, 51, 108, 0.4);
    border-radius: calc(4 * var(--vw-mob));
    overflow: scroll;
  }

  .profile-first-name {
    position: absolute;
    top: calc(200 * var(--vw-mob));
    left: calc(56 * var(--vw-mob));
    height: calc(46 * var(--vw-mob));
    width: calc(200 * var(--vw-mob));
  }
  .profile-last-name {
    position: absolute;
    top: calc(280 * var(--vw-mob));
    left: calc(56 * var(--vw-mob));
    height: calc(46 * var(--vw-mob));
    width: calc(200 * var(--vw-mob));
  }

  .profile-name-head,
  .profile-lastname-head,
  .profile-email-head,
  .profile-mobile-head,
  .profile-bio-head,
  .profile-member-head {
    font-size: calc(12 * var(--vw-mob));
    font-weight: 400;
    color: rgba(144, 144, 144, 1);
  }

  .profile-name-content,
  .profile-lastname-content,
  .profile-email-content,
  .profile-mobile-content,
  .profile-bio-content,
  .profile-member-content {
    font-size: calc(16 * var(--vw-mob));
    font-weight: 600;
    color: rgba(7, 51, 108, 1);
    overflow-x: scroll;
  }

  .profile-content-edit {
    position: absolute;
    top: calc(920 * var(--vw-mob));
    left: calc(100 * var(--vw-mob));
    height: calc(40 * var(--vw-mob));
    width: calc(90 * var(--vw-mob));
    border-radius: calc(4 * var(--vw-mob));
    border: calc(0.5 * var(--vw-mob)) solid rgba(7, 51, 108, 1);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: white;
  }

  .profile-content-edit img {
    height: calc(16 * var(--vw-mob));
  }

  .profile-content-edit p {
    font-size: calc(14 * var(--vw-mob));
    font-weight: 500;
    color: rgba(7, 51, 108, 1);
  }

  .profile-email {
    position: absolute;
    top: calc(480 * var(--vw-mob));
    left: calc(56 * var(--vw-mob));
    height: calc(46 * var(--vw-mob));
    width: calc(260 * var(--vw-mob));
  }

  .profile-mobile {
    position: absolute;
    top: calc(380 * var(--vw-mob));
    left: calc(56 * var(--vw-mob));
    height: calc(46 * var(--vw-mob));
    width: calc(260 * var(--vw-mob));
  }

  .profile-bio {
    position: absolute;
    top: calc(680 * var(--vw-mob));
    left: calc(56 * var(--vw-mob));
    height: calc(46 * var(--vw-mob));
    width: calc(360 * var(--vw-mob));
  }

  .profile-member {
    position: absolute;
    top: calc(580 * var(--vw-mob));
    left: calc(56 * var(--vw-mob));
    height: calc(46 * var(--vw-mob));
    width: calc(250 * var(--vw-mob));
  }
  .profile-seller-verify {
    position: absolute;
    top: calc(780 * var(--vw-mob));
    left: calc(56 * var(--vw-mob));
    height: calc(46 * var(--vw-mob));
    width: calc(250 * var(--vw-mob));
  }
  .profile-seller-verify button {
    height: calc(35 * var(--vw-mob));
    padding: 0 calc(30 * var(--vw-mob));
    border: none;
    border-radius: calc(4 * var(--vw-mob));
    background-color: rgba(7, 51, 108, 1);
    color: white;
    font-size: calc(12 * var(--vw-mob));
    font-weight: 600;
  }
  .profile-premium-brokers {
    top: calc(840 * var(--vw-mob));
    left: calc(56 * var(--vw-mob));
    height: calc(46 * var(--vw-mob));
    width: calc(250 * var(--vw-mob));
  }
  .profile-premium-brokers button {
    height: calc(30 * var(--vw-mob));
    padding: 0 calc(30 * var(--vw-mob));
    border: none;
    border-radius: calc(4 * var(--vw-mob));
    background-color: rgba(7, 51, 108, 1);
    color: white;
    font-size: calc(12 * var(--vw-mob));
    font-weight: 600;
  }
  .verified-seller {
    height: calc(30 * var(--vw-mob));
    display: flex;
    align-items: center;
    gap: calc(10 * var(--vw-mob));
  }
  .verified-seller h2 {
    margin: 0;
    padding: 0;
    font-size: calc(16 * var(--vw-mob));
    font-weight: 600;
    color: rgba(7, 51, 108, 1);
  }
  .verified-seller img {
    height: 60%;
  }
  .edit-details-profile {
    position: absolute;
    top: calc(144 * var(--vw-mob));
    left: calc(24 * var(--vw-mob));
    height: calc(790 * var(--vw-mob));
    width: calc(310 * var(--vw-mob));
    margin: 0;
    padding: calc(15 * var(--vw-mob));
    border: calc(1 * var(--vw-mob)) solid rgba(7, 51, 108, 0.4);
    border-radius: calc(4 * var(--vw-mob));
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: calc(2 * var(--vw-mob));
    z-index: 50;
  }
  .edit-details-profile label {
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: calc(16 * var(--vw-mob));
    color: rgba(7, 51, 108, 1);
  }
  .edit-details-profile input,
  .mobile-edit-profile {
    margin: 0;
    height: calc(40 * var(--vw-mob));
    width: calc(260 * var(--vw-mob));
    margin-bottom: calc(15 * var(--vw-mob));
    padding-left: calc(10 * var(--vw-mob));
    border: calc(1 * var(--vw-mob)) solid rgba(7, 51, 108, 0.4);
    font-size: calc(16 * var(--vw-mob));
  }
  .edit-details-profile input:focus {
    outline: calc(5 * var(--vw-mob)) solid rgba(220, 249, 255, 1);
  }

  .profile-changes-cancel {
    position: absolute;
    top: calc(370 * var(--vw-mob));
    left: calc(40 * var(--vw-mob));
    height: calc(48 * var(--vw-mob));
    width: calc(102 * var(--vw-mob));
    border: calc(1 * var(--vw-mob)) solid rgba(7, 51, 108, 1);
    border-radius: calc(4 * var(--vw-mob));
    background: transparent;
    font-weight: 500;
    font-size: calc(16 * var(--vw-mob));
    color: rgba(7, 51, 108, 1);
    z-index: 60;
  }

  .profile-changes-save {
    position: absolute;
    top: calc(370 * var(--vw-mob));
    left: calc(165 * var(--vw-mob));
    height: calc(48 * var(--vw-mob));
    width: calc(102 * var(--vw-mob));
    border: calc(1 * var(--vw-mob)) solid rgba(7, 51, 108, 1);
    border-radius: calc(4 * var(--vw-mob));
    background-color: rgba(7, 51, 108, 1);
    font-weight: 500;
    font-size: calc(16 * var(--vw-mob));
    color: white;
  }
  .verify-seller-pop {
    position: absolute;
    top: calc(144 * var(--vw-mob));
    left: calc(24 * var(--vw-mob));
    height: calc(790 * var(--vw-mob));
    width: calc(310 * var(--vw-mob));
    margin: 0;
    padding: calc(15 * var(--vw-mob));
    border: calc(1 * var(--vw-mob)) solid rgba(7, 51, 108, 0.4);
    border-radius: calc(4 * var(--vw-mob));
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: calc(2 * var(--vw-mob));
    z-index: 50;
  }
  .verify-seller-pop label {
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: calc(16 * var(--vw-mob));
    color: rgba(7, 51, 108, 1);
  }
  .verify-seller-pop input {
    margin: 0;
    height: calc(40 * var(--vw-mob));
    width: calc(260 * var(--vw-mob));
    margin-bottom: calc(15 * var(--vw-mob));
    padding-left: calc(10 * var(--vw-mob));
    border: calc(1 * var(--vw-mob)) solid rgba(7, 51, 108, 0.4);
    font-size: calc(16 * var(--vw-mob));
  }
  .verify-seller-pop input:focus {
    outline: calc(5 * var(--vw-mob)) solid rgba(220, 249, 255, 1);
  }
  .seller-details-cancel {
    position: absolute;
    top: calc(370 * var(--vw-mob));
    left: calc(40 * var(--vw-mob));
    height: calc(48 * var(--vw-mob));
    width: calc(102 * var(--vw-mob));
    border: calc(1 * var(--vw-mob)) solid rgba(7, 51, 108, 1);
    border-radius: calc(4 * var(--vw-mob));
    background: transparent;
    font-weight: 500;
    font-size: calc(16 * var(--vw-mob));
    color: rgba(7, 51, 108, 1);
    z-index: 60;
  }
  .seller-details-submit {
    position: absolute;
    top: calc(370 * var(--vw-mob));
    left: calc(165 * var(--vw-mob));
    height: calc(48 * var(--vw-mob));
    width: calc(102 * var(--vw-mob));
    border: calc(1 * var(--vw-mob)) solid rgba(7, 51, 108, 1);
    border-radius: calc(4 * var(--vw-mob));
    background-color: rgba(7, 51, 108, 1);
    font-weight: 500;
    font-size: calc(16 * var(--vw-mob));
    color: white;
  }
}
