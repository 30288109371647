.about-us-container *::-webkit-scrollbar {
  width: 0;
  height: 0;
  color: transparent;
}
.about-us-container {
  min-height: calc(700 * var(--vw-unit));
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 0;
  background-color: #f5f5f5;
  border-radius: calc(8 * var(--vw-unit));
  overflow: scroll;
}

.about-us-top {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: calc(20 * var(--vw-unit));
}

.about-us-content {
  flex: 1;
  padding: calc(10 * var(--vw-unit));
  animation: fadeIn 2s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.about-us-title {
  font-size: calc(36 * var(--vw-unit));
  color: rgb(13, 71, 161, 1);
  margin-bottom: calc(20 * var(--vw-unit));
  animation: fadeIn 1s ease-in-out;
}

.about-us-text {
  font-size: calc(18 * var(--vw-unit));
  line-height: 1.6;
  color: #34495e;
  margin-bottom: calc(15 * var(--vw-unit));
}

.about-us-highlight {
  color: #e74c3c;
  cursor: pointer;
  text-decoration: underline;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    color: #e74c3c;
  }
  50% {
    color: #c0392b;
  }
  100% {
    color: #e74c3c;
  }
}

.about-us-button {
  background-color: rgb(13, 71, 161, 1);
  color: white;
  border: none;
  padding: calc(10 * var(--vw-unit)) calc(20 * var(--vw-unit));
  font-size: calc(16 * var(--vw-unit));
  border-radius: calc(5 * var(--vw-unit));
  cursor: pointer;
  margin-top: calc(20 * var(--vw-unit));
  transition:
    transform 0.3s ease,
    background-color 0.3s ease;
}

.about-us-button:hover {
  background-color: rgb(13, 71, 161, 0.8);
  transform: scale(1.05);
}

.about-us-image-slider {
  flex: 1;
  overflow: hidden;
  width: calc(400 * var(--vw-unit));
  height: calc(400 * var(--vw-unit));
  z-index: 100;
}

.image-container {
  display: flex;
  animation: slide 20s infinite;
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(-200%);
  }
  75% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.about-us-img {
  min-width: 100%;
  max-height: 100%;
  height: 100%;
  border-radius: calc(8 * var(--vw-unit));
}

@media (min-width: 760px) and (max-width: 1200px) {
  .about-us-container {
    min-height: calc(900 * var(--vw-tab));
    height: 100vh;
    flex-direction: column;
    padding: calc(15 * var(--vw-tab));
    overflow: scroll;
  }
  .about-us-top {
    flex-direction: column;
    align-items: start;
    padding: calc(20 * var(--vw-unit));
  }
  .about-us-content {
    height: calc(900 * var(--vw-tab));
    padding-right: 0;
  }

  .about-us-title {
    font-size: calc(28 * var(--vw-tab));
  }

  .about-us-text {
    font-size: calc(14 * var(--vw-tab));
  }

  .about-us-image-slider {
    overflow: visible;
    max-width: 100%;
    max-height: 100%;
    height: calc(300 * var(--vw-tab));
    width: calc(300 * var(--vw-tab));
    margin-top: calc(20 * var(--vw-tab));
  }
  .image-container {
    height: 100%;
    width: calc(760 * var(--vw-tab));
    animation: none;
  }
  .about-us-img {
    min-height: 0;
    height: 90%;
    min-width: 0;
    width: auto;
    max-width: 30%;
  }

  .about-us-button {
    font-size: calc(12 * var(--vw-tab));
    padding: 8px 16px;
    margin-bottom: calc(10 * var(--vw-tab));
  }
  .footer-about {
    position: sticky;
    bottom: 0;
    width: 100%;
    height: calc(40 * var(--vw-tab));
    background-color: rgba(5, 66, 106, 1);
    display: flex;
    align-items: center;
    color: #fff;
  }
}

@media (max-width: 760px) {
  .about-us-container {
    min-height: calc(800 * var(--vw-mob));
    height: 100vh;
    flex-direction: column;

    overflow: scroll;
  }
  .about-us-top {
    flex-direction: column;
    align-items: start;
    padding: calc(15 * var(--vw-mob));
  }
  .about-us-content {
    height: calc(800 * var(--vw-mob));
    padding-right: 0;
  }

  .about-us-title {
    font-size: calc(25 * var(--vw-mob));
  }

  .about-us-text {
    font-size: calc(11 * var(--vw-mob));
  }

  .about-us-image-slider {
    position: static;
    overflow: hidden;
    max-height: 100vh;
    max-width: 100vw;
    height: 300px;
    width: 80vw;
    padding: 10% 0;
  }
  .image-container {
    height: 80%;
    width: calc(760 * var(--vw-tab));
    animation: slide 20s infinite;
  }

  .about-us-button {
    font-size: calc(12 * var(--vw-mob));
    padding: 8px 16px;
    margin-bottom: calc(10 * var(--vw-mob));
  }
}
