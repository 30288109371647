@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

:root {
  --vw-unit: calc((100 / 1440) * 1vw);
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

p {
  margin: 0;
  padding: 0;
}

.settings-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: calc(100 * var(--vw-unit));
  background-color: rgba(250, 250, 250, 1);
  overflow: scroll;
}

.settings-heading {
  position: absolute;
  top: calc(16 * var(--vw-unit));
  left: calc(25 * var(--vw-unit));
  font-size: calc(18 * var(--vw-unit));
  font-weight: 600;
  color: rgba(46, 46, 46, 1);
}

.settings-box {
  height: calc(510 * var(--vw-unit));
  width: calc(1080 * var(--vw-unit));
  padding-top: calc(20 * var(--vw-unit));
  background-color: white;
  overflow: scroll;
}

.setting-item {
  height: calc(70 * var(--vw-unit));
  width: 96%;
  margin-left: 2%;
  margin-bottom: calc(15 * var(--vw-unit));
  display: flex;
  align-items: center;
  background: transparent;
  border: calc(1 * var(--vw-unit)) solid rgba(7, 51, 108, 0.4);
  border-radius: calc(4 * var(--vw-unit));
}

.setting-item-head {
  display: flex;
}

.setting-item img {
  width: calc(20 * var(--vw-unit));
  height: calc(24 * var(--vw-unit));
  margin-left: calc(20 * var(--vw-unit));
  margin-right: calc(20 * var(--vw-unit));
}

.setting-item p {
  width: calc(700 * var(--vw-unit));
  font-weight: 600;
  font-size: calc(16 * var(--vw-unit));
  color: rgba(7, 51, 108, 1);
}

.setting-item button {
  height: calc(36 * var(--vw-unit));
  width: calc(160 * var(--vw-unit));
  padding: 0;
  border: calc(0.5 * var(--vw-unit)) solid rgba(7, 51, 108, 1);
  border-radius: calc(4 * var(--vw-unit));
  background: transparent;
  color: rgba(7, 51, 108, 1);
  font-size: calc(14 * var(--vw-unit));
  font-weight: 500;
  margin-left: calc(80 * var(--vw-unit));
  cursor: pointer;
}

.password-popup {
  height: calc(441 * var(--vw-unit));
  width: 96%;
  margin-left: 2%;
  background-color: rgba(250, 250, 250, 1);
  display: flex;
  flex-direction: column;
}

.change-password-head {
  font-size: calc(16 * var(--vw-unit));
  font-weight: 600;
  color: rgba(7, 51, 108, 1);
  margin: calc(20 * var(--vw-unit));
  padding-bottom: calc(20 * var(--vw-unit));
  border-bottom: calc(1 * var(--vw-unit)) solid rgba(155, 179, 195, 1);
}

.password-popup label {
  font-size: calc(14 * var(--vw-unit));
  font-weight: 600;
  margin-left: calc(20 * var(--vw-unit));
}

.password-popup input {
  height: calc(42 * var(--vw-unit));
  width: calc(460 * var(--vw-unit));
  border: calc(1 * var(--vw-unit)) solid rgba(208, 208, 208, 1);
  margin-left: calc(20 * var(--vw-unit));
  margin-bottom: calc(10 * var(--vw-unit));
  padding-left: calc(12 * var(--vw-unit));
  font-size: calc(13 * var(--vw-unit));
  font-weight: 400;
}

.password-popup input:focus {
  outline: calc(5 * var(--vw-unit)) solid rgba(229, 249, 245, 1);
}

.password-pop-buttons {
  display: flex;
  margin-top: calc(20 * var(--vw-unit));
}

.cancel-button-password {
  height: calc(46 * var(--vw-unit));
  width: calc(221 * var(--vw-unit));
  margin-left: calc(20 * var(--vw-unit));
  border: calc(1 * var(--vw-unit)) solid rgba(7, 51, 108, 1);
  border-radius: calc(4 * var(--vw-unit));
  font-size: calc(16 * var(--vw-unit));
  font-weight: calc(500 * var(--vw-unit));
  color: rgba(7, 51, 108, 1);
}

.submit-button-password {
  height: calc(46 * var(--vw-unit));
  width: calc(221 * var(--vw-unit));
  margin-left: calc(20 * var(--vw-unit));
  border: none;
  border-radius: calc(4 * var(--vw-unit));
  background-color: rgba(7, 51, 108, 1);
  color: white;
  font-size: calc(16 * var(--vw-unit));
  font-weight: 500;
}

.delete-popup {
  height: calc(331 * var(--vw-unit));
  width: 96%;
  margin-left: 2%;
  margin-bottom: calc(30 * var(--vw-unit));
  background-color: rgba(250, 250, 250, 1);
  padding: calc(20 * var(--vw-unit));
}

.delete-account-head {
  font-size: calc(16 * var(--vw-unit));
  font-weight: 600;
  color: rgba(7, 51, 108, 1);
  padding-bottom: calc(10 * var(--vw-unit));
  margin-bottom: calc(20 * var(--vw-unit));
  border-bottom: calc(1 * var(--vw-unit)) solid rgba(155, 179, 195, 1);
}

.delete-account-info {
  font-size: calc(16 * var(--vw-unit));
  font-weight: 500;
  color: rgba(46, 46, 46, 1);
  line-height: calc(24 * var(--vw-unit));
}

.delete-confirm {
  margin-top: calc(40 * var(--vw-unit));
  height: calc(150 * var(--vw-unit));
  width: 96%;
}

.delete-confirm label {
  font-size: calc(16 * var(--vw-unit));
  font-weight: 600;
  color: rgba(7, 51, 108, 1);
  margin: 0;
}

.delete-confirm input {
  height: calc(42 * var(--vw-unit));
  width: calc(458 * var(--vw-unit));
  border: calc(1 * var(--vw-unit)) solid rgba(208, 208, 208, 1);
  padding-left: calc(15 * var(--vw-unit));
  background: transparent;
  font-size: calc(13 * var(--vw-unit));
  font-weight: 400;
}

.delete-confirm input:focus {
  outline: calc(5 * var(--vw-unit)) solid rgba(229, 249, 245, 1);
}

.delete-account-buttons {
  display: flex;
  margin-top: calc(20 * var(--vw-unit));
}

.delete-account-cancel {
  height: calc(46 * var(--vw-unit));
  width: calc(221 * var(--vw-unit));
  border: calc(1 * var(--vw-unit)) solid rgba(7, 51, 108, 1);
  border-radius: calc(4 * var(--vw-unit));
  color: rgba(7, 51, 108, 1);
  background: transparent;
  font-size: calc(16 * var(--vw-unit));
  font-weight: 500;
}

.delete-account-submit {
  height: calc(46 * var(--vw-unit));
  width: calc(221 * var(--vw-unit));
  border: none;
  border-radius: calc(4 * var(--vw-unit));
  color: white;
  background-color: rgba(7, 51, 108, 1);
  font-size: calc(16 * var(--vw-unit));
  font-weight: 500;
  margin-left: calc(20 * var(--vw-unit));
}

@media (min-width: 550px) and (max-width: 1200px) {
  .settings-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: calc(100 * var(--vw-tab));
    background-color: rgba(250, 250, 250, 1);
    overflow: scroll;
  }

  .settings-heading {
    position: absolute;
    top: calc(16 * var(--vw-tab));
    left: calc(25 * var(--vw-tab));
    font-size: calc(18 * var(--vw-tab));
    font-weight: 600;
    color: rgba(46, 46, 46, 1);
  }

  .settings-box {
    min-height: calc(880 * var(--vw-tab));
    height: 85vh;
    width: 98%;
    padding-top: calc(20 * var(--vw-tab));
    background-color: white;
    overflow: scroll;
  }

  .setting-item {
    height: calc(70 * var(--vw-tab));
    width: 96%;
    margin-left: 2%;
    margin-bottom: calc(15 * var(--vw-tab));
    display: flex;
    align-items: center;
    background: transparent;
    border: calc(1 * var(--vw-tab)) solid rgba(7, 51, 108, 0.4);
    border-radius: calc(4 * var(--vw-tab));
  }

  .setting-item-head {
    display: flex;
  }

  .setting-item img {
    width: calc(20 * var(--vw-tab));
    height: calc(24 * var(--vw-tab));
    margin-left: calc(20 * var(--vw-tab));
    margin-right: calc(20 * var(--vw-tab));
  }

  .setting-item p {
    width: calc(400 * var(--vw-tab));
    font-weight: 600;
    font-size: calc(16 * var(--vw-tab));
    color: rgba(7, 51, 108, 1);
  }

  .setting-item button {
    height: calc(36 * var(--vw-tab));
    width: calc(160 * var(--vw-tab));
    padding: 0;
    border: calc(0.5 * var(--vw-tab)) solid rgba(7, 51, 108, 1);
    border-radius: calc(4 * var(--vw-tab));
    background: transparent;
    color: rgba(7, 51, 108, 1);
    font-size: calc(14 * var(--vw-tab));
    font-weight: 500;
    margin-left: calc(60 * var(--vw-tab));
    cursor: pointer;
  }

  .password-popup {
    height: calc(441 * var(--vw-tab));
    width: 96%;
    margin-left: 2%;
    background-color: rgba(250, 250, 250, 1);
    display: flex;
    flex-direction: column;
  }

  .change-password-head {
    font-size: calc(16 * var(--vw-tab));
    font-weight: 600;
    color: rgba(7, 51, 108, 1);
    margin: calc(20 * var(--vw-tab));
    padding-bottom: calc(20 * var(--vw-tab));
    border-bottom: calc(1 * var(--vw-tab)) solid rgba(155, 179, 195, 1);
  }

  .password-popup label {
    font-size: calc(14 * var(--vw-tab));
    font-weight: 600;
    margin-left: calc(20 * var(--vw-tab));
  }

  .password-popup input {
    height: calc(42 * var(--vw-tab));
    width: calc(460 * var(--vw-tab));
    border: calc(1 * var(--vw-tab)) solid rgba(208, 208, 208, 1);
    margin-left: calc(20 * var(--vw-tab));
    margin-bottom: calc(10 * var(--vw-tab));
    padding-left: calc(12 * var(--vw-tab));
    font-size: calc(13 * var(--vw-tab));
    font-weight: 400;
  }

  .password-popup input:focus {
    outline: calc(5 * var(--vw-tab)) solid rgba(229, 249, 245, 1);
  }

  .password-pop-buttons {
    display: flex;
    margin-top: calc(20 * var(--vw-tab));
  }

  .cancel-button-password {
    height: calc(46 * var(--vw-tab));
    width: calc(221 * var(--vw-tab));
    margin-left: calc(20 * var(--vw-tab));
    border: calc(1 * var(--vw-tab)) solid rgba(7, 51, 108, 1);
    border-radius: calc(4 * var(--vw-tab));
    font-size: calc(16 * var(--vw-tab));
    font-weight: calc(500 * var(--vw-tab));
    color: rgba(7, 51, 108, 1);
  }

  .submit-button-password {
    height: calc(46 * var(--vw-tab));
    width: calc(221 * var(--vw-tab));
    margin-left: calc(20 * var(--vw-tab));
    border: none;
    border-radius: calc(4 * var(--vw-tab));
    background-color: rgba(7, 51, 108, 1);
    color: white;
    font-size: calc(16 * var(--vw-tab));
    font-weight: 500;
  }

  .delete-popup {
    height: calc(331 * var(--vw-tab));
    width: 96%;
    margin-left: 2%;
    margin-bottom: calc(30 * var(--vw-tab));
    background-color: rgba(250, 250, 250, 1);
    padding: calc(20 * var(--vw-tab));
  }

  .delete-account-head {
    font-size: calc(16 * var(--vw-tab));
    font-weight: 600;
    color: rgba(7, 51, 108, 1);
    padding-bottom: calc(10 * var(--vw-tab));
    margin-bottom: calc(20 * var(--vw-tab));
    border-bottom: calc(1 * var(--vw-tab)) solid rgba(155, 179, 195, 1);
  }

  .delete-account-info {
    font-size: calc(16 * var(--vw-tab));
    font-weight: 500;
    color: rgba(46, 46, 46, 1);
    line-height: calc(24 * var(--vw-tab));
  }

  .delete-confirm {
    margin-top: calc(40 * var(--vw-tab));
    height: calc(150 * var(--vw-tab));
    width: 96%;
  }

  .delete-confirm label {
    font-size: calc(16 * var(--vw-tab));
    font-weight: 600;
    color: rgba(7, 51, 108, 1);
    margin: 0;
  }

  .delete-confirm input {
    height: calc(42 * var(--vw-tab));
    width: calc(458 * var(--vw-tab));
    border: calc(1 * var(--vw-tab)) solid rgba(208, 208, 208, 1);
    padding-left: calc(15 * var(--vw-tab));
    background: transparent;
    font-size: calc(13 * var(--vw-tab));
    font-weight: 400;
  }

  .delete-confirm input:focus {
    outline: calc(5 * var(--vw-tab)) solid rgba(229, 249, 245, 1);
  }

  .delete-account-buttons {
    display: flex;
    margin-top: calc(20 * var(--vw-tab));
  }

  .delete-account-cancel {
    height: calc(46 * var(--vw-tab));
    width: calc(221 * var(--vw-tab));
    border: calc(1 * var(--vw-tab)) solid rgba(7, 51, 108, 1);
    border-radius: calc(4 * var(--vw-tab));
    color: rgba(7, 51, 108, 1);
    background: transparent;
    font-size: calc(16 * var(--vw-tab));
    font-weight: 500;
  }

  .delete-account-submit {
    height: calc(46 * var(--vw-tab));
    width: calc(221 * var(--vw-tab));
    border: none;
    border-radius: calc(4 * var(--vw-tab));
    color: white;
    background-color: rgba(7, 51, 108, 1);
    font-size: calc(16 * var(--vw-tab));
    font-weight: 500;
    margin-left: calc(20 * var(--vw-tab));
  }
}

@media (max-width: 550px) {
  .settings-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: calc(100 * var(--vw-mob));
    background-color: rgba(250, 250, 250, 1);
    overflow: scroll;
  }

  .settings-heading {
    position: absolute;
    top: calc(16 * var(--vw-mob));
    left: calc(25 * var(--vw-mob));
    font-size: calc(18 * var(--vw-mob));
    font-weight: 600;
    color: rgba(46, 46, 46, 1);
  }

  .settings-box {
    height: calc(690 * var(--vw-mob));
    width: 96%;
    padding-top: calc(20 * var(--vw-mob));
    background-color: white;
    overflow: scroll;
  }

  .setting-item {
    height: calc(132 * var(--vw-mob));
    width: 96%;
    margin-left: 2%;
    margin-bottom: calc(15 * var(--vw-mob));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: calc(20 * var(--vw-mob));
    background: transparent;
    border: calc(1 * var(--vw-mob)) solid rgba(7, 51, 108, 0.4);
    border-radius: calc(4 * var(--vw-mob));
  }

  .setting-item-head {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .setting-item img {
    width: calc(19 * var(--vw-mob));
    height: calc(22 * var(--vw-mob));
    margin-left: calc(20 * var(--vw-mob));
    margin-right: calc(20 * var(--vw-mob));
  }

  .setting-item p {
    width: auto;
    font-weight: 600;
    font-size: calc(16 * var(--vw-mob));
    color: rgba(7, 51, 108, 1);
  }

  .setting-item button {
    height: calc(36 * var(--vw-mob));
    width: calc(250 * var(--vw-mob));
    padding: 0;
    border: calc(0.5 * var(--vw-mob)) solid rgba(7, 51, 108, 1);
    border-radius: calc(4 * var(--vw-mob));
    background: transparent;
    color: rgba(7, 51, 108, 1);
    font-size: calc(14 * var(--vw-mob));
    font-weight: 500;
    margin-left: calc(10 * var(--vw-mob));
    cursor: pointer;
  }

  .password-popup {
    height: calc(441 * var(--vw-mob));
    width: 96%;
    margin-left: 2%;
    background-color: rgba(250, 250, 250, 1);
    display: flex;
    flex-direction: column;
  }

  .change-password-head {
    font-size: calc(16 * var(--vw-mob));
    font-weight: 600;
    color: rgba(7, 51, 108, 1);
    margin: calc(20 * var(--vw-mob));
    padding-bottom: calc(20 * var(--vw-mob));
    border-bottom: calc(1 * var(--vw-mob)) solid rgba(155, 179, 195, 1);
  }

  .password-popup label {
    font-size: calc(14 * var(--vw-mob));
    font-weight: 600;
    margin-left: calc(20 * var(--vw-mob));
  }

  .password-popup input {
    height: calc(42 * var(--vw-mob));
    width: calc(260 * var(--vw-mob));
    border: calc(1 * var(--vw-mob)) solid rgba(208, 208, 208, 1);
    margin-left: calc(20 * var(--vw-mob));
    margin-bottom: calc(10 * var(--vw-mob));
    padding-left: calc(12 * var(--vw-mob));
    font-size: calc(13 * var(--vw-mob));
    font-weight: 400;
  }

  .password-popup input:focus {
    outline: calc(5 * var(--vw-mob)) solid rgba(229, 249, 245, 1);
  }

  .password-pop-buttons {
    display: flex;
    margin-top: calc(20 * var(--vw-mob));
  }

  .cancel-button-password {
    height: calc(46 * var(--vw-mob));
    width: calc(160 * var(--vw-mob));
    margin-left: calc(20 * var(--vw-mob));
    border: calc(1 * var(--vw-mob)) solid rgba(7, 51, 108, 1);
    border-radius: calc(4 * var(--vw-mob));
    font-size: calc(16 * var(--vw-mob));
    font-weight: calc(500 * var(--vw-mob));
    color: rgba(7, 51, 108, 1);
  }

  .submit-button-password {
    height: calc(46 * var(--vw-mob));
    width: calc(160 * var(--vw-mob));
    margin-left: calc(10 * var(--vw-mob));
    border: none;
    border-radius: calc(4 * var(--vw-mob));
    background-color: rgba(7, 51, 108, 1);
    color: white;
    font-size: calc(16 * var(--vw-mob));
    font-weight: 500;
  }

  .delete-popup {
    height: calc(431 * var(--vw-mob));
    width: 96%;
    margin-left: 2%;
    margin-bottom: calc(30 * var(--vw-mob));
    background-color: rgba(250, 250, 250, 1);
    padding: calc(20 * var(--vw-mob));
  }

  .delete-account-head {
    font-size: calc(16 * var(--vw-mob));
    font-weight: 600;
    color: rgba(7, 51, 108, 1);
    padding-bottom: calc(10 * var(--vw-mob));
    margin-bottom: calc(20 * var(--vw-mob));
    border-bottom: calc(1 * var(--vw-mob)) solid rgba(155, 179, 195, 1);
  }

  .delete-account-info {
    font-size: calc(16 * var(--vw-mob));
    font-weight: 500;
    color: rgba(46, 46, 46, 1);
    line-height: calc(24 * var(--vw-mob));
  }

  .delete-confirm {
    margin-top: calc(40 * var(--vw-mob));
    height: calc(150 * var(--vw-mob));
    width: 96%;
  }

  .delete-confirm label {
    font-size: calc(16 * var(--vw-mob));
    font-weight: 600;
    color: rgba(7, 51, 108, 1);
    margin: 0;
  }

  .delete-confirm input {
    height: calc(42 * var(--vw-mob));
    width: calc(258 * var(--vw-mob));
    border: calc(1 * var(--vw-mob)) solid rgba(208, 208, 208, 1);
    padding-left: calc(15 * var(--vw-mob));
    background: transparent;
    font-size: calc(13 * var(--vw-mob));
    font-weight: 400;
  }

  .delete-confirm input:focus {
    outline: calc(5 * var(--vw-mob)) solid rgba(229, 249, 245, 1);
  }

  .delete-account-buttons {
    display: flex;
    margin-top: calc(20 * var(--vw-mob));
  }

  .delete-account-cancel {
    height: calc(46 * var(--vw-mob));
    width: calc(221 * var(--vw-mob));
    border: calc(1 * var(--vw-mob)) solid rgba(7, 51, 108, 1);
    border-radius: calc(4 * var(--vw-mob));
    color: rgba(7, 51, 108, 1);
    background: transparent;
    font-size: calc(12 * var(--vw-mob));
    font-weight: 500;
  }

  .delete-account-submit {
    height: calc(46 * var(--vw-mob));
    width: calc(221 * var(--vw-mob));
    border: none;
    border-radius: calc(4 * var(--vw-mob));
    color: white;
    background-color: rgba(7, 51, 108, 1);
    font-size: calc(12 * var(--vw-mob));
    font-weight: 500;
    margin-left: calc(10 * var(--vw-mob));
  }
}
