.payment-container {
  height: calc(580 * var(--vw-unit));
  width: calc(1020 * var(--vw-unit));
  margin: 0 auto;
  padding: calc(20 * var(--vw-unit));
  background: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: scroll;
}

.payment-heading {
  font-size: calc(20 * var(--vw-unit));
  font-weight: 600;
  color: rgba(7, 51, 108, 1);
  margin-bottom: calc(20 * var(--vw-unit));
}

.promo-holder {
  margin-bottom: calc(60 * var(--vw-unit));
}

.plan-selection {
  margin: calc(20 * var(--vw-unit));
  display: flex;
  flex-direction: column;
}

.subscription-table {
  width: 100%;
  border-collapse: collapse;
}

.subscription-table thead th {
  background-color: rgba(7, 51, 108, 1);
  color: white;
  border: calc(3 * var(--vw-unit)) solid white;
  padding: 0;
  padding-bottom: calc(10 * var(--vw-unit));
  font-size: calc(16 * var(--vw-unit));
  text-align: center;
  cursor: pointer;
}

.heading-select-plan {
  background-color: white !important;
  color: rgba(7, 51, 108, 1) !important;
  border: calc(3 * var(--vw-unit)) solid white;
  padding: 0 !important;
  font-size: calc(16 * var(--vw-unit));
  text-align: center;
  cursor: pointer;
  font-weight: 600;
}
.heading-select-plan-active {
  background-color: rgba(7, 51, 108, 1) !important;
  color: white !important;
  padding: calc(10 * var(--vw-unit)) !important;
  font-size: calc(16 * var(--vw-unit));
  text-align: center;
  cursor: pointer;
  font-weight: 600;
}

.subscription-table thead th.highlighted {
  background-image: linear-gradient(
    45deg,
    rgb(58, 185, 159),
    rgb(17, 141, 130)
  );
  color: wheat;
  border: calc(2 * var(--vw-unit)) solid white;
}

.subscription-table thead th {
  cursor: pointer;
}

.subscription-table tbody td {
  font-size: calc(14 * var(--vw-unit));
  padding: calc(10 * var(--vw-unit)) calc(15 * var(--vw-unit));
  border: calc(1 * var(--vw-unit)) solid #ddd;
  text-align: center;
  vertical-align: middle;
}

.subscription-table tbody tr:nth-child(even) {
  background-image: linear-gradient(
    45deg,
    rgba(226, 255, 249, 1),
    rgba(231, 245, 255, 1)
  );
}

.subscription-table tbody tr:nth-child(odd) {
  background-color: #fff;
}

.subscription-table td:first-child {
  text-align: left;
  padding-left: calc(20 * var(--vw-unit));
}

.tick {
  color: green;
}

.cross {
  color: red;
}

.promocode-input {
  width: 50%;
  height: calc(45 * var(--vw-unit));
  padding: calc(8 * var(--vw-unit));
  margin: calc(20 * var(--vw-unit)) calc(50 * var(--vw-unit)) 0
    calc(20 * var(--vw-unit));
  border: calc(1 * var(--vw-unit)) solid #ddd;
  border-radius: calc(4 * var(--vw-unit));
  font-size: calc(14 * var(--vw-unit));
}

.promocode-input:focus {
  outline: calc(5 * var(--vw-unit)) solid rgba(220, 249, 245, 1);
}

.confirm-button {
  height: calc(45 * var(--vw-unit));
  width: calc(150 * var(--vw-unit));
  padding: calc(5 * var(--vw-unit));
  background: rgba(7, 51, 108, 1);
  color: #fff;
  border: none;
  border-radius: calc(4 * var(--vw-unit));
  cursor: pointer;
  font-size: calc(15 * var(--vw-unit));
}

.confirm-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.error-message {
  color: #fa755a;
  font-size: calc(18 * var(--vw-unit));
  text-align: center;
}
.payment-details-list {
  margin-bottom: calc(50 * var(--vw-unit));
}
.payment-details {
  font-size: calc(20 * var(--vw-unit));
  font-style: normal;
  color: black;

  margin-bottom: calc(10 * var(--vw-unit));
}
.payment-details-value {
  font-style: italic;
  color: #666;
  font-size: calc(18 * var(--vw-unit));
  margin-left: calc(20 * var(--vw-unit));
}

.payment-form {
  width: 70%;
  height: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: calc(14 * var(--vw-unit));
  border: calc(1 * var(--vw-unit)) solid rgba(7, 51, 108, 1);
  border-radius: calc(4 * var(--vw-unit));
}
.cardelement-holder {
  width: 90%;
  height: 40%;
  border: calc(3 * var(--vw-unit)) solid rgba(220, 249, 245, 1);
  padding: calc(18 * var(--vw-unit));
  overflow: hidden;
}
.buttons-holder-payments2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: calc(40 * var(--vw-unit));
}
@media (min-width: 550px) and (max-width: 1200px) {
  .payment-container {
    height: calc(920 * var(--vw-tab));
    width: calc(980 * var(--vw-tab));
    margin: 0 auto;
    padding: calc(20 * var(--vw-tab));
    background: white;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .payment-heading {
    font-size: calc(20 * var(--vw-tab));
    font-weight: 600;
    color: rgba(7, 51, 108, 1);
    margin-bottom: calc(20 * var(--vw-tab));
  }

  .promo-holder {
    margin-bottom: calc(40 * var(--vw-tab));
  }
  .subscription-table {
    width: 100%;
    border-collapse: collapse;
  }

  .subscription-table thead th {
    background-color: rgba(7, 51, 108, 1);
    color: white;
    border: calc(3 * var(--vw-tab)) solid white;
    font-size: calc(16 * var(--vw-tab));
    padding: calc(15 * var(--vw-tab));
    text-align: center;
    cursor: pointer;
  }

  .subscription-table thead th.highlighted {
    background-image: linear-gradient(
      45deg,
      rgb(58, 185, 159),
      rgb(17, 141, 130)
    );
    color: wheat;
    border: calc(2 * var(--vw-tab)) solid white;
  }

  .subscription-table thead th {
    cursor: pointer;
  }

  .subscription-table tbody td {
    font-size: calc(14 * var(--vw-tab));
    padding: calc(10 * var(--vw-tab)) calc(15 * var(--vw-tab));
    border: calc(1 * var(--vw-tab)) solid #ddd;
    text-align: center;
    vertical-align: middle;
  }

  .promocode-input {
    width: 50%;
    height: calc(45 * var(--vw-tab));
    padding: calc(8 * var(--vw-tab));
    margin: calc(20 * var(--vw-tab)) calc(50 * var(--vw-tab)) 0
      calc(20 * var(--vw-tab));
    border: calc(1 * var(--vw-tab)) solid #ddd;
    border-radius: calc(4 * var(--vw-tab));
    font-size: calc(14 * var(--vw-tab));
  }

  .promocode-input:focus {
    outline: calc(5 * var(--vw-tab)) solid rgba(220, 249, 245, 1);
  }

  .confirm-button {
    height: calc(45 * var(--vw-tab));
    width: calc(150 * var(--vw-tab));
    padding: calc(5 * var(--vw-tab));
    background: rgba(7, 51, 108, 1);
    color: #fff;
    border: none;
    border-radius: calc(4 * var(--vw-tab));
    cursor: pointer;
    font-size: calc(15 * var(--vw-tab));
  }

  .confirm-button:disabled {
    background: #ccc;
    cursor: not-allowed;
  }

  .error-message {
    color: #fa755a;
    font-size: calc(18 * var(--vw-tab));
    text-align: center;
  }
  .payment-details {
    font-size: calc(22 * var(--vw-tab));
    color: black;
    margin-bottom: calc(20 * var(--vw-tab));
  }
  .payment-details-value {
    font-style: normal;
    color: #666;
    font-style: italic;
    font-size: calc(22 * var(--vw-tab));
  }
  .payment-form {
    width: 70%;
    height: 25%;
    display: flex;
    flex-direction: column;
    margin-top: calc(90 * var(--vw-tab));
    gap: calc(30 * var(--vw-tab));
  }
  .cardelement-holder {
    width: 90%;
    height: 25%;
    border: calc(3 * var(--vw-unit)) solid rgba(220, 249, 245, 1);
    padding: calc(20 * var(--vw-unit));
    overflow: hidden;
  }
  .buttons-holder-payments2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: calc(40 * var(--vw-unit));
  }
}
@media (max-width: 550px) {
  .payment-container {
    min-height: calc(620 * var(--vw-mob));
    height: 100%;
    width: 100%;
    margin: 0 auto;
    padding: calc(20 * var(--vw-mob));
    background: white;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: scroll;
  }

  .payment-heading {
    font-size: calc(20 * var(--vw-mob));
    font-weight: 600;
    color: rgba(7, 51, 108, 1);
    margin-bottom: calc(20 * var(--vw-mob));
  }

  .subscription-table {
    width: 100%;
    border-collapse: collapse;
  }

  .subscription-table thead th {
    background-color: rgba(7, 51, 108, 1);
    color: white;
    border: calc(3 * var(--vw-mob)) solid white;
    font-size: calc(16 * var(--vw-mob));
    padding: calc(15 * var(--vw-mob));
    text-align: center;
    cursor: pointer;
  }

  .subscription-table thead th.highlighted {
    background-image: linear-gradient(
      45deg,
      rgb(58, 185, 159),
      rgb(17, 141, 130)
    );
    color: wheat;
    border: calc(2 * var(--vw-mob)) solid white;
  }

  .subscription-table thead th {
    cursor: pointer;
  }

  .subscription-table tbody td {
    font-size: calc(14 * var(--vw-mob));
    padding: calc(10 * var(--vw-mob)) calc(15 * var(--vw-mob));
    border: calc(1 * var(--vw-mob)) solid #ddd;
    text-align: center;
    vertical-align: middle;
  }

  .promocode-input {
    width: 100%;
    height: calc(45 * var(--vw-mob));
    padding: calc(8 * var(--vw-mob));
    margin: calc(20 * var(--vw-mob)) calc(50 * var(--vw-mob))
      calc(15 * var(--vw-mob)) 0;
    border: calc(1 * var(--vw-mob)) solid #ddd;
    border-radius: calc(4 * var(--vw-mob));
    font-size: calc(14 * var(--vw-mob));
  }

  .promocode-input:focus {
    outline: calc(5 * var(--vw-mob)) solid rgba(220, 249, 245, 1);
  }

  .confirm-button {
    height: calc(45 * var(--vw-mob));
    width: calc(150 * var(--vw-mob));
    padding: calc(5 * var(--vw-mob));
    background: rgba(7, 51, 108, 1);
    color: #fff;
    border: none;
    border-radius: calc(4 * var(--vw-mob));
    cursor: pointer;
    font-size: calc(15 * var(--vw-mob));
  }

  .confirm-button:disabled {
    background: #ccc;
    cursor: not-allowed;
  }

  .error-message {
    color: #fa755a;
    font-size: calc(18 * var(--vw-mob));
    text-align: center;
  }
  .payment-details {
    font-size: calc(20 * var(--vw-mob));
    color: black;
    margin-bottom: calc(40 * var(--vw-mob));
  }
  .payment-details-value {
    font-size: calc(20 * var(--vw-mob));
    font-style: normal;
    font-style: italic;
    color: #666;
  }
  .payment-form {
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: column;
    border-radius: calc(4 * var(--vw-mob));
    gap: calc(30 * var(--vw-mob));
  }
  .cardelement-holder {
    width: 92%;
    height: 25%;
    border: calc(3 * var(--vw-mob)) solid rgba(220, 249, 245, 1);
    padding: calc(10 * var(--vw-mob)) 0;
    overflow: hidden;
  }
  .buttons-holder-payments2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: calc(40 * var(--vw-unit));
  }
}
