@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

:root {
  --vw-unit: calc((100 / 1440) * 1vw);
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

p {
  margin: 0;
  padding: 0;
}

#pagecontent-dash {
  width: 100%;
  height: 100%;
  margin-top: 0;
  overflow: hidden;
}

.main-heading {
  text-align: center;
  margin-bottom: 30px;
}

.profile-img {
  width: calc(40 * var(--vw-unit));
  height: calc(40 * var(--vw-unit));
  border-radius: 50%;
}

.dashboard-head {
  height: calc(100 * var(--vw-unit));
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-direction: row;
  border-bottom: calc(1 * var(--vw-unit)) solid rgb(13, 71, 161, 0.1);
}

.profile-holder-dashboard {
  height: calc(65 * var(--vw-unit));
  width: calc(65 * var(--vw-unit));
  margin-right: calc(20 * var(--vw-unit));
  margin-left: calc(20 * var(--vw-unit));
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.profile-holder-dashboard img {
  height: calc(70 * var(--vw-unit));
  max-width: calc(70 * var(--vw-unit));
  border: calc(6 * var(--vw-unit)) solid rgb(228, 227, 227);
  border-radius: 50%;
}

.welcome-holder-dashboard {
  height: 100%;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#main-text-dashboard {
  font-weight: 500;
  font-size: calc(20 * var(--vw-unit));
  color: rgb(13, 71, 161, 1);
}

#side-text-dashboard {
  font-weight: 400;
  color: rgba(80, 80, 80, 0.8);
  font-size: calc(14 * var(--vw-unit));
}

.dashboard-body {
  height: 85%;
  width: 100%;
  background-color: white;
  overflow: scroll;
}

.empty-dashboard {
  height: calc(250 * var(--vw-unit));
}
.start-posting {
  height: calc(40 * var(--vw-unit));
  width: calc(140 * var(--vw-unit));
  border: none;
  border-radius: calc(4 * var(--vw-unit));
  background-color: rgb(13, 71, 161, 1);
  color: white;
  font-size: calc(18 * var(--vw-unit));
  font-weight: 500;
}
.dashboard-heading {
  font-size: calc(20 * var(--vw-unit));
  font-weight: 500;
  text-align: center;
  color: rgb(13, 71, 161, 1);
  margin-top: calc(15 * var(--vw-unit));
  margin-bottom: calc(10 * var(--vw-unit));
}

.user-posts {
  height: auto;
  width: 90%;
  margin: calc(40 * var(--vw-unit));
  margin-top: calc(10 * var(--vw-unit));
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
}

.post-card {
  height: calc(50 * var(--vw-unit));
  width: 100%;
  border: none;
  border-radius: calc(4 * var(--vw-unit));
  background-color: rgba(229, 249, 245, 1);
  display: flex;
  align-items: center;
  overflow: scroll;
  margin-top: calc(10 * var(--vw-unit));
  margin-bottom: calc(10 * var(--vw-unit));
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.post-card img {
  height: calc(6 * var(--vw-unit));
  margin-left: calc(20 * var(--vw-unit));
  margin-right: calc(20 * var(--vw-unit));
}

.post-card-type {
  width: calc(400 * var(--vw-unit));
  font-size: calc(18 * var(--vw-unit));
  font-weight: 500;
  margin-right: calc(20 * var(--vw-unit));
  color: rgb(13, 71, 161, 1);
}

.post-card-upload {
  width: calc(100 * var(--vw-unit));
  font-size: calc(12 * var(--vw-unit));
  color: rgba(80, 80, 80, 0.8);
  margin-right: calc(50 * var(--vw-unit));
}

.post-card-startdate {
  width: calc(250 * var(--vw-unit));
  font-size: calc(12 * var(--vw-unit));
  color: rgba(80, 80, 80, 0.8);
}

.post-card button {
  height: calc(30 * var(--vw-unit));
  width: calc(80 * var(--vw-unit));
  border: none;
  border-radius: calc(4 * var(--vw-unit));
  background-color: rgb(13, 71, 161, 1);
  color: white;
  font-size: calc(12 * var(--vw-unit));
}

.editpost-holder-dashboard {
  position: fixed;
  top: calc(150 * var(--vw-unit));
  left: calc(400 * var(--vw-unit));
  max-height: calc(450 * var(--vw-unit));
  height: 65vh;
  width: calc(800 * var(--vw-unit));
  box-shadow: calc(500 * var(--vw-mob)) calc(500 * var(--vw-mob))
    calc(500 * var(--vw-mob)) calc(500 * var(--vw-mob)) rgba(80, 80, 80, 0.2);
  overflow: auto;
}

@media (min-width: 550px) and (max-width: 1200px) {
  #pagecontent-dash {
    width: 100%;
    height: 100%;
    margin-top: 0;
    overflow: hidden;
  }

  .dashboard-head {
    height: calc(100 * var(--vw-tab));
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: calc(1 * var(--vw-tab)) solid rgb(13, 71, 161, 0.1);
  }

  .profile-holder-dashboard {
    height: calc(70 * var(--vw-tab));
    width: calc(70 * var(--vw-tab));
    margin-right: calc(20 * var(--vw-tab));
    margin-left: calc(50 * var(--vw-unit));
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .profile-holder-dashboard img {
    height: calc(60 * var(--vw-tab));
    width: calc(60 * var(--vw-tab));
    border: calc(6 * var(--vw-tab)) solid rgb(228, 227, 227);
    border-radius: 50%;
  }

  .welcome-holder-dashboard {
    height: 100%;
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  #main-text-dashboard {
    font-weight: 500;
    font-size: calc(18 * var(--vw-tab));
    color: rgb(13, 71, 161, 1);
  }

  #side-text-dashboard {
    font-weight: 400;
    color: rgba(80, 80, 80, 0.8);
    font-size: calc(12 * var(--vw-tab));
  }

  .dashboard-body {
    height: calc(840 * var(--vw-tab));
    width: 100%;
    background-color: white;
    overflow: scroll;
  }
  .empty-dashboard {
    height: calc(250 * var(--vw-tab));
  }
  .start-posting {
    height: calc(40 * var(--vw-tab));
    width: calc(140 * var(--vw-tab));
    border: none;
    border-radius: calc(4 * var(--vw-tab));
    background-color: rgb(13, 71, 161, 1);
    color: white;
    font-size: calc(18 * var(--vw-tab));
    font-weight: 500;
  }

  .dashboard-heading {
    font-size: calc(20 * var(--vw-tab));
    font-weight: 500;
    text-align: center;
    color: rgb(13, 71, 161, 1);
    margin-top: calc(13 * var(--vw-tab));
    margin-bottom: calc(10 * var(--vw-tab));
  }

  .user-posts {
    height: auto;
    width: 90%;
    margin-top: calc(10 * var(--vw-tab));
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
  }

  .post-card {
    height: calc(50 * var(--vw-tab));
    width: 100%;
    border: none;
    border-radius: calc(4 * var(--vw-tab));
    background-color: rgba(229, 249, 245, 1);
    display: flex;
    align-items: center;
    overflow: scroll;
    margin-top: calc(10 * var(--vw-tab));
    margin-bottom: calc(10 * var(--vw-tab));
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }

  .post-card img {
    height: calc(6 * var(--vw-tab));
    margin-left: calc(15 * var(--vw-tab));
    margin-right: calc(15 * var(--vw-tab));
  }

  .post-card-type {
    width: calc(400 * var(--vw-tab));
    font-size: calc(14 * var(--vw-tab));
    font-weight: 500;
    margin-right: calc(20 * var(--vw-tab));
    color: rgb(13, 71, 161, 1);
  }

  .post-card-upload {
    width: calc(100 * var(--vw-tab));
    font-size: calc(8 * var(--vw-tab));
    color: rgba(80, 80, 80, 0.8);
    margin-right: calc(15 * var(--vw-tab));
  }

  .post-card-startdate {
    width: calc(250 * var(--vw-tab));
    font-size: calc(9 * var(--vw-tab));
    color: rgb(13, 71, 161, 1);
  }

  .post-card button {
    height: calc(30 * var(--vw-tab));
    width: calc(80 * var(--vw-tab));
    margin-right: calc(10 * var(--vw-tab));
    border: none;
    border-radius: calc(4 * var(--vw-tab));
    background-color: rgb(13, 71, 161, 1);
    color: white;
    font-size: calc(12 * var(--vw-tab));
  }

  .editpost-holder-dashboard {
    position: fixed;
    top: calc(180 * var(--vw-tab));
    left: calc(80 * var(--vw-tab));
    max-height: calc(600 * var(--vw-tab));
    height: 60vh;
    width: calc(650 * var(--vw-tab));
    border-radius: calc(8 * var(--vw-tab));
    box-shadow: calc(8 * var(--vw-tab)) calc(8 * var(--vw-tab))
      calc(180 * var(--vw-tab)) calc(180 * var(--vw-tab)) rgba(80, 80, 80, 0.2);
    overflow: scroll;
  }
}

@media (max-width: 550px) {
  #pagecontent-dash {
    width: 100%;
    height: 100%;
    margin-top: 0;
    overflow: hidden;
  }

  .dashboard-head {
    height: calc(100 * var(--vw-mob));
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: calc(1 * var(--vw-mob)) solid rgb(13, 71, 161, 0.1);
  }

  .profile-holder-dashboard {
    height: calc(70 * var(--vw-mob));
    width: calc(70 * var(--vw-mob));
    margin-right: calc(20 * var(--vw-mob));
    margin-left: calc(10 * var(--vw-mob));
    display: flex;
    border-radius: 50%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .profile-holder-dashboard img {
    height: calc(60 * var(--vw-mob));
    width: calc(60 * var(--vw-mob));
    border: calc(6 * var(--vw-mob)) solid rgb(228, 227, 227);
    border-radius: 50%;
  }

  .welcome-holder-dashboard {
    height: 100%;
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  #main-text-dashboard {
    font-weight: 500;
    font-size: calc(18 * var(--vw-mob));
    color: rgb(13, 71, 161, 1);
  }

  #side-text-dashboard {
    font-weight: 400;
    color: rgba(80, 80, 80, 0.8);
    font-size: calc(12 * var(--vw-mob));
  }

  .dashboard-body {
    height: calc(660 * var(--vw-mob));
    width: 100%;
    background-color: white;
    overflow: scroll;
  }
  .empty-dashboard {
    height: calc(250 * var(--vw-mob));
  }
  .start-posting {
    height: calc(40 * var(--vw-mob));
    width: calc(140 * var(--vw-mob));
    border: none;
    border-radius: calc(4 * var(--vw-mob));
    background-color: rgb(13, 71, 161, 1);
    color: white;
    font-size: calc(18 * var(--vw-mob));
    font-weight: 500;
  }

  .dashboard-heading {
    font-size: calc(20 * var(--vw-mob));
    font-weight: 500;
    text-align: center;
    color: rgb(13, 71, 161, 1);
    margin-top: calc(13 * var(--vw-mob));
    margin-bottom: calc(10 * var(--vw-mob));
  }

  .user-posts {
    height: auto;
    width: 90%;
    margin-top: calc(10 * var(--vw-mob));
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
  }

  .post-card {
    height: calc(50 * var(--vw-mob));
    width: 100%;
    border: none;
    border-radius: calc(4 * var(--vw-mob));
    background-color: rgba(229, 249, 245, 1);
    display: flex;
    align-items: center;
    overflow: scroll;
    margin-top: calc(10 * var(--vw-mob));
    margin-bottom: calc(10 * var(--vw-mob));
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }

  .post-card img {
    height: calc(6 * var(--vw-mob));
    margin-left: calc(15 * var(--vw-mob));
    margin-right: calc(15 * var(--vw-mob));
  }

  .post-card-type {
    width: calc(400 * var(--vw-mob));
    font-size: calc(14 * var(--vw-mob));
    font-weight: 500;
    margin-right: calc(20 * var(--vw-mob));
    color: rgb(13, 71, 161, 1);
  }

  .post-card-upload {
    width: calc(100 * var(--vw-mob));
    font-size: calc(8 * var(--vw-mob));
    color: rgba(80, 80, 80, 0.8);
    margin-right: calc(15 * var(--vw-mob));
  }

  .post-card-startdate {
    width: calc(250 * var(--vw-mob));
    font-size: calc(9 * var(--vw-mob));
    color: rgb(13, 71, 161, 1);
  }

  .post-card button {
    visibility: hidden;
    height: calc(30 * var(--vw-mob));
    width: calc(80 * var(--vw-mob));
    border: none;
    border-radius: calc(4 * var(--vw-mob));
    background-color: rgb(13, 71, 161, 1);
    color: white;
    font-size: calc(12 * var(--vw-mob));
  }

  .editpost-holder-dashboard {
    position: fixed;
    top: calc(200 * var(--vw-mob));
    left: 2%;
    max-height: calc(500 * var(--vw-mob));
    height: 40vh;
    width: 96%;
    border-radius: calc(8 * var(--vw-mob));
    box-shadow: calc(500 * var(--vw-mob)) calc(500 * var(--vw-mob))
      calc(500 * var(--vw-mob)) calc(500 * var(--vw-mob)) rgba(80, 80, 80, 0.2);
    background-color: white;
    overflow: scroll;
  }
}
