@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

:root {
  --vw-unit: calc((100 / 1440) * 1vw);
  --vw-htab: calc((100 / 834) * 1vw);
  --vw-mob: calc((100 / 390) * 1vw);
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

body {
  overflow-y: auto;
  margin: 0;
}

p {
  margin: 0;
  padding: 0;
}

.homepage {
  height: calc(4075 * var(--vw-unit));
  width: 100vw;
}

.page1 {
  height: calc(1100 * var(--vw-unit));
  width: 100%;
  background-image: linear-gradient(
    45deg,
    rgba(226, 255, 249, 1),
    rgba(231, 245, 255, 1)
  );
}

.navbar {
  height: calc(120 * var(--vw-unit));
  width: 100%;
  background: transparent;
  display: flex;
  align-items: center;
}

.logo {
  position: absolute;
  top: calc(35 * var(--vw-unit));
  left: calc(100 * var(--vw-unit));
  width: calc(260 * var(--vw-unit));
}

.login {
  position: absolute;
  height: calc(47 * var(--vw-unit));
  width: calc(130 * var(--vw-unit));
  border: calc(1 * var(--vw-unit)) solid rgba(7, 51, 108, 1);
  border-radius: calc(100 * var(--vw-unit));
  background-color: rgba(5, 66, 106, 1);
  color: white;
  left: calc(1210 * var(--vw-unit));
  padding: calc(12 * var(--vw-unit)), calc(40 * var(--vw-unit));
  cursor: pointer;
  font-size: calc(16 * var(--vw-unit));
}

.calculator-tool-btn {
  position: absolute;
  height: calc(47 * var(--vw-unit));
  width: calc(340 * var(--vw-unit));
  border: none;
  background: transparent;
  color: rgba(7, 51, 108, 1);
  left: calc(604 * var(--vw-unit));
  padding: calc(12 * var(--vw-unit)) calc(20 * var(--vw-unit));
  font-size: calc(16 * var(--vw-unit));
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: calc(10 * var(--vw-unit));
  cursor: pointer;
}
.tool-btn-home {
  position: absolute;
  height: calc(47 * var(--vw-unit));
  width: calc(100 * var(--vw-unit));
  border: none;
  background: transparent;
  color: rgba(7, 51, 108, 1);
  left: calc(904 * var(--vw-unit));
  padding: calc(12 * var(--vw-unit)) calc(20 * var(--vw-unit));
  font-size: calc(16 * var(--vw-unit));
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: calc(10 * var(--vw-unit));
  cursor: pointer;
}

.calculator-tool-btn img {
  height: 100%;
}

.tools-options-home {
  position: absolute;
  top: calc(100 * var(--vw-unit));
  left: calc(794 * var(--vw-unit));
  width: calc(300 * var(--vw-unit));
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  background-color: rgba(110, 240, 219, 1);
  overflow: hidden;
  animation: slideOpen 0.3s;
}

@keyframes slideOpen {
  0% {
    height: 0%;
  }
  100% {
    height: 80%;
  }
}
.tools-options-home p {
  padding: calc(10 * var(--vw-unit));
  text-align: center;
  width: 100%;
  color: rgba(5, 66, 106, 1);
  cursor: pointer;
  font-size: calc(18 * var(--vw-unit));
  font-weight: 600;
  border-bottom: calc(1 * var(--vw-unit)) solid white;
}

.aboutus-btn-home {
  position: absolute;
  height: calc(47 * var(--vw-unit));
  width: auto;
  border: none;
  background: transparent;
  color: rgba(7, 51, 108, 1);
  left: calc(1054 * var(--vw-unit));
  padding: calc(12 * var(--vw-unit)) calc(20 * var(--vw-unit));
  font-size: calc(16 * var(--vw-unit));
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: calc(10 * var(--vw-unit));
}

.aboutus-btn-home img {
  height: 100%;
}

.tutorial-btn-home {
  position: absolute;
  height: calc(47 * var(--vw-unit));
  width: auto;
  border: none;
  background: transparent;
  color: rgba(7, 51, 108, 1);
  left: calc(984 * var(--vw-unit));
  padding: calc(12 * var(--vw-unit)) calc(20 * var(--vw-unit));
  font-size: calc(16 * var(--vw-unit));
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: calc(10 * var(--vw-unit));
}

.tutorial-btn-home img {
  height: 100%;
}

.creditcard-home {
  position: absolute;
  top: calc(478 * var(--vw-unit));
  left: calc(820 * var(--vw-unit));
  font-size: calc(22 * var(--vw-unit));
  font-weight: 700;
  color: rgba(5, 66, 106, 1);
}

.select-role-home {
  position: absolute;
  top: calc(440 * var(--vw-unit));
  left: calc(357 * var(--vw-unit));
  width: calc(726 * var(--vw-unit));
  display: flex;
  justify-content: center;
  align-items: center;
  gap: calc(30 * var(--vw-unit));
}

.role-btn {
  height: calc(68 * var(--vw-unit));
  width: calc(248 * var(--vw-unit));
  border: calc(1 * var(--vw-unit)) solid rgba(5, 66, 106, 1);
  border-radius: calc(100 * var(--vw-unit));
  background: transparent;
  color: rgba(5, 66, 106, 1);
  font-size: calc(24 * var(--vw-unit));
  font-weight: 700;
}

.role-btn-active {
  height: calc(68 * var(--vw-unit));
  width: calc(248 * var(--vw-unit));
  border: none;
  border-radius: calc(100 * var(--vw-unit));
  background-color: rgba(5, 66, 106, 1);
  color: white;
  font-size: calc(24 * var(--vw-unit));
  font-weight: 700;
}

.role-background {
  position: absolute;
  top: calc(732 * var(--vw-unit));
  left: calc(110 * var(--vw-unit));
  height: calc(334 * var(--vw-unit));
}

.need-insurance-box,
.provide-insurance-box {
  position: absolute;
  top: calc(580 * var(--vw-unit));
  left: calc(330 * var(--vw-unit));
  height: calc(421 * var(--vw-unit));
  width: calc(780 * var(--vw-unit));
  border: none;
  border-radius: calc(9 * var(--vw-unit));
  background-color: white;
  padding: calc(20 * var(--vw-unit));
  box-shadow: calc(-8 * var(--vw-unit)) calc(8 * var(--vw-unit))
    calc(34 * var(--vw-unit)) 0 rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease-in-out;
  z-index: 20;
}

.need-insurance-box:hover,
.provide-insurance-box:hover {
  transform: scale(1.05);
}

.btn-holder-home {
  height: 30%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.select-wrapper-home {
  height: calc(52 * var(--vw-unit));
  width: calc(370 * var(--vw-unit));
  border: calc(1 * var(--vw-unit)) solid rgba(7, 51, 108, 0.2);
  border-radius: calc(100 * var(--vw-unit));
  display: flex;
  justify-content: center;
}

.btn-holder-home select {
  height: 100%;
  width: 90%;
  border: none;
  background: transparent;
  color: rgba(5, 66, 106, 1);
  font-size: calc(24 * var(--vw-unit));
  font-weight: 600;
  padding: 0;
}
.btn-holder-home select:focus {
  outline: none;
}

.need-insurance-box-content {
  height: 70%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.need-insurance-heading {
  height: calc(37 * var(--vw-unit));
  text-align: center;
  font-size: calc(34 * var(--vw-unit));
  font-weight: 700;
  color: rgba(5, 66, 106, 1);
  margin-top: calc(10 * var(--vw-unit));
}

.need-insurance-sidehead {
  height: calc(37 * var(--vw-unit));
  text-align: center;
  font-size: calc(24 * var(--vw-unit));
  font-weight: 500;
  color: rgba(5, 66, 106, 1);
  margin-top: calc(30 * var(--vw-unit));
}

.need-insurance-info {
  height: calc(50 * var(--vw-unit));
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: calc(30 * var(--vw-unit));
  margin-top: calc(50 * var(--vw-unit));
}

.need-insurance-info h3 {
  height: 80%;
  border-radius: calc(100 * var(--vw-unit));
  background-color: rgba(115, 246, 201, 1);
  font-size: calc(18 * var(--vw-unit));
  font-weight: 500;
  color: rgba(5, 66, 106, 1);
  padding: 0 calc(20 * var(--vw-unit));
  display: flex;
  justify-content: center;
  align-items: center;
}

.quote-head {
  position: absolute;
  top: calc(220 * var(--vw-unit));
  left: calc(157 * var(--vw-unit));
  font-size: calc(44 * var(--vw-unit));
  width: calc(1126 * var(--vw-unit));
  text-align: center;
  font-weight: 700;
  color: rgba(5, 66, 106, 1);
}

.quote-info {
  position: absolute;
  width: calc(831 * var(--vw-unit));
  top: calc(320 * var(--vw-unit));
  left: calc(304 * var(--vw-unit));
  font-size: calc(26 * var(--vw-unit));
  text-align: center;
  line-height: calc(30 * var(--vw-unit));
  font-weight: 400;
  color: rgba(79, 79, 79, 1);
}

.get-started {
  position: absolute;
  height: calc(45 * var(--vw-unit));
  width: calc(200 * var(--vw-unit));
  top: calc(478 * var(--vw-unit));
  left: calc(800 * var(--vw-unit));
  border: calc(2 * var(--vw-unit)) solid rgba(5, 66, 106, 1);
  border-radius: calc(100 * var(--vw-unit));
  padding: calc(12 * var(--vw-unit)), calc(40 * var(--vw-unit));
  background-color: rgba(110, 240, 219, 0.5);
  color: rgba(5, 66, 106, 1);
  cursor: pointer;
  font-size: calc(18 * var(--vw-unit));
  font-style: italic;
  font-weight: 600;
  background-image: linear-gradient(
    45deg,
    rgba(226, 255, 249, 1),
    rgb(149, 201, 223)
  );
  transition: all 0.4s ease-in-out;
}

.get-started:hover {
  font-size: calc(20 * var(--vw-unit));
}

.solar-background {
  position: absolute;
  height: calc(350 * var(--vw-unit));
  top: calc(184 * var(--vw-unit));
  left: calc(936 * var(--vw-unit));
  z-index: 10;
  animation: rotater 50s linear infinite;
}

.notify1 {
  position: absolute;
  height: calc(85 * var(--vw-unit));
  width: calc(260 * var(--vw-unit));
  top: calc(178 * var(--vw-unit));
  left: calc(780 * var(--vw-unit));
  border-radius: calc(10 * var(--vw-unit));
  padding:
    calc(8 * var(--vw-unit)), calc(24 * var(--vw-unit)),
    calc(8 * var(--vw-unit)), calc(16 * var(--vw-unit));
  background-color: rgba(255, 255, 255, 1);
  box-shadow: calc(-8 * var(--vw-unit)) calc(8 * var(--vw-unit))
    calc(34 * var(--vw-unit)) 0 rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: calc(16 * var(--vw-unit));
  z-index: 20;
}

.bell-notify {
  height: calc(28 * var(--vw-unit));
  border-radius: calc(4 * var(--vw-unit));
}

.notify1-content {
  width: calc((18200 / 1440) * 1vw);
  font-weight: 600;
  font-size: calc(16 * var(--vw-unit));
  color: rgba(5, 66, 106, 1);
}

.notify2 {
  position: absolute;
  height: calc((12400 / 1440) * 1vw);
  width: calc((20100 / 1440) * 1vw);
  top: calc((21900 / 1440) * 1vw);
  left: calc((117000 / 1440) * 1vw);

  padding:
    calc(8 * var(--vw-unit)), calc(24 * var(--vw-unit)),
    calc(8 * var(--vw-unit)), calc(16 * var(--vw-unit));
  background: transparent;
  z-index: 20;
}

.commas {
  position: absolute;
  height: calc((4000 / 1440) * 1vw);
  margin-left: calc(15 * var(--vw-unit));
  z-index: 30;
}

.notify2-content {
  height: calc((9000 / 1440) * 1vw);
  width: calc((20100 / 1440) * 1vw);
  margin-top: calc(34 * var(--vw-unit));
  background-color: rgba(255, 255, 255, 1);
  border-radius: calc(10 * var(--vw-unit));
  box-shadow: calc(-8 * var(--vw-unit)) calc(8 * var(--vw-unit))
    calc(34 * var(--vw-unit)) 0 rgba(0, 0, 0, 0.08);
  padding: calc(8 * var(--vw-unit)) calc(24 * var(--vw-unit))
    calc(8 * var(--vw-unit)) calc(16 * var(--vw-unit));
}

.notify2-content p {
  font-size: calc(14 * var(--vw-unit));
  font-weight: 500;
  color: rgba(5, 66, 106, 1);
}

.notify2-content img {
  width: calc(82 * var(--vw-unit));
}

.bid-notify-home {
  position: absolute;
  height: calc((5400 / 1440) * 1vw);
  width: calc((43700 / 1440) * 1vw);
  top: calc((55100 / 1440) * 1vw);
  left: calc((59700 / 1440) * 1vw);
  border-radius: calc(10 * var(--vw-unit));
  gap: calc(24 * var(--vw-unit));
  display: flex;
  align-items: center;
  padding: calc(8 * var(--vw-unit)) calc(16 * var(--vw-unit))
    calc(8 * var(--vw-unit)) calc(24 * var(--vw-unit));
  background-color: rgba(255, 255, 255, 1);
  box-shadow: calc(-8 * var(--vw-unit)) calc(8 * var(--vw-unit))
    calc(36 * var(--vw-unit)) 0 rgba(0, 0, 0, 0.08);
  z-index: 20;
  visibility: hidden;
}

.bid-notify-home img {
  height: calc((2800 / 1440) * 1vw);
  border-radius: calc(4 * var(--vw-unit));
}

.bid-notify-home p {
  height: calc((2000 / 1440) * 1vw);
  width: calc((22200 / 1440) * 1vw);
  padding: 0;
  font-weight: 700;
  font-size: calc(14 * var(--vw-unit));
  color: rgba(5, 66, 106, 1);
}

.accept-home {
  height: calc((2800 / 1440) * 1vw);
  width: calc((6300 / 1440) * 1vw);
  border-radius: calc(4 * var(--vw-unit));
  font-weight: 500;
  font-size: calc(12 * var(--vw-unit));
  border: none;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(5, 106, 76, 1);
  cursor: default !important;
}

.reject-home {
  height: calc((2800 / 1440) * 1vw);
  width: calc((6300 / 1440) * 1vw);
  border-radius: calc(4 * var(--vw-unit));
  font-weight: 500;
  font-size: calc(12 * var(--vw-unit));
  border: none;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(193, 20, 20, 1);
  cursor: default !important;
}

.inactive-notify {
  position: absolute;
  top: calc(420 * var(--vw-unit));
  left: calc(720 * var(--vw-unit));
  height: calc(80 * var(--vw-unit));
  width: calc(360 * var(--vw-unit));
  display: flex;
  align-items: center;
  border-radius: calc(10 * var(--vw-unit));
  padding: calc(8 * var(--vw-unit)) calc(16 * var(--vw-unit))
    calc(8 * var(--vw-unit)) calc(24 * var(--vw-unit));
  background-color: rgba(255, 255, 255, 1);
  box-shadow: calc(-8 * var(--vw-unit)) calc(8 * var(--vw-unit))
    calc(36 * var(--vw-unit)) 0 rgba(0, 0, 0, 0.08);
  z-index: 30;
}

.inactive-notify span {
  width: calc((30000 / 1440) * 1vw);
  font-weight: 600;
  font-size: calc(16 * var(--vw-unit));
  color: rgba(5, 66, 106, 1);
  display: inline-block;
}

.active-notify {
  position: absolute;
  top: calc(420 * var(--vw-unit));
  left: calc(720 * var(--vw-unit));
  height: calc(80 * var(--vw-unit));
  width: calc(360 * var(--vw-unit));
  display: flex;
  align-items: center;
  border-radius: calc(10 * var(--vw-unit));
  padding: calc(8 * var(--vw-unit)) calc(16 * var(--vw-unit))
    calc(8 * var(--vw-unit)) calc(24 * var(--vw-unit));
  background-color: rgba(255, 255, 255, 1);
  box-shadow: calc(-8 * var(--vw-unit)) calc(8 * var(--vw-unit))
    calc(36 * var(--vw-unit)) 0 rgba(0, 0, 0, 0.08);
  z-index: 30;
}

.active-notify span {
  width: calc((30000 / 1440) * 1vw);
  font-weight: 600;
  font-size: calc(16 * var(--vw-unit));
  color: rgba(5, 66, 106, 1);
  display: inline-block;
}

.toggle-info-container {
  overflow: hidden;
  animation: revealletters 1s steps(100, end);
}

.toggle-btn-home-active {
  position: absolute;
  top: calc(340 * var(--vw-unit));
  left: calc(1040 * var(--vw-unit));
  height: calc(62 * var(--vw-unit));
  width: calc(148 * var(--vw-unit));
  background-color: rgba(5, 66, 106, 1);
  border-radius: calc(50 * var(--vw-unit));
  display: flex;
  align-items: center;
  z-index: 30;
  cursor: pointer;
  transition: background-color 0.8s ease;
}

.toggle-btn-home-inactive {
  position: absolute;
  top: calc(340 * var(--vw-unit));
  left: calc(1040 * var(--vw-unit));
  height: calc(62 * var(--vw-unit));
  width: calc(148 * var(--vw-unit));
  background: transparent;
  border: calc(1 * var(--vw-unit)) solid rgba(5, 106, 76, 1);
  border-radius: calc(50 * var(--vw-unit));
  display: flex;
  align-items: center;
  z-index: 30;
  cursor: pointer;
  transition: background-color 0.8s ease;
}

.toggle-btn-home-inactive span {
  color: rgba(5, 106, 76, 1);
  font-style: italic;
  font-weight: 600;
  font-size: calc(18 * var(--vw-unit));
  padding-left: calc(66 * var(--vw-unit));
}

.toggle-btn-home-active span {
  color: white;
  font-style: italic;
  font-weight: 600;
  font-size: calc(18 * var(--vw-unit));
  padding-left: calc(10 * var(--vw-unit));
}

.toggle-bar-home-active {
  position: absolute;
  right: calc(-1 * var(--vw-unit));
  height: calc(65 * var(--vw-unit));
  width: calc(65 * var(--vw-unit));
  background-color: rgba(110, 240, 219, 1);
  border-radius: 50%;
  z-index: 40;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
}

.toggle-bar-home-inactive {
  position: absolute;
  height: calc(65 * var(--vw-unit));
  width: calc(65 * var(--vw-unit));
  background-color: rgba(226, 255, 249, 1);
  border: calc(1 * var(--vw-unit)) solid rgba(5, 106, 76, 1);
  border-radius: 50%;
  z-index: 40;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
}

.girl-home {
  position: absolute;
  top: calc((37000 / 1440) * 1vw);
  left: calc((119000 / 1440) * 1vw);
  height: calc(240 * var(--vw-unit));
  z-index: 40;
}

.lorem-details {
  position: absolute;
  height: calc((22000 / 1440) * 1vw);
  width: calc((124000 / 1440) * 1vw);
  top: calc((70200 / 1440) * 1vw);
  left: calc((10000 / 1440) * 1vw);
  border-radius: calc(10 * var(--vw-unit));
  background-color: rgba(255, 255, 255, 1);
  box-shadow: calc(4 * var(--vw-unit)) calc(4 * var(--vw-unit))
    calc(40 * var(--vw-unit)) 0 rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: calc(10 * var(--vw-unit));
}

.lorem-options {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: calc(200 * var(--vw-unit));
  padding-bottom: calc(10 * var(--vw-unit));
}

.lorem-btn {
  height: calc(40 * var(--vw-unit));
  width: calc(200 * var(--vw-unit));
  border: calc(1 * var(--vw-unit)) solid rgba(5, 66, 106, 1);
  background-color: white;
  border-radius: calc(4 * var(--vw-unit));
  color: rgba(5, 66, 106, 1);
  font-size: calc(16 * var(--vw-unit));
  font-weight: 600;
  transition: all 0.3s ease-in-out;
}

.lorem-btn-active {
  height: calc(40 * var(--vw-unit));
  width: calc(200 * var(--vw-unit));
  border: none;
  background-color: rgba(5, 66, 106, 1);
  border-radius: calc(4 * var(--vw-unit));
  color: white;
  font-size: calc(16 * var(--vw-unit));
  font-weight: 600;
  transition: all 0.3s ease-in-out;
}

.lorem-btn:hover,
.lorem-btn-active:hover {
  height: calc(41 * var(--vw-unit));
  width: calc(202 * var(--vw-unit));
  border: calc(1 * var(--vw-unit)) solid rgba(110, 240, 219, 1);
  font-size: calc(17 * var(--vw-unit));
}

.lorem-content-holder {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: calc(15 * var(--vw-unit));
}

.lorem-content {
  height: calc((10700 / 1440) * 1vw);
  width: calc((25900 / 1440) * 1vw);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lorem-content-heading {
  text-align: center;
  font-weight: 700;
  font-size: calc(18 * var(--vw-unit));
  color: rgba(79, 79, 79, 1);
}

.lorem-content-info {
  font-weight: 400;
  font-size: calc(12 * var(--vw-unit));
  margin-top: calc(10 * var(--vw-unit));
  text-align: center;
  color: rgba(144, 144, 144, 1);
}

.deals-notify-head {
  position: absolute;
  height: calc((10000 / 1440) * 1vw);
  width: calc((64000 / 1440) * 1vw);
  top: calc((118000 / 1440) * 1vw);
  left: calc((10000 / 1440) * 1vw);
  line-height: calc(50 * var(--vw-unit));
  font-size: calc(36 * var(--vw-unit));
  font-weight: 700;
  color: rgba(7, 51, 108, 1);
}

.deals-notify-head span {
  background: linear-gradient(
    to right,
    rgba(7, 51, 108, 1),
    rgba(41, 205, 178, 1)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.deals-notify-info {
  position: absolute;
  height: calc((4800 / 1440) * 1vw);
  width: calc((58000 / 1440) * 1vw);
  top: calc((131800 / 1440) * 1vw);
  left: calc((10000 / 1440) * 1vw);
  font-weight: 400;
  font-size: calc(16 * var(--vw-unit));
  color: rgba(79, 79, 79, 1);
}

.machine-home {
  position: absolute;
  top: calc((106500 / 1440) * 1vw);
  left: calc((68000 / 1440) * 1vw);
  height: calc(401 * var(--vw-unit));
}

.point1 {
  position: absolute;
  top: calc((153800 / 1440) * 1vw);
  left: calc((10000 / 1440) * 1vw);
  border-radius: calc(6 * var(--vw-unit));
  display: flex;
  align-items: center;
  gap: calc(10 * var(--vw-unit));
}

.point2 {
  position: absolute;
  top: calc((153800 / 1440) * 1vw);
  left: calc((77000 / 1440) * 1vw);
  border-radius: calc(6 * var(--vw-unit));
  display: flex;
  align-items: center;
  gap: calc(10 * var(--vw-unit));
}

.point3 {
  position: absolute;
  top: calc((161600 / 1440) * 1vw);
  left: calc((10000 / 1440) * 1vw);
  border-radius: calc(6 * var(--vw-unit));
  display: flex;
  align-items: center;
  gap: calc(10 * var(--vw-unit));
}

.point4 {
  position: absolute;
  top: calc((161600 / 1440) * 1vw);
  left: calc((77000 / 1440) * 1vw);
  border-radius: calc(6 * var(--vw-unit));
  display: flex;
  align-items: center;
  gap: calc(10 * var(--vw-unit));
}

.point1 img,
.point2 img,
.point3 img,
.point4 img {
  height: calc(50 * var(--vw-unit));
  width: calc(50 * var(--vw-unit));
}

.points-info {
  font-weight: 700;
  font-size: calc(22 * var(--vw-unit));
  color: rgba(46, 46, 46, 1);
}

.comparison-block {
  position: absolute;
  height: calc((73000 / 1440) * 1vw);
  width: calc((124000 / 1440) * 1vw);
  top: calc((177600 / 1440) * 1vw);
  left: calc((10000 / 1440) * 1vw);
  border-radius: calc(20 * var(--vw-unit));
  background-image: linear-gradient(
    to bottom,
    rgba(7, 51, 108, 1),
    rgba(41, 205, 178, 1)
  );
  display: flex;
  flex-direction: column;
  align-items: center;
}

.comparison-head {
  height: calc((10000 / 1440) * 1vw);
  width: calc((73000 / 1440) * 1vw);
  margin-top: calc((5600 / 1440) * 1vw);
  font-weight: 700;
  font-size: calc(42 * var(--vw-unit));
  line-height: calc(50.4 * var(--vw-unit));
  text-align: center;
  color: rgba(255, 255, 255, 1);
}

.comparison-info {
  height: calc((4200 / 1440) * 1vw);
  width: calc((52300 / 1440) * 1vw);
  margin-top: calc(20 * var(--vw-unit));
  font-weight: 400;
  font-size: calc(14 * var(--vw-unit));
  line-height: calc(21 * var(--vw-unit));
  text-align: center;
  color: rgba(255, 255, 255, 1);
}

.comparison-table {
  border-radius: calc(10 * var(--vw-unit));
  margin-top: calc(80 * var(--vw-unit));
  overflow: hidden;
  border-collapse: separate;
  border-spacing: calc(6 * var(--vw-unit));
}
.comparison-table thead {
  color: #fff;
  background-color: rgba(5, 66, 106, 1);
  border-top-left-radius: calc(5 * var(--vw-unit));
  border-top-right-radius: calc(5 * var(--vw-unit));
  overflow: hidden;
}
.comparison-table th {
  padding: calc(8 * var(--vw-unit)) calc(25 * var(--vw-unit));
  font-size: calc(16 * var(--vw-unit));
  font-weight: 500;
}
.comparison-table td {
  color: rgba(5, 66, 106, 1);
  padding: calc(5 * var(--vw-unit)) calc(25 * var(--vw-unit));
  font-size: calc(16 * var(--vw-unit));
  font-weight: 500;
}
.comparison-table tbody {
  background-color: rgba(228, 251, 251, 1);
}
.comparison-table .td-image {
  text-align: center;
  padding: calc(2 * var(--vw-unit)) 0;
}
.comparison-table .td-image img {
  height: calc(25 * var(--vw-unit));
}

.needs-home {
  position: absolute;
  top: calc((253600 / 1440) * 1vw);
  left: calc((10000 / 1440) * 1vw);
  height: calc((12500 / 1440) * 1vw);
  width: calc((64000 / 1440) * 1vw);
}

.needs-head {
  height: calc((5000 / 1440) * 1vw);
  width: calc((114000 / 1440) * 1vw);
  font-size: calc(42 * var(--vw-unit));
  font-weight: 700;
  color: rgba(5, 66, 106, 1);
}

.needs-head span {
  background: linear-gradient(
    to right,
    rgba(7, 51, 108, 1),
    rgba(41, 205, 178, 1)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.needs-info {
  color: rgba(79, 79, 79, 1);
  margin-top: calc(24 * var(--vw-unit));
  font-size: calc(16 * var(--vw-unit));
}

.types-container-home {
  position: absolute;
  top: calc((270600 / 1440) * 1vw);
  left: calc((10000 / 1440) * 1vw);
  height: calc((53400 / 1440) * 1vw);
  width: calc((124200 / 1440) * 1vw);
  display: flex;
  flex-wrap: wrap;
}

.type-item {
  flex: 1 0 21%;
  margin: calc(10 * var(--vw-unit));
  padding: calc(20 * var(--vw-unit));
  height: calc((24000 / 1440) * 1vw);
  border: calc(2 * var(--vw-unit)) solid rgba(110, 240, 219, 1);
  border-radius: calc(10 * var(--vw-unit));
}

.type-item-heading {
  font-size: calc(22 * var(--vw-unit));
  font-weight: 700;
  color: rgba(5, 106, 76, 1);
}

.type-item-info {
  font-size: calc(10 * var(--vw-unit));
  font-weight: 400;
  color: black;
  max-height: calc(115 * var(--vw-unit));
  line-height: calc(20 * var(--vw-unit));
  margin-top: calc(10 * var(--vw-unit));
  overflow: hidden;
}

.customer-say {
  position: absolute;
  top: calc((325000 / 1440) * 1vw);
  left: calc((10000 / 1440) * 1vw);
  height: calc((42400 / 1440) * 1vw);
  width: calc((120000 / 1440) * 1vw);
}

.customer-say-caption h2 {
  height: calc((10800 / 1440) * 1vw);
  width: calc((80000 / 1440) * 1vw);
  font-size: calc(40 * var(--vw-unit));
  font-weight: 700;
  line-height: calc(54 * var(--vw-unit));
  color: rgba(5, 66, 106, 1);
}

.customer-say-caption span {
  background: linear-gradient(
    to right,
    rgba(7, 51, 108, 1),
    rgba(41, 205, 178, 1)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.customer-reviews-container {
  display: flex;
  overflow: scroll;
  gap: calc(30 * var(--vw-unit));
  margin-top: calc(40 * var(--vw-unit));
  padding: calc(10 * var(--vw-unit)) calc(1 * var(--vw-unit));
}

.customer-review {
  height: calc((22200 / 1440) * 1vw);
  min-width: calc((48000 / 1440) * 1vw);
  border-radius: calc(8 * var(--vw-unit));
  padding: calc(20 * var(--vw-unit));
  background: linear-gradient(
    to bottom,
    rgba(226, 255, 249, 1),
    rgba(231, 245, 255, 1)
  );
  box-shadow: 0 calc(30 * var(--vw-unit)) calc(116 * var(--vw-unit))
    calc(-16 * var(--vw-unit)) rgba(211, 211, 211, 0.2);
}

.customer-review-text {
  font-size: calc(16 * var(--vw-unit));
  line-height: calc(30 * var(--vw-unit));
}

.customer-name-home {
  margin-top: calc(5 * var(--vw-unit));
  font-weight: 700;
  font-size: calc(22 * var(--vw-unit));
  color: rgba(5, 66, 106, 1);
  align-self: center;
}

.join-us-home {
  position: absolute;
  top: calc((370000 / 1440) * 1vw);
  left: calc((10000 / 1440) * 1vw);
  height: calc((17400 / 1440) * 1vw);
  width: calc((124000 / 1440) * 1vw);
  display: inline-flex;
  padding: calc(42 * var(--vw-unit)) calc(68 * var(--vw-unit))
    calc(42 * var(--vw-unit)) calc(57 * var(--vw-unit));
  align-items: center;
  gap: calc((31000 / 1440) * 1vw);
  border-radius: calc(10 * var(--vw-unit));
  background: linear-gradient(
    270deg,
    rgba(41, 205, 178, 1),
    rgba(5, 66, 106, 1)
  );
}

.join-us-home h2 {
  width: calc((56000 / 1440) * 1vw);
  color: #fff;
  font-size: calc(30 * var(--vw-unit));
  font-style: normal;
  font-weight: 700;
  line-height: calc(45 * var(--vw-unit));
  cursor: pointer;
}

.join-us-home button {
  display: flex;
  padding: calc(12 * var(--vw-unit)) calc(40 * var(--vw-unit));
  justify-content: center;
  align-items: center;
  gap: calc(10 * var(--vw-unit));
  border: none;
  border-radius: calc(100 * var(--vw-unit));
  background: #fff;
  color: rgba(56, 104, 116, 1);
  color: #386874;
  font-size: calc(18 * var(--vw-unit));
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

footer {
  position: absolute;
  top: calc((400400 / 1440) * 1vw);
  width: 100%;
  height: calc((7000 / 1440) * 1vw);
  background-color: rgba(5, 66, 106, 1);
  display: flex;
  align-items: center;
  color: #fff;
}
.aboutus-footer {
  font-size: calc(14 * var(--vw-unit));
  margin-right: calc(15 * var(--vw-unit));
  text-decoration: underline;
  cursor: pointer;
}

.copyright {
  margin-left: calc((10000 / 1440) * 1vw);
  font-size: calc(14 * var(--vw-unit));
}

.liner-footer {
  margin-left: calc(12 * var(--vw-unit));
  margin-right: calc(12 * var(--vw-unit));
  font-size: calc(40 * var(--vw-unit));
  font-weight: 100;
}

.footer-icons-holder {
  display: inline-flex;
  gap: calc(5 * var(--vw-unit));
  margin-right: calc((48000 / 1440) * 1vw);
}

.footer-icons-holder img {
  height: calc(30 * var(--vw-unit));
  cursor: pointer;
}

.blogs {
  text-decoration: underline;
  font-size: calc(14 * var(--vw-unit));
  cursor: pointer;
  margin-right: calc(34 * var(--vw-unit));
}

.terms {
  text-decoration: underline;
  font-size: calc(14 * var(--vw-unit));
  cursor: pointer;
}

.home-tutorial-holder {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
}

.home-tutorial-btnholder {
  height: calc(80 * var(--vw-unit));
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
}

.tutorial-home-btn {
  height: calc(37 * var(--vw-tab));
  width: calc(100 * var(--vw-tab));
  border: none;
  border-radius: calc(4 * var(--vw-tab));
  color: rgba(5, 66, 106, 1);
  background-color: white;
  font-size: calc(15 * var(--vw-tab));
  font-weight: 600;
}

.tutorial-home-btn-active {
  height: calc(37 * var(--vw-tab));
  width: calc(100 * var(--vw-tab));
  border: none;
  border-radius: calc(4 * var(--vw-tab));
  background-color: white;
  color: rgba(41, 205, 178, 1);
  font-size: calc(16 * var(--vw-tab));
  font-weight: 600;
}

.home-tutorial-btnholder img {
  position: absolute;
  right: calc(30 * var(--vw-unit));
  height: calc(50 * var(--vw-unit));
}

.home-video-holder {
  height: calc(100% - (80 * var(--vw-unit)));
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
}

.home-video-holder video {
  max-height: 96%;
  max-width: 98%;
}

@keyframes revealletters {
  0% {
    width: 0;
    height: 40%;
  }

  20% {
    width: 20%;
    height: 40%;
  }

  40% {
    width: 40%;
    height: 40%;
  }

  60% {
    width: 60%;
    height: 40%;
  }

  80% {
    width: 80%;
    height: 40%;
  }

  100% {
    width: 100%;
  }
}

@keyframes rotater {
  0% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(36deg);
  }

  20% {
    transform: rotate(72deg);
  }

  30% {
    transform: rotate(108deg);
  }

  40% {
    transform: rotate(144deg);
  }

  50% {
    transform: rotate(180deg);
  }

  60% {
    transform: rotate(216deg);
  }

  70% {
    transform: rotate(252deg);
  }

  80% {
    transform: rotate(288deg);
  }

  90% {
    transform: rotate(324deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (min-width: 550px) and (max-width: 1000px) {
  .homepage {
    height: calc(5092 * var(--vw-htab));
    width: 100vw;
    overflow: hidden;
  }

  .page1 {
    height: calc(1029 * var(--vw-htab));
    width: 100%;
    background-image: linear-gradient(
      45deg,
      rgba(226, 255, 249, 1),
      rgba(231, 245, 255, 1)
    );
  }

  .navbar {
    height: calc(100 * var(--vw-htab));
    width: 100%;
    background: transparent;
    display: flex;
    align-items: center;
  }

  .function-buttons-home {
    visibility: hidden;
  }

  .logo {
    position: absolute;
    top: calc(35 * var(--vw-htab));
    left: calc(32 * var(--vw-htab));
    width: calc(276 * var(--vw-htab));
  }

  .menu-icon-home {
    position: absolute;
    top: calc(30 * var(--vw-htab));
    right: calc(32 * var(--vw-htab));
    height: calc(42 * var(--vw-htab));
  }

  .menu-options-home {
    position: absolute;
    height: auto;
    padding: calc(32 * var(--vw-htab)) 0;
    border-radius: calc(32 * var(--vw-htab));
    box-shadow: calc(-8 * var(--vw-unit)) calc(8 * var(--vw-unit))
      calc(34 * var(--vw-unit)) 0 rgba(0, 0, 0, 0.08);
    background-color: rgba(110, 240, 219, 1);
    max-height: 100vh;
    width: 50%;
    top: calc(75 * var(--vw-htab));
    right: 1%;
    transform: translateX(110%);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: calc(12 * var(--vw-htab));
    overflow: hidden;
    z-index: 30;

    transition: transform 0.3s ease-in-out;
  }

  .menu-options-home.open {
    transform: translateX(0);
  }

  .login-m,
  .signup-m {
    height: calc(46 * var(--vw-htab));
    width: 80%;
    border: calc(1 * var(--vw-htab)) solid rgba(7, 51, 108, 1);
    border-radius: calc(100 * var(--vw-htab));
    background-color: rgba(5, 66, 106, 1);
    color: white;
    padding: calc(12 * var(--vw-htab)), calc(40 * var(--vw-htab));
    cursor: pointer;
    font-size: calc(18 * var(--vw-htab));
    font-weight: 600;
  }

  .aboutus-btn-home-m,
  .calculator-tool-btn-m {
    height: calc(46 * var(--vw-htab));
    font-weight: 700;
    border: none;
    border-radius: calc(100 * var(--vw-htab));
    background: transparent;
    color: rgba(7, 51, 108, 1);
    padding: calc(12 * var(--vw-htab)), calc(40 * var(--vw-htab));
    cursor: pointer;
    font-size: calc(16 * var(--vw-htab));
    display: flex;
    align-items: center;
    gap: calc(8 * var(--vw-htab));
  }

  .aboutus-btn-home-m img,
  .calculator-tool-btn-m img {
    height: 60%;
  }

  .tutorial-btn-home-m {
    height: calc(46 * var(--vw-htab));
    border: none;
    border-radius: calc(100 * var(--vw-htab));
    background: transparent;
    color: rgba(7, 51, 108, 1);
    font-weight: 700;
    padding: calc(12 * var(--vw-htab)), calc(40 * var(--vw-htab));
    cursor: pointer;
    font-size: calc(16 * var(--vw-htab));
    display: flex;
    align-items: center;
    gap: calc(8 * var(--vw-htab));
  }

  .tutorial-btn-home-m img {
    height: 60%;
  }

  .creditcard-home {
    position: absolute;
    top: calc(135 * var(--vw-htab));
    left: 0;
    width: 100%;
    text-align: center;
    font-size: calc(19 * var(--vw-htab));
  }

  .select-role-home {
    position: absolute;
    top: calc(330 * var(--vw-htab));
    left: calc(0 * var(--vw-htab));
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: calc(20 * var(--vw-htab));
  }

  .role-btn,
  .role-btn-active {
    height: calc(50 * var(--vw-htab));
    width: auto;
    font-size: calc(18 * var(--vw-htab));
    font-weight: 600;
    padding: 0 calc(30 * var(--vw-htab));
  }

  .need-insurance-box,
  .provide-insurance-box {
    position: absolute;
    top: calc(470 * var(--vw-htab));
    left: calc(100 * var(--vw-htab));
    min-height: calc(400 * var(--vw-htab));
    height: calc(440 * var(--vw-htab));
    width: calc(100% - (200 * var(--vw-htab)));
    border: none;
    border-radius: calc(15 * var(--vw-htab));
    background-color: white;
    padding: calc(25 * var(--vw-htab));
    box-shadow: calc(-8 * var(--vw-htab)) calc(8 * var(--vw-htab))
      calc(34 * var(--vw-htab)) 0 rgba(0, 0, 0, 0.08);
    transition: all 0.3s ease-in-out;
    background-color: white;
    display: flex;
    flex-direction: column;
  }

  .need-insurance-box:hover {
    transform: scale(1.05);
  }

  .need-insurance-heading {
    text-align: center;
    font-size: calc(28 * var(--vw-htab));
    font-weight: 600;
    color: rgba(5, 66, 106, 1);
    margin-bottom: calc(10 * var(--vw-htab));
  }

  .need-insurance-sidehead {
    text-align: center;
    font-size: calc(22 * var(--vw-htab));
    font-weight: 600;
    color: rgba(5, 106, 76, 1);
    margin-bottom: calc(40 * var(--vw-htab));
  }

  .need-insurance-box span {
    font-size: calc(20 * var(--vw-htab));
  }

  .need-insurance-info {
    height: 30%;
  }

  .need-insurance-info h3 {
    height: 50%;
    font-size: calc(14 * var(--vw-htab));
  }

  .btn-holder-home {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .select-wrapper-home {
    height: 40%;
    width: calc(250 * var(--vw-tab));
    border: none;
    border-radius: calc(30 * var(--vw-htab));
    background-color: rgba(5, 66, 106, 1);
    overflow: hidden;
  }

  .btn-holder-home select {
    height: 100%;
    width: 90%;
    border: none;
    background-color: rgba(5, 66, 106, 1);
    color: white;
    font-size: calc(20 * var(--vw-htab));
    font-weight: 600;
    padding: 0;
  }

  .need-insurance-box-content {
    height: 70%;
    display: flex;
    flex-direction: column;
  }

  .provide-insurance-heading {
    text-align: center;
    font-size: calc(26 * var(--vw-htab));
    font-weight: 600;
    color: rgba(5, 66, 106, 1);
    margin-bottom: calc(0 * var(--vw-htab));
  }

  .provide-insurance-box span {
    font-size: calc(20 * var(--vw-htab));
  }

  .provide-insurance-box button {
    height: calc(65 * var(--vw-htab));
    width: calc(250 * var(--vw-htab));
    margin-top: calc(10 * var(--vw-htab));
    margin-bottom: calc(25 * var(--vw-htab));
    border: none;
    border-radius: calc(30 * var(--vw-htab));
    background-color: rgba(5, 66, 106, 1);
    color: white;
    font-size: calc(20 * var(--vw-htab));
    font-weight: 600;
  }

  .quote-head {
    position: absolute;
    top: calc(162 * var(--vw-htab));
    left: calc(0 * var(--vw-htab));
    width: 100%;
    text-align: center;
    font-size: calc(40 * var(--vw-htab));
    font-weight: 700;
    color: rgba(5, 66, 106, 1);
  }

  .quote-info {
    position: absolute;
    top: calc(273 * var(--vw-htab));
    left: calc(114 * var(--vw-htab));
    width: calc(606 * var(--vw-htab));
    font-size: calc(16 * var(--vw-htab));
    font-weight: 400;
    color: rgba(79, 79, 79, 1);
  }

  .role-background {
    position: absolute;
    top: calc(750 * var(--vw-htab));
  }

  .get-started {
    position: absolute;
    height: calc(48 * var(--vw-htab));
    width: calc(200 * var(--vw-htab));
    top: calc(301 * var(--vw-htab));
    left: calc(317 * var(--vw-htab));
    border-radius: calc(100 * var(--vw-htab));
    cursor: pointer;
    font-size: calc(16 * var(--vw-htab));
    visibility: hidden;
  }

  .solar-background {
    position: absolute;
    height: calc(540 * var(--vw-htab));
    top: calc(405 * var(--vw-htab));
    left: calc(146 * var(--vw-htab));
    z-index: 10;
    animation: rotater 50s linear infinite;
    visibility: hidden;
  }

  .notify1 {
    position: absolute;
    height: calc(85 * var(--vw-htab));
    width: calc(260 * var(--vw-htab));
    top: calc(455 * var(--vw-htab));
    left: calc(68 * var(--vw-htab));
    border-radius: calc(10 * var(--vw-htab));
    padding:
      calc(8 * var(--vw-htab)), calc(24 * var(--vw-htab)),
      calc(8 * var(--vw-htab)), calc(16 * var(--vw-htab));
    background-color: rgba(255, 255, 255, 1);
    box-shadow: calc(-8 * var(--vw-htab)) calc(8 * var(--vw-htab))
      calc(34 * var(--vw-htab)) 0 rgba(0, 0, 0, 0.08);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: calc(16 * var(--vw-htab));
    z-index: 20;
    visibility: hidden;
  }

  .bell-notify {
    height: calc(28 * var(--vw-htab));
    border-radius: calc(4 * var(--vw-htab));
  }

  .notify1-content {
    width: calc(182 * var(--vw-htab));
    font-weight: 600;
    font-size: calc(16 * var(--vw-htab));
    color: rgba(5, 66, 106, 1);
  }

  .notify2 {
    position: absolute;
    height: calc(144 * var(--vw-htab));
    width: calc(201 * var(--vw-htab));
    top: calc(571 * var(--vw-htab));
    left: calc(586 * var(--vw-htab));
    background: transparent;
    z-index: 20;
    visibility: hidden;
  }

  .commas {
    position: absolute;
    height: calc(40 * var(--vw-htab));
    margin-left: calc(15 * var(--vw-htab));
    z-index: 30;
    visibility: hidden;
  }

  .notify2-content {
    height: calc(90 * var(--vw-htab));
    width: calc(201 * var(--vw-htab));
    margin-top: calc(34 * var(--vw-htab));
    background-color: rgba(255, 255, 255, 1);
    border-radius: calc(10 * var(--vw-htab));
    box-shadow: calc(-8 * var(--vw-htab)) calc(8 * var(--vw-htab))
      calc(34 * var(--vw-htab)) 0 rgba(0, 0, 0, 0.08);
    padding: calc(8 * var(--vw-htab)) calc(24 * var(--vw-htab))
      calc(8 * var(--vw-htab)) calc(16 * var(--vw-htab));
  }

  .notify2-content p {
    font-size: calc(14 * var(--vw-htab));
    font-weight: 500;
    color: rgba(5, 66, 106, 1);
  }

  .notify2-content img {
    width: calc(82 * var(--vw-htab));
  }

  .bid-notify-home {
    position: absolute;
    height: calc(54 * var(--vw-htab));
    width: calc(437 * var(--vw-htab));
    top: calc(892 * var(--vw-htab));
    left: calc(59 * var(--vw-htab));
    border-radius: calc(10 * var(--vw-htab));
    gap: calc(24 * var(--vw-htab));
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: calc(-8 * var(--vw-htab)) calc(8 * var(--vw-htab))
      calc(36 * var(--vw-htab)) 0 rgba(0, 0, 0, 0.08);
    z-index: 20;
    visibility: hidden;
  }

  .bid-notify-home img {
    height: calc(19 * var(--vw-htab));
    border-radius: calc(4 * var(--vw-htab));
  }

  .bid-notify-home p {
    width: calc(191 * var(--vw-htab));
    padding: 0;
    font-weight: 700;
    font-size: calc(14 * var(--vw-htab));
    color: rgba(5, 66, 106, 1);
  }

  .accept-home {
    height: calc(28 * var(--vw-htab));
    width: calc(63 * var(--vw-htab));
    border-radius: calc(4 * var(--vw-htab));
    font-weight: 500;
    font-size: calc(12 * var(--vw-htab));
    border: none;
    color: rgba(255, 255, 255, 1);
    background-color: rgba(5, 106, 76, 1);
    cursor: default !important;
  }

  .reject-home {
    height: calc(28 * var(--vw-htab));
    width: calc(63 * var(--vw-htab));
    border-radius: calc(4 * var(--vw-htab));
    font-weight: 500;
    font-size: calc(12 * var(--vw-htab));
    border: none;
    color: rgba(255, 255, 255, 1);
    background-color: rgba(193, 20, 20, 1);
    cursor: default !important;
  }

  .inactive-notify {
    position: absolute;
    top: calc(710 * var(--vw-htab));
    left: calc(60 * var(--vw-htab));
    height: calc(80 * var(--vw-htab));
    width: calc(360 * var(--vw-htab));
    display: flex;
    align-items: center;
    border-radius: calc(10 * var(--vw-htab));
    padding: calc(8 * var(--vw-htab)) calc(16 * var(--vw-htab))
      calc(8 * var(--vw-htab)) calc(24 * var(--vw-htab));
    background-color: rgba(255, 255, 255, 1);
    box-shadow: calc(-8 * var(--vw-htab)) calc(8 * var(--vw-htab))
      calc(36 * var(--vw-htab)) 0 rgba(0, 0, 0, 0.08);
    z-index: 30;
    visibility: hidden;
  }

  .inactive-notify span {
    width: calc(300 * var(--vw-htab));
    font-weight: 600;
    font-size: calc(16 * var(--vw-htab));
    color: rgba(5, 66, 106, 1);
    display: inline-block;
  }

  .active-notify {
    position: absolute;
    top: calc(710 * var(--vw-htab));
    left: calc(60 * var(--vw-htab));
    height: calc(80 * var(--vw-htab));
    width: calc(360 * var(--vw-htab));
    display: flex;
    align-items: center;
    border-radius: calc(10 * var(--vw-htab));
    padding: calc(8 * var(--vw-htab)) calc(16 * var(--vw-htab))
      calc(8 * var(--vw-htab)) calc(24 * var(--vw-htab));
    background-color: rgba(255, 255, 255, 1);
    box-shadow: calc(-8 * var(--vw-htab)) calc(8 * var(--vw-htab))
      calc(36 * var(--vw-htab)) 0 rgba(0, 0, 0, 0.08);
    z-index: 30;
    visibility: hidden;
  }

  .active-notify span {
    width: calc(300 * var(--vw-htab));
    font-weight: 600;
    font-size: calc(16 * var(--vw-htab));
    color: rgba(5, 66, 106, 1);
    display: inline-block;
  }

  .toggle-info-container {
    overflow: hidden;
    animation: revealletters 1s steps(100, end);
    visibility: hidden;
  }

  .toggle-btn-home-active {
    position: absolute;
    top: calc(625 * var(--vw-htab));
    left: calc(320 * var(--vw-htab));
    height: calc(62 * var(--vw-htab));
    width: calc(148 * var(--vw-htab));
    background-color: rgba(5, 66, 106, 1);
    border-radius: calc(50 * var(--vw-htab));
    display: flex;
    align-items: center;
    z-index: 30;
    cursor: pointer;
    transition: background-color 0.8s ease;
    visibility: hidden;
  }

  .toggle-btn-home-inactive {
    position: absolute;
    top: calc(625 * var(--vw-htab));
    left: calc(320 * var(--vw-htab));
    height: calc(62 * var(--vw-htab));
    width: calc(148 * var(--vw-htab));
    background: transparent;
    border: calc(1 * var(--vw-htab)) solid rgba(5, 106, 76, 1);
    border-radius: calc(50 * var(--vw-htab));
    display: flex;
    align-items: center;
    z-index: 30;
    cursor: pointer;
    transition: background-color 0.8s ease;
    visibility: hidden;
  }

  .toggle-btn-home-inactive span {
    color: rgba(5, 106, 76, 1);
    font-style: italic;
    font-weight: 600;
    font-size: calc(18 * var(--vw-htab));
    padding-left: calc(66 * var(--vw-htab));
  }

  .toggle-btn-home-active span {
    color: white;
    font-style: italic;
    font-weight: 600;
    font-size: calc(18 * var(--vw-htab));
    padding-left: calc(10 * var(--vw-htab));
  }

  .toggle-bar-home-active {
    position: absolute;
    right: calc(-1 * var(--vw-htab));
    height: calc(65 * var(--vw-htab));
    width: calc(65 * var(--vw-htab));
    background-color: rgba(110, 240, 219, 1);
    border-radius: 50%;
    z-index: 40;
    transition:
      background-color 0.3s ease,
      transform 0.3s ease;
  }

  .toggle-bar-home-inactive {
    position: absolute;
    height: calc(65 * var(--vw-htab));
    width: calc(65 * var(--vw-htab));
    background-color: rgba(226, 255, 249, 1);
    border: calc(0.3 * var(--vw-htab)) solid rgba(5, 106, 76, 1);
    border-radius: 50%;
    z-index: 40;
    transition:
      background-color 0.3s ease,
      transform 0.3s ease;
  }

  .girl-home {
    position: absolute;
    top: calc(690 * var(--vw-htab));
    left: calc(460 * var(--vw-htab));
    height: calc(247.6 * var(--vw-htab));
    z-index: 40;
    visibility: hidden;
  }

  .lorem-details {
    position: absolute;
    height: calc(250 * var(--vw-htab));
    width: calc(770 * var(--vw-htab));
    top: calc(1000 * var(--vw-htab));
    left: calc(32 * var(--vw-htab));
    border-radius: calc(10 * var(--vw-htab));
    background-color: rgba(255, 255, 255, 1);
    box-shadow: calc(4 * var(--vw-htab)) calc(4 * var(--vw-htab))
      calc(40 * var(--vw-htab)) 0 rgba(0, 0, 0, 0.08);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: calc(10 * var(--vw-htab));
  }

  .lorem-options {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: calc(200 * var(--vw-htab));
    padding-bottom: calc(5 * var(--vw-htab));
  }

  .lorem-btn {
    height: calc(32 * var(--vw-htab));
    width: calc(200 * var(--vw-htab));
    border: calc(1 * var(--vw-htab)) solid rgba(5, 66, 106, 1);
    background-color: white;
    border-radius: calc(6 * var(--vw-htab));
    color: rgba(5, 66, 106, 1);
    font-size: calc(16 * var(--vw-htab));
    font-weight: 600;
    transition: all 0.3s ease-in-out;
  }

  .lorem-btn-active {
    height: calc(32 * var(--vw-htab));
    width: calc(200 * var(--vw-htab));
    border: none;
    background-color: rgba(5, 66, 106, 1);
    border-radius: calc(6 * var(--vw-htab));
    color: white;
    font-size: calc(16 * var(--vw-htab));
    font-weight: 600;
    transition: all 0.3s ease-in-out;
  }

  .lorem-btn:hover,
  .lorem-btn-active:hover {
    height: calc(32 * var(--vw-htab));
    width: calc(200 * var(--vw-htab));
    border: calc(1 * var(--vw-htab)) solid rgba(110, 240, 219, 1);
    font-size: calc(17 * var(--vw-htab));
  }

  .lorem-content-holder {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: calc(15 * var(--vw-htab));
  }

  .lorem-content {
    height: calc(97 * var(--vw-htab));
    width: calc(227 * var(--vw-htab));
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .lorem-content-heading {
    font-weight: 700;
    font-size: calc(16 * var(--vw-htab));
    color: rgba(79, 79, 79, 1);
  }

  .lorem-content-info {
    font-weight: 400;
    font-size: calc(12 * var(--vw-htab));
    margin-top: calc(10 * var(--vw-htab));
    text-align: center;
    color: rgba(144, 144, 144, 1);
  }

  .deals-notify-head {
    position: absolute;
    height: calc(100 * var(--vw-htab));
    width: calc(597 * var(--vw-htab));
    top: calc(1349 * var(--vw-htab));
    left: calc(119 * var(--vw-htab));
    line-height: calc(50 * var(--vw-htab));
    font-size: calc(35 * var(--vw-htab));
    font-weight: 700;
    color: rgba(7, 51, 108, 1);
    text-align: center;
  }

  .deals-notify-head span {
    background: linear-gradient(
      to right,
      rgba(7, 51, 108, 1),
      rgba(41, 205, 178, 1)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .deals-notify-info {
    position: absolute;
    height: calc(48 * var(--vw-htab));
    width: calc(540 * var(--vw-htab));
    top: calc(1473 * var(--vw-htab));
    left: calc(147 * var(--vw-htab));
    font-weight: 400;
    font-size: calc(16 * var(--vw-htab));
    color: rgba(79, 79, 79, 1);
    text-align: center;
  }

  .machine-home {
    position: absolute;
    top: calc(1520 * var(--vw-htab));
    left: calc(-6 * var(--vw-htab));
    height: calc(445 * var(--vw-htab));
  }

  .point1 {
    position: absolute;
    top: calc(2038 * var(--vw-htab));
    left: calc(147 * var(--vw-htab));
    border-radius: calc(6 * var(--vw-htab));
    display: flex;
    align-items: center;
    gap: calc(10 * var(--vw-htab));
  }

  .point2 {
    position: absolute;
    top: calc(2120 * var(--vw-htab));
    left: calc(147 * var(--vw-htab));
    border-radius: calc(6 * var(--vw-htab));
    display: flex;
    align-items: center;
    gap: calc(10 * var(--vw-htab));
  }

  .point3 {
    position: absolute;
    top: calc(2202 * var(--vw-htab));
    left: calc(147 * var(--vw-htab));
    border-radius: calc(6 * var(--vw-htab));
    display: flex;
    align-items: center;
    gap: calc(10 * var(--vw-htab));
  }

  .point4 {
    position: absolute;
    top: calc(2284 * var(--vw-htab));
    left: calc(147 * var(--vw-htab));
    border-radius: calc(6 * var(--vw-htab));
    display: flex;
    align-items: center;
    gap: calc(10 * var(--vw-htab));
  }

  .point1 img,
  .point2 img,
  .point3 img,
  .point4 img {
    height: calc(50 * var(--vw-htab));
    width: calc(50 * var(--vw-htab));
  }

  .points-info {
    font-weight: 700;
    font-size: calc(22 * var(--vw-htab));
    color: rgba(46, 46, 46, 1);
  }

  .comparison-block {
    position: absolute;
    height: calc(650 * var(--vw-htab));
    width: calc(770 * var(--vw-htab));
    top: calc(2454 * var(--vw-htab));
    left: calc(32 * var(--vw-htab));
    border-radius: calc(20 * var(--vw-htab));
    background-image: linear-gradient(
      to bottom,
      rgba(7, 51, 108, 1),
      rgba(41, 205, 178, 1)
    );
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .comparison-head {
    height: calc(100 * var(--vw-htab));
    width: calc(730 * var(--vw-htab));
    margin-top: calc(56 * var(--vw-htab));
    font-weight: 700;
    font-size: calc(42 * var(--vw-htab));
    line-height: calc(50.4 * var(--vw-htab));
    text-align: center;
    color: rgba(255, 255, 255, 1);
  }

  .comparison-info {
    height: calc(42 * var(--vw-htab));
    width: calc(523 * var(--vw-htab));
    margin-top: calc(20 * var(--vw-htab));
    font-weight: 400;
    font-size: calc(14 * var(--vw-htab));
    line-height: calc(21 * var(--vw-htab));
    text-align: center;
    color: rgba(255, 255, 255, 1);
  }

  .comparison-table {
    border-radius: calc(10 * var(--vw-htab));
    margin-top: calc(80 * var(--vw-htab));
    overflow: hidden;
    border-collapse: separate;
    border-spacing: calc(6 * var(--vw-htab));
  }
  .comparison-table thead {
    color: #fff;
    background-color: rgba(5, 66, 106, 1);
    border-top-left-radius: calc(5 * var(--vw-htab));
    border-top-right-radius: calc(5 * var(--vw-htab));
    overflow: hidden;
  }
  .comparison-table th {
    padding: calc(8 * var(--vw-htab)) calc(25 * var(--vw-htab));
    font-size: calc(12 * var(--vw-htab));
    font-weight: 500;
  }
  .comparison-table td {
    color: rgba(5, 66, 106, 1);
    padding: calc(5 * var(--vw-htab)) calc(25 * var(--vw-htab));
    font-size: calc(12 * var(--vw-htab));
    font-weight: 500;
  }
  .comparison-table tbody {
    background-color: rgba(228, 251, 251, 1);
  }
  .comparison-table .td-image {
    text-align: center;
    padding: calc(2 * var(--vw-htab)) 0;
  }
  .comparison-table .td-image img {
    height: calc(25 * var(--vw-htab));
  }

  .needs-home {
    position: absolute;
    top: calc(3154 * var(--vw-htab));
    left: calc(97 * var(--vw-htab));
    height: calc(225 * var(--vw-htab));
    width: calc(640 * var(--vw-htab));
  }

  .needs-head {
    height: calc(100 * var(--vw-htab));
    width: calc(640 * var(--vw-htab));
    font-size: calc(42 * var(--vw-htab));
    font-weight: 700;
    color: rgba(5, 66, 106, 1);
    text-align: center;
  }

  .needs-head span {
    background: linear-gradient(
      to right,
      rgba(7, 51, 108, 1),
      rgba(41, 205, 178, 1)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .needs-info {
    color: rgba(79, 79, 79, 1);
    margin-top: calc(24 * var(--vw-htab));
    font-size: calc(16 * var(--vw-htab));
    text-align: center;
  }

  .types-container-home {
    position: absolute;
    top: calc(3386 * var(--vw-htab));
    left: calc(32 * var(--vw-htab));
    height: calc(752 * var(--vw-htab));
    width: calc(771 * var(--vw-htab));
    display: flex;
    flex-wrap: wrap;
  }

  .type-item {
    flex: 1 0 25%;
    margin: calc(10 * var(--vw-htab));
    padding: calc(20 * var(--vw-htab));
    height: calc(240 * var(--vw-htab));
    border: calc(2 * var(--vw-htab)) solid rgba(110, 240, 219, 1);
    border-radius: calc(10 * var(--vw-htab));
  }

  .type-item-heading {
    font-size: calc(22 * var(--vw-htab));
    font-weight: 700;
    color: rgba(5, 106, 76, 1);
  }

  .type-item-info {
    font-size: calc(10 * var(--vw-htab));
    font-weight: 400;
    color: black;
    max-height: calc(115 * var(--vw-htab));
    line-height: calc(20 * var(--vw-htab));
    margin-top: calc(10 * var(--vw-htab));
    overflow: hidden;
  }

  .customer-say {
    position: absolute;
    top: calc(4258 * var(--vw-htab));
    left: calc(33 * var(--vw-htab));
    height: calc(424 * var(--vw-htab));
    width: calc(770 * var(--vw-htab));
  }

  .customer-say-caption h2 {
    height: calc(108 * var(--vw-htab));
    width: calc(800 * var(--vw-htab));
    font-size: calc(40 * var(--vw-htab));
    font-weight: 700;
    line-height: calc(54 * var(--vw-htab));
    color: rgba(5, 66, 106, 1);
  }

  .customer-say-caption span {
    background: linear-gradient(
      to right,
      rgba(7, 51, 108, 1),
      rgba(41, 205, 178, 1)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .customer-reviews-container {
    display: flex;
    overflow: scroll;
    gap: calc(30 * var(--vw-htab));
    margin-top: calc(40 * var(--vw-htab));
    padding: calc(10 * var(--vw-htab)) calc(1 * var(--vw-htab));
  }

  .customer-review {
    height: calc(222 * var(--vw-htab));
    min-width: calc(480 * var(--vw-htab));
    border-radius: calc(8 * var(--vw-htab));
    padding: calc(20 * var(--vw-htab));
    background: linear-gradient(
      to bottom,
      rgba(226, 255, 249, 1),
      rgba(231, 245, 255, 1)
    );
    box-shadow: 0 calc(30 * var(--vw-htab)) calc(116 * var(--vw-htab))
      calc(-16 * var(--vw-htab)) rgba(211, 211, 211, 0.2);
  }

  .customer-review-text {
    font-size: calc(16 * var(--vw-htab));
    line-height: calc(30 * var(--vw-htab));
  }

  .customer-name-home {
    margin-top: calc(5 * var(--vw-htab));
    font-weight: 700;
    font-size: calc(22 * var(--vw-htab));
    color: rgba(5, 66, 106, 1);
    align-self: center;
  }

  .join-us-home {
    position: absolute;
    top: calc(4748 * var(--vw-htab));
    left: calc(32 * var(--vw-htab));
    height: calc(174 * var(--vw-htab));
    width: calc(771 * var(--vw-htab));
    display: inline-flex;
    align-items: center;
    gap: calc(21 * var(--vw-htab));
    border-radius: calc(10 * var(--vw-htab));
    background: linear-gradient(
      270deg,
      rgba(41, 205, 178, 1),
      rgba(5, 66, 106, 1)
    );
  }

  .join-us-home h2 {
    width: calc(460 * var(--vw-htab));
    height: calc(90 * var(--vw-htab));
    color: #fff;
    font-size: calc(30 * var(--vw-htab));
    font-style: normal;
    font-weight: 700;
    line-height: calc(45 * var(--vw-htab));
  }

  .join-us-home button {
    height: calc(51 * var(--vw-htab));
    width: calc(218 * var(--vw-htab));
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: calc(100 * var(--vw-htab));
    background: #fff;
    color: rgba(56, 104, 116, 1);
    font-size: calc(18 * var(--vw-htab));
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  footer {
    position: absolute;
    top: calc(5022 * var(--vw-htab));
    width: 100%;
    height: calc(70 * var(--vw-htab));
    background-color: rgba(5, 66, 106, 1);
    display: flex;
    align-items: center;
    color: #fff;
  }
  .aboutus-footer {
    font-size: calc(14 * var(--vw-htab));
    margin-right: calc(15 * var(--vw-htab));
    text-decoration: underline;
    cursor: pointer;
  }
  .copyright {
    width: calc(277 * var(--vw-htab));
    margin-left: calc(50 * var(--vw-htab));
    font-size: calc(14 * var(--vw-htab));
  }

  .liner-footer {
    margin-left: calc(12 * var(--vw-htab));
    margin-right: calc(12 * var(--vw-htab));
    font-size: calc(40 * var(--vw-htab));
    font-weight: 100;
  }

  .footer-icons-holder {
    display: inline-flex;
    gap: calc(5 * var(--vw-htab));
    margin-right: calc(30 * var(--vw-htab));
  }

  .footer-icons-holder img {
    height: calc(30 * var(--vw-htab));
    cursor: pointer;
  }
  .blogs {
    text-decoration: underline;
    font-size: calc(14 * var(--vw-htab));
    cursor: pointer;
    margin-right: calc(20 * var(--vw-htab));
  }

  .terms {
    text-decoration: underline;
    font-size: calc(14 * var(--vw-htab));
    cursor: pointer;
  }

  .home-tutorial-btnholder {
    height: calc(80 * var(--vw-htab));
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center;
  }

  .tutorial-home-btn {
    height: calc(37 * var(--vw-htab));
    width: calc(100 * var(--vw-htab));
    border: none;
    border-radius: calc(4 * var(--vw-htab));
    color: rgba(5, 66, 106, 1);
    background-color: white;
    font-size: calc(19 * var(--vw-htab));
    font-weight: 600;
  }

  .tutorial-home-btn-active {
    height: calc(37 * var(--vw-htab));
    width: calc(100 * var(--vw-htab));
    border: none;
    border-radius: calc(4 * var(--vw-htab));
    background-color: white;
    color: rgba(41, 205, 178, 1);
    font-size: calc(19 * var(--vw-htab));
    font-weight: 600;
  }

  .home-tutorial-btnholder img {
    position: absolute;
    right: calc(30 * var(--vw-htab));
    height: calc(50 * var(--vw-htab));
  }

  .home-video-holder {
    height: calc(100% - (80 * var(--vw-htab)));
    width: 100%;
    background-color: black;
  }

  @keyframes revealletters {
    0% {
      width: 0;
      height: 40%;
    }

    20% {
      width: 20%;
      height: 40%;
    }

    40% {
      width: 40%;
      height: 40%;
    }

    60% {
      width: 60%;
      height: 40%;
    }

    80% {
      width: 80%;
      height: 40%;
    }

    100% {
      width: 100%;
    }
  }

  @keyframes rotater {
    0% {
      transform: rotate(0deg);
    }

    10% {
      transform: rotate(36deg);
    }

    20% {
      transform: rotate(72deg);
    }

    30% {
      transform: rotate(108deg);
    }

    40% {
      transform: rotate(144deg);
    }

    50% {
      transform: rotate(180deg);
    }

    60% {
      transform: rotate(216deg);
    }

    70% {
      transform: rotate(252deg);
    }

    80% {
      transform: rotate(288deg);
    }

    90% {
      transform: rotate(324deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

@media (max-width: 550px) {
  .homepage {
    height: calc(5092 * var(--vw-htab));
    width: 100vw;
    overflow: hidden;
  }

  .page1 {
    height: calc(1189 * var(--vw-htab));
    width: 100%;
    background-image: linear-gradient(
      45deg,
      rgba(226, 255, 249, 1),
      rgba(231, 245, 255, 1)
    );
  }

  .navbar {
    height: calc(100 * var(--vw-htab));
    width: 100%;
    background: transparent;
    display: flex;
    align-items: center;
  }

  .function-buttons-home {
    visibility: hidden;
  }

  .logo {
    position: absolute;
    top: calc(35 * var(--vw-htab));
    left: calc(32 * var(--vw-htab));
    width: calc(276 * var(--vw-htab));
  }

  .menu-icon-home {
    position: absolute;
    top: calc(30 * var(--vw-htab));
    right: calc(32 * var(--vw-htab));
    height: calc(42 * var(--vw-htab));
  }

  .menu-options-home {
    position: absolute;
    height: auto;
    padding: calc(32 * var(--vw-htab)) 0;
    border-radius: calc(32 * var(--vw-htab));
    box-shadow: calc(-8 * var(--vw-unit)) calc(8 * var(--vw-unit))
      calc(34 * var(--vw-unit)) 0 rgba(0, 0, 0, 0.08);
    background-color: rgba(110, 240, 219, 1);
    max-height: 100vh;
    width: 50%;
    top: calc(75 * var(--vw-htab));
    right: 1%;
    transform: translateX(110%);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: calc(12 * var(--vw-htab));
    overflow: hidden;
    z-index: 30;

    transition: transform 0.3s ease-in-out;
  }

  .menu-options-home.open {
    transform: translateX(0);
  }

  .login-m,
  .signup-m {
    height: calc(46 * var(--vw-htab));
    width: 80%;
    border: calc(1 * var(--vw-htab)) solid rgba(7, 51, 108, 1);
    border-radius: calc(100 * var(--vw-htab));
    background-color: rgba(5, 66, 106, 1);
    color: white;
    padding: calc(12 * var(--vw-htab)), calc(40 * var(--vw-htab));
    cursor: pointer;
    font-size: calc(18 * var(--vw-htab));
    font-weight: 600;
  }

  .aboutus-btn-home-m,
  .calculator-tool-btn-m {
    height: calc(46 * var(--vw-htab));
    font-weight: 700;
    border: none;
    border-radius: calc(100 * var(--vw-htab));
    background: transparent;
    color: rgba(7, 51, 108, 1);
    padding: calc(12 * var(--vw-htab)), calc(40 * var(--vw-htab));
    cursor: pointer;
    font-size: calc(16 * var(--vw-htab));
    display: flex;
    align-items: center;
    gap: calc(8 * var(--vw-htab));
  }

  .aboutus-btn-home-m img,
  .calculator-tool-btn-m img {
    height: 60%;
  }

  .tutorial-btn-home-m {
    height: calc(46 * var(--vw-htab));
    border: none;
    border-radius: calc(100 * var(--vw-htab));
    background: transparent;
    color: rgba(7, 51, 108, 1);
    font-weight: 700;
    padding: calc(12 * var(--vw-htab)), calc(40 * var(--vw-htab));
    cursor: pointer;
    font-size: calc(16 * var(--vw-htab));
    display: flex;
    align-items: center;
    gap: calc(8 * var(--vw-htab));
  }

  .tutorial-btn-home-m img {
    height: 60%;
  }

  .creditcard-home {
    position: absolute;
    top: calc(135 * var(--vw-htab));
    left: 0;
    width: 100%;
    text-align: center;
    font-size: calc(19 * var(--vw-htab));
  }

  .select-role-home {
    position: absolute;
    top: calc(330 * var(--vw-htab));
    left: calc(0 * var(--vw-htab));
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: calc(20 * var(--vw-htab));
  }

  .role-btn,
  .role-btn-active {
    height: calc(50 * var(--vw-htab));
    width: auto;
    font-size: calc(18 * var(--vw-htab));
    font-weight: 600;
    padding: 0 calc(30 * var(--vw-htab));
  }

  .need-insurance-box,
  .provide-insurance-box {
    position: absolute;
    top: calc(540 * var(--vw-htab));
    left: calc(100 * var(--vw-htab));
    min-height: calc(400 * var(--vw-htab));
    height: calc(540 * var(--vw-htab));
    width: calc(100% - (200 * var(--vw-htab)));
    border: none;
    border-radius: calc(15 * var(--vw-htab));
    background-color: white;
    padding: calc(25 * var(--vw-htab));
    box-shadow: calc(-8 * var(--vw-htab)) calc(8 * var(--vw-htab))
      calc(34 * var(--vw-htab)) 0 rgba(0, 0, 0, 0.08);
    transition: all 0.3s ease-in-out;
    background-color: white;
    display: flex;
    flex-direction: column;
  }

  .need-insurance-box:hover {
    transform: scale(1.05);
  }

  .need-insurance-heading {
    text-align: center;
    font-size: calc(28 * var(--vw-htab));
    font-weight: 600;
    color: rgba(5, 66, 106, 1);
    margin-bottom: calc(10 * var(--vw-htab));
  }

  .need-insurance-sidehead {
    text-align: center;
    font-size: calc(22 * var(--vw-htab));
    font-weight: 600;
    color: rgba(5, 106, 76, 1);
    margin-bottom: calc(40 * var(--vw-htab));
  }

  .need-insurance-box span {
    font-size: calc(20 * var(--vw-htab));
  }

  .need-insurance-info {
    flex-direction: column;
    height: 50%;
  }

  .need-insurance-info h3 {
    height: 100%;
    font-size: calc(14 * var(--vw-htab));
    padding: 0 calc(30 * var(--vw-htab));
  }

  .btn-holder-home {
    height: 20%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .select-wrapper-home {
    height: 50%;
    width: calc(310 * var(--vw-htab));
    overflow: hidden;
    background-color: rgba(5, 66, 106, 1);
  }

  .btn-holder-home select {
    height: 100%;
    width: 90%;
    border: none;
    border-radius: calc(30 * var(--vw-htab));
    color: white;
    font-size: calc(18 * var(--vw-htab));
    font-weight: 600;
    padding: 0;
  }
  .btn-holder-home select:focus {
    outline: none;
  }

  .need-insurance-box-content {
    height: 70%;
    display: flex;
    flex-direction: column;
  }

  .provide-insurance-heading {
    text-align: center;
    font-size: calc(26 * var(--vw-htab));
    font-weight: 600;
    color: rgba(5, 66, 106, 1);
    margin-bottom: calc(0 * var(--vw-htab));
  }

  .provide-insurance-box span {
    font-size: calc(20 * var(--vw-htab));
  }

  .quote-head {
    position: absolute;
    top: calc(162 * var(--vw-htab));
    left: 0;
    width: 100%;
    text-align: center;
    font-size: calc(40 * var(--vw-htab));
    font-weight: 700;
    color: rgba(5, 66, 106, 1);
  }

  .quote-info {
    position: absolute;
    top: calc(273 * var(--vw-htab));
    left: calc(114 * var(--vw-htab));
    width: calc(606 * var(--vw-htab));
    font-size: calc(16 * var(--vw-htab));
    font-weight: 400;
    color: rgba(79, 79, 79, 1);
  }

  .role-background {
    position: absolute;
    top: calc(950 * var(--vw-htab));
  }

  .get-started {
    position: absolute;
    height: calc(48 * var(--vw-htab));
    width: calc(200 * var(--vw-htab));
    top: calc(301 * var(--vw-htab));
    left: calc(317 * var(--vw-htab));
    border-radius: calc(100 * var(--vw-htab));
    cursor: pointer;
    font-size: calc(16 * var(--vw-htab));
    visibility: hidden;
  }

  .solar-background {
    position: absolute;
    height: calc(540 * var(--vw-htab));
    top: calc(405 * var(--vw-htab));
    left: calc(146 * var(--vw-htab));
    z-index: 10;
    animation: rotater 50s linear infinite;
    visibility: hidden;
  }

  .notify1 {
    position: absolute;
    height: calc(85 * var(--vw-htab));
    width: calc(260 * var(--vw-htab));
    top: calc(455 * var(--vw-htab));
    left: calc(68 * var(--vw-htab));
    border-radius: calc(10 * var(--vw-htab));
    padding:
      calc(8 * var(--vw-htab)), calc(24 * var(--vw-htab)),
      calc(8 * var(--vw-htab)), calc(16 * var(--vw-htab));
    background-color: rgba(255, 255, 255, 1);
    box-shadow: calc(-8 * var(--vw-htab)) calc(8 * var(--vw-htab))
      calc(34 * var(--vw-htab)) 0 rgba(0, 0, 0, 0.08);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: calc(16 * var(--vw-htab));
    z-index: 20;
    visibility: hidden;
  }

  .bell-notify {
    height: calc(28 * var(--vw-htab));
    border-radius: calc(4 * var(--vw-htab));
  }

  .notify1-content {
    width: calc(182 * var(--vw-htab));
    font-weight: 600;
    font-size: calc(16 * var(--vw-htab));
    color: rgba(5, 66, 106, 1);
  }

  .notify2 {
    position: absolute;
    height: calc(144 * var(--vw-htab));
    width: calc(201 * var(--vw-htab));
    top: calc(571 * var(--vw-htab));
    left: calc(586 * var(--vw-htab));
    background: transparent;
    z-index: 20;
    visibility: hidden;
  }

  .commas {
    position: absolute;
    height: calc(40 * var(--vw-htab));
    margin-left: calc(15 * var(--vw-htab));
    z-index: 30;
    visibility: hidden;
  }

  .notify2-content {
    height: calc(90 * var(--vw-htab));
    width: calc(201 * var(--vw-htab));
    margin-top: calc(34 * var(--vw-htab));
    background-color: rgba(255, 255, 255, 1);
    border-radius: calc(10 * var(--vw-htab));
    box-shadow: calc(-8 * var(--vw-htab)) calc(8 * var(--vw-htab))
      calc(34 * var(--vw-htab)) 0 rgba(0, 0, 0, 0.08);
    padding: calc(8 * var(--vw-htab)) calc(24 * var(--vw-htab))
      calc(8 * var(--vw-htab)) calc(16 * var(--vw-htab));
  }

  .notify2-content p {
    font-size: calc(14 * var(--vw-htab));
    font-weight: 500;
    color: rgba(5, 66, 106, 1);
  }

  .notify2-content img {
    width: calc(82 * var(--vw-htab));
  }

  .bid-notify-home {
    position: absolute;
    height: calc(54 * var(--vw-htab));
    width: calc(437 * var(--vw-htab));
    top: calc(892 * var(--vw-htab));
    left: calc(59 * var(--vw-htab));
    border-radius: calc(10 * var(--vw-htab));
    gap: calc(24 * var(--vw-htab));
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: calc(-8 * var(--vw-htab)) calc(8 * var(--vw-htab))
      calc(36 * var(--vw-htab)) 0 rgba(0, 0, 0, 0.08);
    z-index: 20;
    visibility: hidden;
  }

  .bid-notify-home img {
    height: calc(19 * var(--vw-htab));
    border-radius: calc(4 * var(--vw-htab));
  }

  .bid-notify-home p {
    width: calc(191 * var(--vw-htab));
    padding: 0;
    font-weight: 700;
    font-size: calc(14 * var(--vw-htab));
    color: rgba(5, 66, 106, 1);
  }

  .accept-home {
    height: calc(28 * var(--vw-htab));
    width: calc(63 * var(--vw-htab));
    border-radius: calc(4 * var(--vw-htab));
    font-weight: 500;
    font-size: calc(12 * var(--vw-htab));
    border: none;
    color: rgba(255, 255, 255, 1);
    background-color: rgba(5, 106, 76, 1);
    cursor: default !important;
  }

  .reject-home {
    height: calc(28 * var(--vw-htab));
    width: calc(63 * var(--vw-htab));
    border-radius: calc(4 * var(--vw-htab));
    font-weight: 500;
    font-size: calc(12 * var(--vw-htab));
    border: none;
    color: rgba(255, 255, 255, 1);
    background-color: rgba(193, 20, 20, 1);
    cursor: default !important;
  }

  .inactive-notify {
    position: absolute;
    top: calc(710 * var(--vw-htab));
    left: calc(60 * var(--vw-htab));
    height: calc(80 * var(--vw-htab));
    width: calc(360 * var(--vw-htab));
    display: flex;
    align-items: center;
    border-radius: calc(10 * var(--vw-htab));
    padding: calc(8 * var(--vw-htab)) calc(16 * var(--vw-htab))
      calc(8 * var(--vw-htab)) calc(24 * var(--vw-htab));
    background-color: rgba(255, 255, 255, 1);
    box-shadow: calc(-8 * var(--vw-htab)) calc(8 * var(--vw-htab))
      calc(36 * var(--vw-htab)) 0 rgba(0, 0, 0, 0.08);
    z-index: 30;
    visibility: hidden;
  }

  .inactive-notify span {
    width: calc(300 * var(--vw-htab));
    font-weight: 600;
    font-size: calc(16 * var(--vw-htab));
    color: rgba(5, 66, 106, 1);
    display: inline-block;
  }

  .active-notify {
    position: absolute;
    top: calc(710 * var(--vw-htab));
    left: calc(60 * var(--vw-htab));
    height: calc(80 * var(--vw-htab));
    width: calc(360 * var(--vw-htab));
    display: flex;
    align-items: center;
    border-radius: calc(10 * var(--vw-htab));
    padding: calc(8 * var(--vw-htab)) calc(16 * var(--vw-htab))
      calc(8 * var(--vw-htab)) calc(24 * var(--vw-htab));
    background-color: rgba(255, 255, 255, 1);
    box-shadow: calc(-8 * var(--vw-htab)) calc(8 * var(--vw-htab))
      calc(36 * var(--vw-htab)) 0 rgba(0, 0, 0, 0.08);
    z-index: 30;
    visibility: hidden;
  }

  .active-notify span {
    width: calc(300 * var(--vw-htab));
    font-weight: 600;
    font-size: calc(16 * var(--vw-htab));
    color: rgba(5, 66, 106, 1);
    display: inline-block;
  }

  .toggle-info-container {
    overflow: hidden;
    animation: revealletters 1s steps(100, end);
    visibility: hidden;
  }

  .toggle-btn-home-active {
    position: absolute;
    top: calc(625 * var(--vw-htab));
    left: calc(320 * var(--vw-htab));
    height: calc(62 * var(--vw-htab));
    width: calc(148 * var(--vw-htab));
    background-color: rgba(5, 66, 106, 1);
    border-radius: calc(50 * var(--vw-htab));
    display: flex;
    align-items: center;
    z-index: 30;
    cursor: pointer;
    transition: background-color 0.8s ease;
    visibility: hidden;
  }

  .toggle-btn-home-inactive {
    position: absolute;
    top: calc(625 * var(--vw-htab));
    left: calc(320 * var(--vw-htab));
    height: calc(62 * var(--vw-htab));
    width: calc(148 * var(--vw-htab));
    background: transparent;
    border: calc(1 * var(--vw-htab)) solid rgba(5, 106, 76, 1);
    border-radius: calc(50 * var(--vw-htab));
    display: flex;
    align-items: center;
    z-index: 30;
    cursor: pointer;
    transition: background-color 0.8s ease;
    visibility: hidden;
  }

  .toggle-btn-home-inactive span {
    color: rgba(5, 106, 76, 1);
    font-style: italic;
    font-weight: 600;
    font-size: calc(18 * var(--vw-htab));
    padding-left: calc(66 * var(--vw-htab));
  }

  .toggle-btn-home-active span {
    color: white;
    font-style: italic;
    font-weight: 600;
    font-size: calc(18 * var(--vw-htab));
    padding-left: calc(10 * var(--vw-htab));
  }

  .toggle-bar-home-active {
    position: absolute;
    right: calc(-1 * var(--vw-htab));
    height: calc(65 * var(--vw-htab));
    width: calc(65 * var(--vw-htab));
    background-color: rgba(110, 240, 219, 1);
    border-radius: 50%;
    z-index: 40;
    transition:
      background-color 0.3s ease,
      transform 0.3s ease;
  }

  .toggle-bar-home-inactive {
    position: absolute;
    height: calc(65 * var(--vw-htab));
    width: calc(65 * var(--vw-htab));
    background-color: rgba(226, 255, 249, 1);
    border: calc(0.3 * var(--vw-htab)) solid rgba(5, 106, 76, 1);
    border-radius: 50%;
    z-index: 40;
    transition:
      background-color 0.3s ease,
      transform 0.3s ease;
  }

  .girl-home {
    position: absolute;
    top: calc(690 * var(--vw-htab));
    left: calc(460 * var(--vw-htab));
    height: calc(247.6 * var(--vw-htab));
    z-index: 40;
    visibility: hidden;
  }

  .lorem-details {
    position: absolute;
    height: calc(250 * var(--vw-htab));
    width: calc(770 * var(--vw-htab));
    top: calc(1000 * var(--vw-htab));
    left: calc(32 * var(--vw-htab));
    border-radius: calc(10 * var(--vw-htab));
    background-color: rgba(255, 255, 255, 1);
    box-shadow: calc(4 * var(--vw-htab)) calc(4 * var(--vw-htab))
      calc(40 * var(--vw-htab)) 0 rgba(0, 0, 0, 0.08);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: calc(10 * var(--vw-htab));
  }

  .lorem-options {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: calc(200 * var(--vw-htab));
    padding-bottom: calc(5 * var(--vw-htab));
  }

  .lorem-btn {
    height: calc(32 * var(--vw-htab));
    width: calc(200 * var(--vw-htab));
    border: calc(1 * var(--vw-htab)) solid rgba(5, 66, 106, 1);
    background-color: white;
    border-radius: calc(6 * var(--vw-htab));
    color: rgba(5, 66, 106, 1);
    font-size: calc(16 * var(--vw-htab));
    font-weight: 600;
    transition: all 0.3s ease-in-out;
  }

  .lorem-btn-active {
    height: calc(32 * var(--vw-htab));
    width: calc(200 * var(--vw-htab));
    border: none;
    background-color: rgba(5, 66, 106, 1);
    border-radius: calc(6 * var(--vw-htab));
    color: white;
    font-size: calc(16 * var(--vw-htab));
    font-weight: 600;
    transition: all 0.3s ease-in-out;
  }

  .lorem-btn:hover,
  .lorem-btn-active:hover {
    height: calc(32 * var(--vw-htab));
    width: calc(200 * var(--vw-htab));
    border: calc(1 * var(--vw-htab)) solid rgba(110, 240, 219, 1);
    font-size: calc(17 * var(--vw-htab));
  }

  .lorem-content-holder {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: calc(15 * var(--vw-htab));
  }

  .lorem-content {
    height: calc(97 * var(--vw-htab));
    width: calc(227 * var(--vw-htab));
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .lorem-content-heading {
    font-weight: 700;
    font-size: calc(16 * var(--vw-htab));
    color: rgba(79, 79, 79, 1);
  }

  .lorem-content-info {
    font-weight: 400;
    font-size: calc(12 * var(--vw-htab));
    margin-top: calc(10 * var(--vw-htab));
    text-align: center;
    color: rgba(144, 144, 144, 1);
  }

  .deals-notify-head {
    position: absolute;
    height: calc(100 * var(--vw-htab));
    width: calc(597 * var(--vw-htab));
    top: calc(1349 * var(--vw-htab));
    left: calc(119 * var(--vw-htab));
    line-height: calc(50 * var(--vw-htab));
    font-size: calc(35 * var(--vw-htab));
    font-weight: 700;
    color: rgba(7, 51, 108, 1);
    text-align: center;
  }

  .deals-notify-head span {
    background: linear-gradient(
      to right,
      rgba(7, 51, 108, 1),
      rgba(41, 205, 178, 1)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .deals-notify-info {
    position: absolute;
    height: calc(48 * var(--vw-htab));
    width: calc(540 * var(--vw-htab));
    top: calc(1473 * var(--vw-htab));
    left: calc(147 * var(--vw-htab));
    font-weight: 400;
    font-size: calc(16 * var(--vw-htab));
    color: rgba(79, 79, 79, 1);
    text-align: center;
  }

  .machine-home {
    position: absolute;
    top: calc(1520 * var(--vw-htab));
    left: calc(-6 * var(--vw-htab));
    height: calc(445 * var(--vw-htab));
  }

  .point1 {
    position: absolute;
    top: calc(2038 * var(--vw-htab));
    left: calc(147 * var(--vw-htab));
    border-radius: calc(6 * var(--vw-htab));
    display: flex;
    align-items: center;
    gap: calc(10 * var(--vw-htab));
  }

  .point2 {
    position: absolute;
    top: calc(2120 * var(--vw-htab));
    left: calc(147 * var(--vw-htab));
    border-radius: calc(6 * var(--vw-htab));
    display: flex;
    align-items: center;
    gap: calc(10 * var(--vw-htab));
  }

  .point3 {
    position: absolute;
    top: calc(2202 * var(--vw-htab));
    left: calc(147 * var(--vw-htab));
    border-radius: calc(6 * var(--vw-htab));
    display: flex;
    align-items: center;
    gap: calc(10 * var(--vw-htab));
  }

  .point4 {
    position: absolute;
    top: calc(2284 * var(--vw-htab));
    left: calc(147 * var(--vw-htab));
    border-radius: calc(6 * var(--vw-htab));
    display: flex;
    align-items: center;
    gap: calc(10 * var(--vw-htab));
  }

  .point1 img,
  .point2 img,
  .point3 img,
  .point4 img {
    height: calc(50 * var(--vw-htab));
    width: calc(50 * var(--vw-htab));
  }

  .points-info {
    font-weight: 700;
    font-size: calc(22 * var(--vw-htab));
    color: rgba(46, 46, 46, 1);
  }

  .comparison-block {
    position: absolute;
    height: calc(650 * var(--vw-htab));
    width: calc(770 * var(--vw-htab));
    top: calc(2454 * var(--vw-htab));
    left: calc(32 * var(--vw-htab));
    border-radius: calc(20 * var(--vw-htab));
    background-image: linear-gradient(
      to bottom,
      rgba(7, 51, 108, 1),
      rgba(41, 205, 178, 1)
    );
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .comparison-head {
    height: calc(100 * var(--vw-htab));
    width: calc(730 * var(--vw-htab));
    margin-top: calc(56 * var(--vw-htab));
    font-weight: 700;
    font-size: calc(42 * var(--vw-htab));
    line-height: calc(50.4 * var(--vw-htab));
    text-align: center;
    color: rgba(255, 255, 255, 1);
  }

  .comparison-info {
    height: calc(42 * var(--vw-htab));
    width: calc(523 * var(--vw-htab));
    margin-top: calc(20 * var(--vw-htab));
    font-weight: 400;
    font-size: calc(14 * var(--vw-htab));
    line-height: calc(21 * var(--vw-htab));
    text-align: center;
    color: rgba(255, 255, 255, 1);
  }

  .comparison-table {
    border-radius: calc(10 * var(--vw-htab));
    margin-top: calc(80 * var(--vw-htab));
    overflow: hidden;
    border-collapse: separate;
    border-spacing: calc(6 * var(--vw-htab));
  }
  .comparison-table thead {
    color: #fff;
    background-color: rgba(5, 66, 106, 1);
    border-top-left-radius: calc(5 * var(--vw-htab));
    border-top-right-radius: calc(5 * var(--vw-htab));
    overflow: hidden;
  }
  .comparison-table th {
    padding: calc(8 * var(--vw-htab)) calc(25 * var(--vw-htab));
    font-size: calc(12 * var(--vw-htab));
    font-weight: 500;
  }
  .comparison-table td {
    color: rgba(5, 66, 106, 1);
    padding: calc(5 * var(--vw-htab)) calc(25 * var(--vw-htab));
    font-size: calc(12 * var(--vw-htab));
    font-weight: 500;
  }
  .comparison-table tbody {
    background-color: rgba(228, 251, 251, 1);
  }
  .comparison-table .td-image {
    text-align: center;
    padding: calc(2 * var(--vw-htab)) 0;
  }
  .comparison-table .td-image img {
    height: calc(25 * var(--vw-htab));
  }

  .needs-home {
    position: absolute;
    top: calc(3154 * var(--vw-htab));
    left: calc(97 * var(--vw-htab));
    height: calc(225 * var(--vw-htab));
    width: calc(640 * var(--vw-htab));
  }

  .needs-head {
    height: calc(100 * var(--vw-htab));
    width: calc(640 * var(--vw-htab));
    font-size: calc(42 * var(--vw-htab));
    font-weight: 700;
    color: rgba(5, 66, 106, 1);
    text-align: center;
  }

  .needs-head span {
    background: linear-gradient(
      to right,
      rgba(7, 51, 108, 1),
      rgba(41, 205, 178, 1)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .needs-info {
    color: rgba(79, 79, 79, 1);
    margin-top: calc(24 * var(--vw-htab));
    font-size: calc(16 * var(--vw-htab));
    text-align: center;
  }

  .types-container-home {
    position: absolute;
    top: calc(3386 * var(--vw-htab));
    left: calc(32 * var(--vw-htab));
    height: calc(752 * var(--vw-htab));
    width: calc(771 * var(--vw-htab));
    display: flex;
    flex-wrap: wrap;
  }

  .type-item {
    flex: 1 0 40%;
    margin: calc(10 * var(--vw-htab));
    padding: calc(20 * var(--vw-htab));
    height: calc(240 * var(--vw-htab));
    border: calc(2 * var(--vw-htab)) solid rgba(110, 240, 219, 1);
    border-radius: calc(10 * var(--vw-htab));
  }

  .type-item-heading {
    font-size: calc(22 * var(--vw-htab));
    font-weight: 700;
    color: rgba(5, 106, 76, 1);
  }

  .type-item-info {
    font-size: calc(14 * var(--vw-htab));
    font-weight: 400;
    color: black;
    max-height: calc(115 * var(--vw-htab));
    line-height: calc(20 * var(--vw-htab));
    margin-top: calc(10 * var(--vw-htab));
    overflow: hidden;
  }

  .customer-say {
    position: absolute;
    top: calc(4558 * var(--vw-htab));
    left: calc(33 * var(--vw-htab));
    height: calc(424 * var(--vw-htab));
    width: calc(770 * var(--vw-htab));
  }

  .customer-say-caption h2 {
    height: calc(108 * var(--vw-htab));
    width: calc(800 * var(--vw-htab));
    font-size: calc(40 * var(--vw-htab));
    font-weight: 700;
    line-height: calc(54 * var(--vw-htab));
    color: rgba(5, 66, 106, 1);
  }

  .customer-say-caption span {
    background: linear-gradient(
      to right,
      rgba(7, 51, 108, 1),
      rgba(41, 205, 178, 1)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .customer-reviews-container {
    display: flex;
    overflow: scroll;
    gap: calc(30 * var(--vw-htab));
    margin-top: calc(40 * var(--vw-htab));
    padding: calc(10 * var(--vw-htab)) calc(1 * var(--vw-htab));
  }

  .customer-review {
    height: calc(222 * var(--vw-htab));
    min-width: calc(480 * var(--vw-htab));
    border-radius: calc(8 * var(--vw-htab));
    padding: calc(20 * var(--vw-htab));
    background: linear-gradient(
      to bottom,
      rgba(226, 255, 249, 1),
      rgba(231, 245, 255, 1)
    );
    box-shadow: 0 calc(30 * var(--vw-htab)) calc(116 * var(--vw-htab))
      calc(-16 * var(--vw-htab)) rgba(211, 211, 211, 0.2);
  }

  .customer-review-text {
    font-size: calc(16 * var(--vw-htab));
    line-height: calc(30 * var(--vw-htab));
  }

  .customer-name-home {
    margin-top: calc(5 * var(--vw-htab));
    font-weight: 700;
    font-size: calc(22 * var(--vw-htab));
    color: rgba(5, 66, 106, 1);
    align-self: center;
  }

  .join-us-home {
    position: absolute;
    top: calc(5048 * var(--vw-htab));
    left: calc(32 * var(--vw-htab));
    height: calc(174 * var(--vw-htab));
    width: calc(771 * var(--vw-htab));
    display: inline-flex;
    align-items: center;
    gap: calc(21 * var(--vw-htab));
    border-radius: calc(10 * var(--vw-htab));
    background: linear-gradient(
      270deg,
      rgba(41, 205, 178, 1),
      rgba(5, 66, 106, 1)
    );
  }

  .join-us-home h2 {
    width: calc(460 * var(--vw-htab));
    height: calc(90 * var(--vw-htab));
    color: #fff;
    font-size: calc(30 * var(--vw-htab));
    font-style: normal;
    font-weight: 700;
    line-height: calc(45 * var(--vw-htab));
  }

  .join-us-home button {
    height: calc(51 * var(--vw-htab));
    width: calc(218 * var(--vw-htab));
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: calc(100 * var(--vw-htab));
    background: #fff;
    color: rgba(56, 104, 116, 1);
    font-size: calc(18 * var(--vw-htab));
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  footer {
    position: absolute;
    top: calc(5322 * var(--vw-htab));
    width: 100%;
    height: calc(70 * var(--vw-htab));
    background-color: rgba(5, 66, 106, 1);
    display: flex;
    align-items: center;
    color: #fff;
  }
  .aboutus-footer {
    font-size: calc(14 * var(--vw-htab));
    margin-right: calc(15 * var(--vw-htab));
    text-decoration: underline;
    cursor: pointer;
  }

  .copyright {
    width: calc(277 * var(--vw-htab));
    margin-left: calc(50 * var(--vw-htab));
    font-size: calc(14 * var(--vw-htab));
  }

  .liner-footer {
    margin-left: calc(12 * var(--vw-htab));
    margin-right: calc(12 * var(--vw-htab));
    font-size: calc(40 * var(--vw-htab));
    font-weight: 100;
  }

  .footer-icons-holder {
    display: inline-flex;
    gap: calc(5 * var(--vw-htab));
    margin-right: calc(30 * var(--vw-htab));
  }

  .footer-icons-holder img {
    height: calc(30 * var(--vw-htab));
    cursor: pointer;
  }

  .blogs {
    text-decoration: underline;
    font-size: calc(14 * var(--vw-htab));
    cursor: pointer;
    margin-right: calc(20 * var(--vw-htab));
  }

  .terms {
    text-decoration: underline;
    font-size: calc(14 * var(--vw-htab));
    cursor: pointer;
  }

  .home-tutorial-btnholder {
    height: calc(80 * var(--vw-mob));
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center;
  }

  .tutorial-home-btn {
    height: calc(37 * var(--vw-mob));
    width: calc(100 * var(--vw-mob));
    border: none;
    border-radius: calc(4 * var(--vw-mob));
    color: rgba(5, 66, 106, 1);
    background-color: white;
    font-size: calc(15 * var(--vw-mob));
    font-weight: 600;
  }

  .tutorial-home-btn-active {
    height: calc(37 * var(--vw-mob));
    width: calc(100 * var(--vw-mob));
    border: none;
    border-radius: calc(4 * var(--vw-mob));
    background-color: white;
    color: rgba(41, 205, 178, 1);
    font-size: calc(16 * var(--vw-mob));
    font-weight: 600;
  }

  .home-tutorial-btnholder img {
    position: absolute;
    right: calc(30 * var(--vw-mob));
    height: calc(40 * var(--vw-mob));
  }

  .home-video-holder {
    height: calc(100% - (80 * var(--vw-mob)));
    width: 100%;
  }

  @keyframes revealletters {
    0% {
      width: 0;
      height: 40%;
    }

    20% {
      width: 20%;
      height: 40%;
    }

    40% {
      width: 40%;
      height: 40%;
    }

    60% {
      width: 60%;
      height: 40%;
    }

    80% {
      width: 80%;
      height: 40%;
    }

    100% {
      width: 100%;
    }
  }

  @keyframes rotater {
    0% {
      transform: rotate(0deg);
    }

    10% {
      transform: rotate(36deg);
    }

    20% {
      transform: rotate(72deg);
    }

    30% {
      transform: rotate(108deg);
    }

    40% {
      transform: rotate(144deg);
    }

    50% {
      transform: rotate(180deg);
    }

    60% {
      transform: rotate(216deg);
    }

    70% {
      transform: rotate(252deg);
    }

    80% {
      transform: rotate(288deg);
    }

    90% {
      transform: rotate(324deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
