.reset-password-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.reset-password-data {
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 400px;
  text-align: center;
}

.reset-password-data label {
  display: block;
  margin: 15px 0 5px;
  font-weight: bold;
  color: #333;
}

.reset-password-data input {
  height: 40px;
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  color: black;
}

.reset-password-data button {
  background-color: rgba(7, 51, 108, 1);
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.reset-password-data button:hover {
  opacity: 0.9;
}
.loading-changepassword {
  position: fixed;
  z-index: 100;
  background-color: white;
}

.success-message {
  margin-top: 20px;
}

.success-message img {
  width: 50px;
  height: 50px;
}

@media (max-width: 600px) {
  .reset-password-data {
    width: 100%;
    padding: 15px;
  }

  .reset-password-data input {
    padding: 8px;
    font-size: 14px;
  }

  .reset-password-data button {
    padding: 8px 16px;
    font-size: 14px;
  }

  .success-message img {
    width: 40px;
    height: 40px;
  }
}
