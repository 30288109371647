.terms-pop {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  max-width: 90%;
  max-height: 80vh;
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  font-family: 'Arial', sans-serif;
  z-index: 100;
}

.terms-content {
  padding: 20px;
  overflow-y: auto;
  flex: 1;
  font-size: 16px;
  line-height: 1.5;
}
.terms-content h2 {
  margin-bottom: 20px;
}

.terms-buttons {
  position: sticky;
  bottom: 0;
  background-color: #f9f9f9;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #ddd;
}

/* Button styling */
.terms-buttons button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.terms-buttons button:hover {
  background-color: #0056b3;
}

.cancel-button {
  background-color: #6c757d;
}

.cancel-button:hover {
  background-color: #5a6268;
}

/* Backdrop overlay to dim the background */
.popup-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 100;
}
