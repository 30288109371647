@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

:root {
  --vw-unit: calc((100 / 1440) * 1vw);
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

p {
  padding: 0;
  margin: 0;
}

.notifications-page {
  height: 100%;
  width: 100%;
}

.notification-heading {
  position: absolute;
  top: calc(16 * var(--vw-unit));
  left: calc(24 * var(--vw-unit));
  font-size: calc(18 * var(--vw-unit));
  font-weight: 600;
  color: rgba(46, 46, 46, 1);
}

.notifications-holder {
  position: absolute;
  height: 90%;
  width: 96%;
  top: calc(50 * var(--vw-unit));
  left: calc(24 * var(--vw-unit));
  background-color: white;
  overflow: scroll;
}

.empty-holder-notifications {
  width: 100%;
  padding-top: calc(80 * var(--vw-unit));
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: calc(30 * var(--vw-unit));
}
.empty-notifications {
  height: calc(250 * var(--vw-unit));
}

.notification-card {
  min-height: calc(54 * var(--vw-unit));
  height: auto;
  width: calc(1020 * var(--vw-unit));
  margin-left: calc(20 * var(--vw-unit));
  margin-top: calc(20 * var(--vw-unit));
  border-radius: calc(4 * var(--vw-unit));
  display: flex;
  align-items: center;
}

.notification-card img {
  height: calc(8 * var(--vw-unit));
  margin-left: calc(24 * var(--vw-unit));
  margin-right: calc(24 * var(--vw-unit));
}

.notification-card p {
  height: auto;
  width: calc(740 * var(--vw-unit));
  font-size: calc(16 * var(--vw-unit));
  font-weight: 500;
}

.notification-card span {
  width: calc(120 * var(--vw-unit));
  font-weight: 400;
  font-size: calc(12 * var(--vw-unit));
  color: rgba(46, 46, 46, 1);
}

.notification-card button {
  height: calc(30 * var(--vw-unit));
  width: calc(72 * var(--vw-unit));
  border: calc(0.5 * var(--vw-unit)) solid rgba(7, 51, 108, 1);
  border-radius: calc(4 * var(--vw-unit));
  background-color: rgba(7, 51, 108, 1);
  color: white;
  font-size: calc(12 * var(--vw-unit));
}

.bid-accepted-pop,
.bid-rejected-pop {
  position: absolute;
  top: calc(168 * var(--vw-unit));
  left: calc(150 * var(--vw-unit));
  height: calc(260 * var(--vw-unit));
  width: calc(560 * var(--vw-unit));
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: calc(8 * var(--vw-unit));
  border: calc(2 * var(--vw-unit)) solid rgba(7, 51, 108, 0.2);
}

.congrats {
  font-weight: 800;
  font-size: calc(18 * var(--vw-unit));
  text-align: center;
  color: rgba(7, 51, 108, 1);
  margin-top: calc(25 * var(--vw-unit));
  margin-bottom: calc(25 * var(--vw-unit));
}

.bid-accept-info {
  font-weight: 600;
  font-size: calc(18 * var(--vw-unit));
  text-align: center;
  margin-bottom: calc(25 * var(--vw-unit));
}

.bid-accept-details {
  font-weight: 400;
  font-size: calc(14 * var(--vw-unit));
  color: rgba(125, 125, 125, 1);
  text-align: center;
}

.bid-accepted-pop .mail-link {
  font-weight: 400;
  font-size: calc(14 * var(--vw-unit));
  text-align: center;
}
.mail-link {
  color: rgba(7, 51, 108, 1);
}

.bid-accept-pop-close,
.bid-reject-pop-close {
  height: calc(36 * var(--vw-unit));
  width: calc(76 * var(--vw-unit));
  border: none;
  border-radius: calc(4 * var(--vw-unit));
  background-color: rgba(7, 51, 108, 1);
  color: white;
  font-size: calc(13 * var(--vw-unit));
  font-weight: 500;
  margin-top: calc(30 * var(--vw-unit));
}

.bid-result-close {
  position: absolute;
  top: calc(180 * var(--vw-unit));
  left: calc(680 * var(--vw-unit));
  font-weight: 800;
  font-size: calc(20 * var(--vw-unit));
  cursor: pointer;
  z-index: 100;
}

.bid-rejected-pop {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: calc(30 * var(--vw-unit));
  height: calc(200 * var(--vw-unit));
}

.bid-rejected-pop p {
  font-weight: 600;
  font-size: calc(18 * var(--vw-unit));
  text-align: center;
}

.editpost-holder-notification {
  position: fixed;
  top: calc(150 * var(--vw-unit));
  max-height: calc(450 * var(--vw-unit));
  height: 60vh;
  width: calc(1000 * var(--vw-unit));
  padding: calc(45 * var(--vw-unit));
  overflow: scroll;
}

@media (min-width: 550px) and (max-width: 1200px) {
  .notifications-page {
    height: 100%;
    width: 100%;
  }

  .notification-heading {
    position: absolute;
    top: calc(16 * var(--vw-tab));
    left: calc(24 * var(--vw-tab));
    font-size: calc(18 * var(--vw-tab));
    font-weight: 600;
    color: rgba(46, 46, 46, 1);
  }

  .notifications-holder {
    position: absolute;
    height: 85vh;
    width: 96%;
    top: calc(50 * var(--vw-tab));
    left: calc(24 * var(--vw-tab));
    background-color: white;
    overflow: scroll;
  }
  .empty-holder-notifications {
    width: 100%;
    padding-top: calc(80 * var(--vw-tab));
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: calc(30 * var(--vw-tab));
  }
  .empty-notifications {
    height: calc(250 * var(--vw-tab));
  }

  .notification-card {
    height: calc(54 * var(--vw-tab));
    width: 92%;
    margin-left: calc(10 * var(--vw-tab));
    margin-top: calc(20 * var(--vw-tab));
    border-radius: calc(4 * var(--vw-tab));
    display: flex;
    align-items: center;
  }

  .notification-card img {
    height: calc(8 * var(--vw-tab));
    margin-left: calc(24 * var(--vw-tab));
    margin-right: calc(24 * var(--vw-tab));
  }

  .notification-card p {
    height: calc(21 * var(--vw-tab));
    width: calc(540 * var(--vw-tab));
    margin-right: calc(2 * var(--vw-tab));
    font-size: calc(16 * var(--vw-tab));
    font-weight: 500;
  }

  .notification-card span {
    margin-right: 0;
    width: calc(80 * var(--vw-tab));
    font-weight: 400;
    font-size: calc(10 * var(--vw-tab));
    color: rgba(46, 46, 46, 1);
  }

  .notification-card button {
    position: fixed;
    visibility: hidden;
    height: calc(30 * var(--vw-tab));
    width: calc(72 * var(--vw-tab));
    border: calc(0.5 * var(--vw-tab)) solid rgba(7, 51, 108, 1);
    border-radius: calc(4 * var(--vw-tab));
    background-color: rgba(7, 51, 108, 1);
    color: white;
    font-size: calc(12 * var(--vw-tab));
  }

  .bid-accepted-pop,
  .bid-rejected-pop {
    position: absolute;
    top: calc(168 * var(--vw-tab));
    left: calc(150 * var(--vw-tab));
    height: calc(260 * var(--vw-tab));
    width: calc(560 * var(--vw-tab));
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: calc(8 * var(--vw-tab));
    border: calc(2 * var(--vw-tab)) solid rgba(7, 51, 108, 0.2);
  }

  .congrats {
    font-weight: 800;
    font-size: calc(18 * var(--vw-tab));
    text-align: center;
    color: rgba(7, 51, 108, 1);
    margin-top: calc(25 * var(--vw-tab));
    margin-bottom: calc(25 * var(--vw-tab));
  }

  .bid-accept-info {
    font-weight: 600;
    font-size: calc(18 * var(--vw-tab));
    text-align: center;
    margin-bottom: calc(25 * var(--vw-tab));
  }

  .bid-accept-details {
    font-weight: 400;
    font-size: calc(14 * var(--vw-tab));
    color: rgba(125, 125, 125, 1);
    text-align: center;
  }

  .bid-accepted-pop .mail-link {
    font-weight: 400;
    font-size: calc(14 * var(--vw-tab));
    text-align: center;
  }

  .bid-accept-pop-close,
  .bid-reject-pop-close {
    height: calc(36 * var(--vw-tab));
    width: calc(76 * var(--vw-tab));
    border: none;
    border-radius: calc(4 * var(--vw-tab));
    background-color: rgba(7, 51, 108, 1);
    color: white;
    font-size: calc(13 * var(--vw-tab));
    font-weight: 500;
    margin-top: calc(30 * var(--vw-tab));
  }

  .bid-result-close {
    position: absolute;
    top: calc(180 * var(--vw-tab));
    left: calc(680 * var(--vw-tab));
    font-weight: 800;
    font-size: calc(20 * var(--vw-tab));
    cursor: pointer;
    z-index: 100;
  }

  .bid-rejected-pop {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: calc(30 * var(--vw-tab));
    height: calc(200 * var(--vw-tab));
  }

  .bid-rejected-pop p {
    font-weight: 600;
    font-size: calc(18 * var(--vw-tab));
    text-align: center;
  }

  .editpost-holder-notification {
    padding: 0;
    position: fixed;
    top: calc(180 * var(--vw-tab));
    left: calc(80 * var(--vw-tab));
    max-height: calc(600 * var(--vw-tab));
    height: 60vh;
    width: calc(650 * var(--vw-tab));
    border-radius: calc(8 * var(--vw-tab));
    box-shadow: calc(8 * var(--vw-tab)) calc(8 * var(--vw-tab))
      calc(180 * var(--vw-tab)) calc(180 * var(--vw-tab)) rgba(80, 80, 80, 0.2);
    z-index: 50;
    overflow: scroll;
  }
}

@media (max-width: 550px) {
  .notifications-page {
    height: 100%;
    width: 100%;
  }

  .notification-heading {
    position: absolute;
    top: calc(16 * var(--vw-mob));
    left: calc(24 * var(--vw-mob));
    font-size: calc(18 * var(--vw-mob));
    font-weight: 600;
    color: rgba(46, 46, 46, 1);
  }

  .notifications-holder {
    position: absolute;
    height: 90%;
    width: 96%;
    top: calc(50 * var(--vw-mob));
    left: 2%;
    background-color: white;
    overflow: scroll;
  }

  .empty-holder-notifications {
    width: 100%;
    padding-top: calc(80 * var(--vw-mob));
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: calc(30 * var(--vw-mob));
  }
  .empty-notifications {
    height: calc(250 * var(--vw-mob));
  }

  .notification-card {
    height: calc(54 * var(--vw-mob));
    width: 92%;
    margin-left: 4%;
    margin-top: calc(20 * var(--vw-mob));
    border-radius: calc(4 * var(--vw-mob));
    padding: 0;
    display: flex;
    align-items: center;
  }

  .notification-card img {
    height: calc(6 * var(--vw-mob));
    margin-left: calc(1 * var(--vw-mob));
    margin-right: calc(6 * var(--vw-mob));
  }

  .notification-card p {
    height: calc(28 * var(--vw-mob));
    width: calc(2000 * var(--vw-mob));
    margin-right: calc(5 * var(--vw-mob));
    font-size: calc(10 * var(--vw-mob));
    color: black;
    font-weight: 500;
    display: flex;
    align-items: center;
  }

  .notification-card span {
    width: calc(200 * var(--vw-mob));
    font-weight: 400;
    font-size: calc(6 * var(--vw-mob));
    color: rgba(46, 46, 46, 1);
  }

  .notification-card button {
    visibility: hidden;
    height: calc(30 * var(--vw-mob));
    width: calc(72 * var(--vw-mob));
    border: calc(0.5 * var(--vw-mob)) solid rgba(7, 51, 108, 1);
    border-radius: calc(4 * var(--vw-mob));
    background-color: rgba(7, 51, 108, 1);
    color: white;
    font-size: calc(12 * var(--vw-mob));
  }

  .bid-accepted-pop,
  .bid-rejected-pop {
    position: absolute;
    top: calc(168 * var(--vw-mob));
    left: calc(10 * var(--vw-mob));
    height: calc(290 * var(--vw-mob));
    width: calc(360 * var(--vw-mob));
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: calc(8 * var(--vw-mob));
    border: calc(2 * var(--vw-mob)) solid rgba(7, 51, 108, 0.2);
  }

  .congrats {
    font-weight: 800;
    font-size: calc(16 * var(--vw-mob));
    text-align: center;
    color: rgba(7, 51, 108, 1);
    margin-top: calc(25 * var(--vw-mob));
    margin-bottom: calc(25 * var(--vw-mob));
  }

  .bid-accept-info {
    font-weight: 600;
    font-size: calc(16 * var(--vw-mob));
    text-align: center;
    margin-bottom: calc(25 * var(--vw-mob));
    margin-left: calc(10 * var(--vw-mob));
  }

  .bid-accept-details {
    font-weight: 400;
    font-size: calc(12 * var(--vw-mob));
    color: rgba(125, 125, 125, 1);
    text-align: center;
  }

  .bid-accepted-pop .mail-link {
    font-weight: 400;
    font-size: calc(12 * var(--vw-mob));
    text-align: center;
  }

  .bid-accept-pop-close,
  .bid-reject-pop-close {
    height: calc(36 * var(--vw-mob));
    width: calc(76 * var(--vw-mob));
    border: none;
    border-radius: calc(4 * var(--vw-mob));
    background-color: rgba(7, 51, 108, 1);
    color: white;
    font-size: calc(13 * var(--vw-mob));
    font-weight: 500;
    margin-top: calc(30 * var(--vw-mob));
  }

  .bid-result-close {
    position: absolute;
    top: calc(180 * var(--vw-mob));
    left: calc(680 * var(--vw-mob));
    font-weight: 800;
    font-size: calc(20 * var(--vw-mob));
    cursor: pointer;
    z-index: 100;
  }

  .bid-rejected-pop {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: calc(30 * var(--vw-mob));
    height: calc(200 * var(--vw-mob));
  }

  .bid-rejected-pop p {
    font-weight: 600;
    font-size: calc(18 * var(--vw-mob));
    text-align: center;
  }

  .editpost-holder-notification {
    position: fixed;
    top: calc(200 * var(--vw-mob));
    left: 2%;
    max-height: calc(500 * var(--vw-mob));
    height: 60vh;
    width: 96%;
    border-radius: calc(8 * var(--vw-mob));
    box-shadow: calc(500 * var(--vw-mob)) calc(500 * var(--vw-mob))
      calc(500 * var(--vw-mob)) calc(500 * var(--vw-mob)) rgba(80, 80, 80, 0.2);
    background-color: white;
    padding: 1%;
    z-index: 50;
    overflow: scroll;
  }
}
