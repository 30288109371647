#pagecontent-getposts *::-webkit-scrollbar {
  width: 0;
  height: 0;
  color: transparent;
}

@media (min-width: 1200px) {
  #pagecontent-getposts {
    height: 100%;
    width: 100%;
    background: transparent;
  }

  .get-posts-heading {
    position: absolute;
    top: calc(16 * var(--vw-unit));
    left: calc(16 * var(--vw-unit));
    font-size: calc(18 * var(--vw-unit));
    font-weight: 600;
    color: rgba(46, 46, 46, 1);
  }

  .get-posts-body {
    position: absolute;
    top: 10%;
    left: 2%;
    height: 90%;
    width: 96%;
    background-color: white;
    padding: calc(10 * var(--vw-unit));
    overflow: scroll;
  }

  .container {
    margin: 0;
    padding: 0;
  }

  .row-mb-3 {
    width: 100%;
  }

  .col-md-2 {
    width: 30%;
  }

  .col-md-2 label {
    font-size: calc(14 * var(--vw-unit));
    font-weight: 700;
    color: rgba(32, 34, 36, 1);
  }

  .col-md-2 input,
  .col-md-2 select {
    height: 50%;
    color: black;
    font-size: calc(13 * var(--vw-unit));
    margin-bottom: calc(20 * var(--vw-unit));
  }

  .col-md-2 input:focus {
    outline: calc(3 * var(--vw-unit)) solid rgba(220, 249, 245, 1);
  }

  .low-icon {
    position: absolute;
    top: calc(55 * var(--vw-unit));
    left: calc(290 * var(--vw-unit));
    height: calc(10 * var(--vw-unit));
    visibility: hidden;
  }

  .table-responsive {
    overflow-x: auto;
  }

  .table {
    width: 100%;
    border-collapse: collapse;
  }

  .table th,
  .table td {
    padding: 8px;
    border-bottom: 1px solid #ddd;
  }

  .table th {
    background-color: rgb(13, 71, 161, 1);
    color: white;
    text-align: left;
    font-weight: bold;
    font-size: calc(14 * var(--vw-unit));
    font-weight: 500;
  }

  .table tr,
  .table td {
    background-color: rgba(241, 255, 252, 1);
    font-size: calc(14 * var(--vw-unit));
    font-weight: 500;
  }

  .table tbody tr:hover {
    background-color: rgba(241, 255, 252, 1);
    cursor: pointer;
  }

  .dataholder {
    width: 95%;
    height: auto;
    display: flex;
    flex-direction: row;
    overflow: scroll;
  }

  .tableholder {
    width: 100%;
    overflow: scroll;
  }

  .otherdetails {
    height: auto;
  }

  .otherdetails-holder-posts {
    position: fixed;
    left: calc(400 * var(--vw-unit));
    top: calc(170 * var(--vw-unit));
    height: 60%;
    max-height: calc(420 * var(--vw-unit));
    width: 50%;
    padding: calc(25 * var(--vw-unit));
    padding-top: calc(80 * var(--vw-unit));
    overflow: scroll;
    border-radius: calc(4 * var(--vw-unit));
    background-color: white;
    box-shadow: calc(200 * var(--vw-unit)) calc(900 * var(--vw-unit))
      calc(2000000 * var(--vw-unit)) calc(2000000 * var(--vw-unit))
      rgb(13, 71, 161, 0.6);
    overflow: scroll;
    display: flex;
    flex-direction: column;
  }

  .details-scroll-getpost {
    position: relative;
    top: 10%;
    height: 90%;
    width: 100%;
    overflow: scroll;
    padding: calc(10 * var(--vw-unit));
    background-color: #fafafa;
  }

  .other-details-value {
    font-size: calc(16 * var(--vw-unit));
    font-weight: 600;
    color: black;
    margin-bottom: calc(3 * var(--vw-unit));
  }

  .other-details-value span {
    font-size: calc(15 * var(--vw-unit));
    font-weight: 500;
    color: rgb(13, 71, 161, 1);
    margin-left: calc(12 * var(--vw-unit));
  }

  #selected-post-cancel {
    position: fixed;
    top: calc(180 * var(--vw-unit));
    left: calc(1060 * var(--vw-unit));
    height: calc(30 * var(--vw-unit));
    cursor: pointer;
  }

  .bid-heading-gp {
    position: fixed;
    top: calc(175 * var(--vw-unit));
    left: calc(425 * var(--vw-unit));
    width: 47%;
    height: calc(40 * var(--vw-unit));
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(16 * var(--vw-unit));
    font-weight: 600;
    color: black;
    border-bottom: calc(1 * var(--vw-unit)) solid #ccc;
  }

  .bid-btn-holder {
    position: fixed;
    left: calc(425 * var(--vw-unit));
    top: calc(285 * var(--vw-unit));
    display: flex;
    gap: calc(20 * var(--vw-unit));
  }

  .bid-button {
    position: fixed;
    left: calc(425 * var(--vw-unit));
    top: calc(285 * var(--vw-unit));
    height: calc(30 * var(--vw-unit));
    width: calc(100 * var(--vw-unit));
    margin-top: calc(3 * var(--vw-unit));
    background-color: rgba(5, 66, 106, 1);
    border: none;
    color: white;
    font-size: calc(16 * var(--vw-unit));
    font-weight: 600;
    border-radius: calc(4 * var(--vw-unit));
  }

  .share-button {
    position: fixed;
    left: calc(560 * var(--vw-unit));
    top: calc(225 * var(--vw-unit));
    height: calc(44 * var(--vw-unit));
    width: calc(100 * var(--vw-unit));
    margin-top: calc(3 * var(--vw-unit));
    background-color: rgba(5, 66, 106, 1);
    border: calc(1 * var(--vw-unit)) solid rgb(13, 71, 161, 1);
    color: white;
    font-size: calc(16 * var(--vw-unit));
    font-weight: 600;
    border-radius: calc(4 * var(--vw-unit));
    display: flex;
    align-items: center;
    justify-content: center;
    gap: calc(8 * var(--vw-unit));
  }

  .share-button img,
  .bid-button img,
  .message-button-gp img {
    height: 60%;
  }

  .message-button-gp {
    position: fixed;
    left: calc(700 * var(--vw-unit));
    top: calc(225 * var(--vw-unit));
    height: calc(44 * var(--vw-unit));
    width: calc(120 * var(--vw-unit));
    margin-top: calc(3 * var(--vw-unit));
    background-color: white;
    border: calc(1 * var(--vw-unit)) solid rgb(13, 71, 161, 1);
    color: rgb(13, 71, 161, 1);
    font-size: calc(16 * var(--vw-unit));
    font-weight: 600;
    border-radius: calc(4 * var(--vw-unit));
    display: flex;
    align-items: center;
    justify-content: center;
    gap: calc(8 * var(--vw-unit));
  }

  .custom-view-button-gp {
    position: fixed;
    left: calc(850 * var(--vw-unit));
    top: calc(225 * var(--vw-unit));
    height: calc(44 * var(--vw-unit));
    width: calc(120 * var(--vw-unit));
    margin-top: calc(3 * var(--vw-unit));
    background-color: white;
    border: calc(1 * var(--vw-unit)) solid rgb(13, 71, 161, 1);
    color: rgb(13, 71, 161, 1);
    font-size: calc(16 * var(--vw-unit));
    font-weight: 600;
    border-radius: calc(4 * var(--vw-unit));
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bid-popup {
    position: relative;
    top: 10%;
  }

  .bid-popup span {
    color: red;
    font-size: calc(16 * var(--vw-unit));
    font-weight: 600;
  }

  .bid-popup form {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .bid-popup form input {
    height: calc(30 * var(--vw-unit));
    width: calc(200 * var(--vw-unit));
    color: black;
    border: calc(1 * var(--vw-unit)) solid rgb(13, 71, 161, 0.4);
    margin-bottom: calc(1 * var(--vw-unit));
    padding-left: calc(10 * var(--vw-unit));
    font-size: calc(12 * var(--vw-unit));
  }

  .bid-button {
    position: fixed;
    left: calc(425 * var(--vw-unit));
    top: calc(225 * var(--vw-unit));
    height: calc(44 * var(--vw-unit));
    width: calc(84 * var(--vw-unit));
    margin-top: calc(3 * var(--vw-unit));
    background-color: #056a4c;
    border: none;
    color: white;
    font-size: calc(16 * var(--vw-unit));
    font-weight: 600;
    border-radius: calc(4 * var(--vw-unit));
    display: flex;
    align-items: center;
    justify-content: center;
    gap: calc(10 * var(--vw-unit));
  }

  .submit-bid-btn {
    height: calc(35 * var(--vw-unit));
    width: calc(200 * var(--vw-unit));
    background-color: rgb(13, 71, 161, 1);
    border: none;
    color: white;
    font-size: calc(16 * var(--vw-unit));
    font-weight: 600;
  }

  .message-input-holder {
    position: relative;
    margin: calc(10 * var(--vw-unit)) 0;
    padding: 0;
    top: 10%;
    display: flex;
    height: calc(40 * var(--vw-unit));
    width: 100%;
    border: calc(1 * var(--vw-unit)) solid rgba(204, 204, 204, 1);
  }

  .message-input-holder input {
    width: 92%;
    padding: 0 calc(10 * var(--vw-unit));
    border: none;
    font-size: calc(16 * var(--vw-unit));
    font-weight: 500;
    overflow-x: hidden;
  }

  .message-input-holder input:focus {
    outline: calc(1 * var(--vw-unit)) solid rgba(41, 205, 178, 1);
  }

  .message-input-holder button {
    width: 8%;
    height: 100%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(5, 66, 106, 1);
  }

  .message-input-holder button img {
    height: 80%;
  }
}

@media (max-width: 550px) {
  #pagecontent-getposts {
    height: 100%;
    width: 100%;
  }

  .get-posts-heading {
    position: absolute;
    top: calc(16 * var(--vw-mob));
    left: calc(16 * var(--vw-mob));
    font-size: calc(16 * var(--vw-mob));
    font-weight: 600;
    color: rgba(46, 46, 46, 1);
  }

  .get-posts-body {
    position: absolute;
    top: 8%;
    left: 2%;
    height: 90%;
    width: 96%;
    background-color: white;
    padding: calc(10 * var(--vw-mob));
    overflow: scroll;
  }

  .col-md-2 {
    height: 60px;
  }

  .col-md-2 label {
    font-size: calc(14 * var(--vw-mob));
    font-weight: 700;
    color: rgba(32, 34, 36, 1);
  }

  .col-md-2 input,
  .col-md-2 select {
    height: 50%;
    font-size: calc(11 * var(--vw-mob));
  }

  .col-md-2 label {
    font-size: 0.8rem;
    color: black;
    margin-bottom: 1px;
  }

  .low-icon {
    position: absolute;
    top: calc(42 * var(--vw-mob));
    left: calc(340 * var(--vw-mob));
    height: calc(7 * var(--vw-mob));
  }

  .table-responsive {
    overflow-x: auto;
  }

  .table {
    font-size: 0.8rem;
    width: 100%;
    border-collapse: collapse;
  }

  .table th,
  .table td {
    padding: 8px;
    border-bottom: 1px solid #ddd;
  }

  .table th {
    background-color: rgb(13, 71, 161, 1);
    color: white;
    text-align: center;
    font-weight: 500;
    font-size: calc(10 * var(--vw-mob));
  }

  .table td {
    background-color: rgba(220, 249, 245, 1);
  }

  .table tbody tr:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }

  .selected-details {
    height: 100%;
    width: 100%;
    border: 1px solid #ccc;
    padding: 10px;
    background-color: #f9f9f9;
  }

  .selected-details h3 {
    margin-top: 0;
    color: black;
  }

  .selected-details p {
    margin: 5px 0;
  }

  .dataholder {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: row;
  }

  .tableholder {
    width: 100%;
    overflow: scroll;
  }

  .otherdetails-holder-posts {
    position: fixed;
    left: 5%;
    top: calc(100 * var(--vw-mob));
    height: 70%;
    max-height: calc(620 * var(--vw-mob));
    width: 90%;
    padding: calc(5 * var(--vw-mob));
    padding-top: calc(100 * var(--vw-mob));
    overflow: scroll;
    border-radius: calc(4 * var(--vw-mob));
    background-color: white;
    box-shadow: calc(2 * var(--vw-mob)) calc(2 * var(--vw-mob))
      calc(2 * var(--vw-mob)) calc(2 * var(--vw-mob)) rgb(13, 71, 161, 0.2);
    overflow: scroll;
    display: flex;
    flex-direction: column;
  }

  .bid-heading-gp {
    position: fixed;
    top: calc(105 * var(--vw-mob));
    left: calc(30 * var(--vw-mob));
    width: 85%;
    height: calc(40 * var(--vw-mob));
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(18 * var(--vw-mob));
    font-weight: 600;
    color: black;
    border-bottom: calc(1 * var(--vw-mob)) solid #ccc;
  }

  .details-scroll-getpost {
    position: relative;
    height: 100%;
    width: 100%;
    padding-left: calc(15 * var(--vw-mob));
    overflow: scroll;
  }

  .other-details-value {
    font-size: calc(16 * var(--vw-mob));
    font-weight: 600;
    color: black;
    margin-bottom: calc(3 * var(--vw-mob));
  }

  .other-details-value span {
    font-size: calc(16 * var(--vw-mob));
    font-weight: 500;
    color: rgb(13, 71, 161, 1);
  }

  #selected-post-cancel {
    position: fixed;
    top: calc(110 * var(--vw-mob));
    left: calc(330 * var(--vw-mob));
    height: calc(30 * var(--vw-mob));
  }

  .bid-popup {
    position: fixed;
    top: calc(180 * var(--vw-mob));
    left: calc(50 * var(--vw-mob));

    padding: calc(10 * var(--vw-mob));
    border-radius: calc(8 * var(--vw-mob));
    background: linear-gradient(
      to left,
      rgb(39, 171, 151),
      rgba(41, 205, 178, 1)
    );
    z-index: 20;
  }

  .bid-popup span {
    color: red;
    font-size: calc(16 * var(--vw-mob));
    font-weight: 600;
  }

  .bid-popup form {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: calc(10 * var(--vw-mob));
  }

  .bid-popup form input {
    height: calc(30 * var(--vw-mob));
    width: calc(200 * var(--vw-mob));
    color: black;
    border: calc(1 * var(--vw-mob)) solid rgb(13, 71, 161, 0.4);
    margin-bottom: calc(1 * var(--vw-mob));
    padding-left: calc(10 * var(--vw-mob));
    font-size: calc(12 * var(--vw-mob));
  }

  .bid-button {
    position: fixed;
    top: calc(150 * var(--vw-mob));
    left: calc(35 * var(--vw-mob));
    height: calc(30 * var(--vw-mob));
    width: calc(60 * var(--vw-mob));
    background-color: rgba(5, 106, 76, 1);
    border: none;
    color: white;
    font-size: calc(11 * var(--vw-mob));
    font-weight: 600;
    border-radius: calc(4 * var(--vw-mob));
    margin-top: calc(10 * var(--vw-mob));
    display: flex;
    align-items: center;
    justify-content: center;
    gap: calc(5 * var(--vw-mob));
  }

  .share-button {
    position: fixed;
    top: calc(150 * var(--vw-mob));
    left: calc(110 * var(--vw-mob));
    height: calc(30 * var(--vw-mob));
    width: calc(65 * var(--vw-mob));
    background-color: rgba(5, 66, 106, 1);
    border: calc(1 * var(--vw-mob)) solid rgb(13, 71, 161, 1);
    color: white;
    font-size: calc(11 * var(--vw-mob));
    font-weight: 600;
    border-radius: calc(4 * var(--vw-mob));
    margin-top: calc(10 * var(--vw-mob));
    display: flex;
    align-items: center;
    justify-content: center;
    gap: calc(5 * var(--vw-mob));
  }

  .share-button img,
  .bid-button img,
  .message-button-gp img {
    height: 50%;
  }

  .message-button-gp {
    position: fixed;
    left: calc(190 * var(--vw-mob));
    top: calc(150 * var(--vw-mob));
    height: calc(30 * var(--vw-mob));
    width: calc(78 * var(--vw-mob));
    margin-top: calc(10 * var(--vw-mob));
    background-color: white;
    border: calc(1 * var(--vw-mob)) solid rgb(13, 71, 161, 1);
    color: rgb(13, 71, 161, 1);
    font-size: calc(11 * var(--vw-mob));
    font-weight: 600;
    border-radius: calc(4 * var(--vw-mob));
    display: flex;
    align-items: center;
    justify-content: center;
    gap: calc(5 * var(--vw-mob));
  }

  .custom-view-button-gp {
    position: fixed;
    left: calc(280 * var(--vw-mob));
    top: calc(150 * var(--vw-mob));
    height: calc(30 * var(--vw-mob));
    width: calc(76 * var(--vw-mob));
    margin-top: calc(10 * var(--vw-mob));
    background-color: white;
    border: calc(1 * var(--vw-mob)) solid rgb(13, 71, 161, 1);
    color: rgb(13, 71, 161, 1);
    font-size: calc(11 * var(--vw-mob));
    font-weight: 600;
    border-radius: calc(4 * var(--vw-mob));
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .submit-bid-btn {
    height: calc(35 * var(--vw-mob));
    width: calc(200 * var(--vw-mob));
    background-color: rgb(13, 71, 161, 1);
    border: none;
    color: white;
    font-size: calc(16 * var(--vw-mob));
    font-weight: 600;
  }

  .message-input-holder {
    position: relative;
    margin: calc(5 * var(--vw-mob)) calc(0 * var(--vw-mob));
    padding: 0;
    top: 0;
    left: calc(10 * var(--vw-mob));
    display: flex;
    height: calc(40 * var(--vw-mob));
    width: 90%;
    border: calc(1 * var(--vw-mob)) solid rgba(204, 204, 204, 1);
  }

  .message-input-holder input {
    width: 85%;
    padding: 0 calc(10 * var(--vw-mob));
    border: none;
    font-size: calc(14 * var(--vw-mob));
    font-weight: 500;
    overflow-x: hidden;
  }

  .message-input-holder input:focus {
    outline: calc(1 * var(--vw-mob)) solid rgba(41, 205, 178, 1);
  }

  .message-input-holder button {
    width: 15%;
    height: 100%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(5, 66, 106, 1);
  }

  .message-input-holder button img {
    height: 80%;
  }
}

@media (min-width: 550px) and (max-width: 1200px) {
  #pagecontent-getposts {
    height: 90%;
    width: 90%;
    font-size: small;
  }

  .get-posts-heading {
    position: absolute;
    top: calc(16 * var(--vw-tab));
    left: calc(16 * var(--vw-tab));
    font-size: calc(16 * var(--vw-tab));
    font-weight: 600;
    color: rgba(46, 46, 46, 1);
  }

  .get-posts-body {
    position: absolute;
    top: 8%;
    left: 2%;
    height: 90%;
    width: 96%;
    background-color: white;
    padding: calc(10 * var(--vw-tab));
    overflow: scroll;
  }

  .container {
    margin: 0;
    padding: 0;
  }

  .col-md-2 {
    width: 50%;
  }

  .col-md-2 label {
    font-size: calc(14 * var(--vw-tab));
    font-weight: 700;
    color: rgba(32, 34, 36, 1);
    margin: 0;
    margin-top: calc(10 * var(--vw-tab));
  }

  .col-md-2 input,
  .col-md-2 select {
    height: 50%;
    width: 100%;
    color: black;
    font-size: small;
  }

  .low-icon {
    position: absolute;
    top: calc(52 * var(--vw-tab));
    left: calc(330 * var(--vw-tab));
    height: calc(8 * var(--vw-tab));
  }

  .table-responsive {
    overflow-x: auto;
  }

  .table {
    width: 100%;
    border-collapse: collapse;
  }

  .table th,
  .table td {
    padding: 8px;
    border-bottom: 1px solid #ddd;
  }

  .table th {
    background-color: rgb(13, 71, 161, 1);
    color: white;
    font-size: calc(14 * var(--vw-tab));
    text-align: left;
    font-weight: 500;
  }

  .table td {
    background-color: rgba(220, 249, 245, 1);
    font-size: calc(14 * var(--vw-tab));
    font-weight: 500;
  }

  .table tbody tr:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }

  .dataholder {
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: row;
    overflow: scroll;
  }

  .tableholder {
    width: 100%;
    overflow: scroll;
  }

  .otherdetails {
    height: auto;
    overflow: scroll;
  }

  .otherdetails-holder-posts {
    position: fixed;
    left: calc(40 * var(--vw-tab));
    top: calc(100 * var(--vw-tab));
    height: 70%;
    width: 80%;
    padding: calc(5 * var(--vw-tab));
    padding-left: calc(15 * var(--vw-tab));
    padding-top: calc(90 * var(--vw-tab));
    overflow: scroll;
    border-radius: calc(4 * var(--vw-tab));
    background-color: white;
    box-shadow: calc(200 * var(--vw-unit)) calc(900 * var(--vw-unit))
      calc(2000000 * var(--vw-unit)) calc(2000000 * var(--vw-unit))
      rgb(13, 71, 161, 0.6);
    overflow: scroll;
    display: flex;
    flex-direction: column;
  }

  .bid-heading-gp {
    position: fixed;
    top: calc(125 * var(--vw-tab));
    left: calc(50 * var(--vw-tab));
    width: 75%;
    height: calc(40 * var(--vw-tab));
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(18 * var(--vw-tab));
    font-weight: 600;
    color: black;
    border-bottom: calc(1 * var(--vw-tab)) solid #ccc;
  }

  .details-scroll-getpost {
    position: relative;
    top: 10%;
    left: 3%;
    height: 90%;
    width: 90%;
    padding-left: calc(15 * var(--vw-mob));
    overflow: scroll;
    background-color: #fafafa;
  }

  .other-details-value {
    font-size: calc(16 * var(--vw-tab));
    font-weight: 600;
    color: black;
    margin-bottom: calc(3 * var(--vw-tab));
  }

  .other-details-value span {
    font-size: calc(16 * var(--vw-tab));
    font-weight: 500;
    color: rgb(13, 71, 161, 1);
    margin-left: calc(12 * var(--vw-tab));
  }

  #selected-post-cancel {
    position: fixed;
    top: calc(120 * var(--vw-tab));
    left: calc(570 * var(--vw-tab));
    height: calc(35 * var(--vw-tab));
    margin: 2%;
    margin-top: 0;
  }

  .bid-popup {
    position: fixed;
    top: calc(180 * var(--vw-tab));
    left: calc(50 * var(--vw-tab));
    height: auto;
    width: auto;
    padding: calc(10 * var(--vw-tab));
    border-radius: calc(8 * var(--vw-tab));
    background: linear-gradient(
      to left,
      rgb(39, 171, 151),
      rgba(41, 205, 178, 1)
    );
    z-index: 20;
  }

  .bid-popup span {
    color: red;
    font-size: calc(16 * var(--vw-tab));
    font-weight: 600;
    margin-bottom: calc(10 * var(--vw-tab));
  }

  .bid-popup form {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: calc(10 * var(--vw-tab));
  }

  .bid-popup form input {
    height: calc(30 * var(--vw-tab));
    width: calc(200 * var(--vw-tab));
    color: black;
    border: calc(1 * var(--vw-tab)) solid rgb(13, 71, 161, 0.4);
    margin-bottom: calc(1 * var(--vw-tab));
    padding-left: calc(10 * var(--vw-tab));
    font-size: calc(12 * var(--vw-tab));
  }

  .bid-button {
    position: fixed;
    left: calc(75 * var(--vw-tab));
    top: calc(120 * var(--vw-tab));
    height: calc(40 * var(--vw-tab));
    width: calc(100 * var(--vw-tab));
    background-color: rgb(13, 71, 161, 1);
    border: none;
    color: white;
    font-size: calc(16 * var(--vw-tab));
    font-weight: 600;
    border-radius: calc(4 * var(--vw-tab));
    margin-top: calc(10 * var(--vw-tab));
  }

  .bid-btn-holder {
    display: flex;
  }

  .bid-button {
    position: fixed;
    left: calc(75 * var(--vw-tab));
    top: calc(170 * var(--vw-tab));
    height: calc(40 * var(--vw-tab));
    width: calc(100 * var(--vw-tab));
    background-color: rgba(5, 106, 76, 1);
    border: none;
    color: white;
    font-size: calc(16 * var(--vw-tab));
    font-weight: 600;
    border-radius: calc(4 * var(--vw-tab));
    margin-top: calc(10 * var(--vw-tab));
    display: flex;
    align-items: center;
    justify-content: center;
    gap: calc(8 * var(--vw-tab));
  }

  .share-button {
    position: fixed;
    left: calc(200 * var(--vw-tab));
    top: calc(170 * var(--vw-tab));
    height: calc(40 * var(--vw-tab));
    width: calc(100 * var(--vw-tab));
    background-color: rgba(5, 66, 106, 1);
    border: calc(1 * var(--vw-tab)) solid rgb(13, 71, 161, 1);
    color: white;
    font-size: calc(16 * var(--vw-tab));
    font-weight: 600;
    border-radius: calc(4 * var(--vw-tab));
    margin-top: calc(10 * var(--vw-tab));
    display: flex;
    align-items: center;
    justify-content: center;
    gap: calc(8 * var(--vw-tab));
  }

  .share-button img,
  .bid-button img,
  .message-button-gp img {
    height: 50%;
  }

  .message-button-gp {
    position: fixed;
    left: calc(320 * var(--vw-tab));
    top: calc(175 * var(--vw-tab));
    height: calc(44 * var(--vw-tab));
    width: calc(120 * var(--vw-tab));
    margin-top: calc(3 * var(--vw-tab));
    background-color: white;
    border: calc(1 * var(--vw-tab)) solid rgb(13, 71, 161, 1);
    color: rgb(13, 71, 161, 1);
    font-size: calc(16 * var(--vw-tab));
    font-weight: 600;
    border-radius: calc(4 * var(--vw-tab));
    display: flex;
    align-items: center;
    justify-content: center;
    gap: calc(8 * var(--vw-tab));
  }

  .custom-view-button-gp {
    position: fixed;
    left: calc(460 * var(--vw-tab));
    top: calc(175 * var(--vw-tab));
    height: calc(44 * var(--vw-tab));
    width: calc(120 * var(--vw-tab));
    margin-top: calc(3 * var(--vw-tab));
    background-color: white;
    border: calc(1 * var(--vw-tab)) solid rgb(13, 71, 161, 1);
    color: rgb(13, 71, 161, 1);
    font-size: calc(16 * var(--vw-tab));
    font-weight: 600;
    border-radius: calc(4 * var(--vw-tab));
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .submit-bid-btn {
    height: calc(35 * var(--vw-tab));
    width: calc(200 * var(--vw-tab));
    background-color: rgb(13, 71, 161, 1);
    border: none;
    color: white;
    font-size: calc(16 * var(--vw-tab));
    font-weight: 600;
  }

  .message-input-holder {
    position: relative;
    margin: calc(5 * var(--vw-tab)) calc(20 * var(--vw-tab));
    padding: 0;
    top: 10%;
    display: flex;
    height: calc(40 * var(--vw-tab));
    width: 90%;
    border: calc(1 * var(--vw-tab)) solid rgba(204, 204, 204, 1);
  }

  .message-input-holder input {
    width: 92%;
    padding: 0 calc(10 * var(--vw-tab));
    border: none;
    font-size: calc(16 * var(--vw-tab));
    font-weight: 500;
    overflow-x: hidden;
  }

  .message-input-holder input:focus {
    outline: calc(1 * var(--vw-tab)) solid rgba(41, 205, 178, 1);
  }

  .message-input-holder button {
    width: 8%;
    height: 100%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(5, 66, 106, 1);
  }

  .message-input-holder button img {
    height: 80%;
  }
}
