@media (min-width: 1200px) {
  .edit-post-container {
    min-height: 100%;
    width: 100%;
    max-width: calc(800 * var(--vw-unit));
    margin: 0 auto;
    padding: calc(20 * var(--vw-unit));
    padding-bottom: calc(40 * var(--vw-unit));
    background: #fff;
    border-radius: calc(8 * var(--vw-unit));
    box-shadow: 0 calc(2 * var(--vw-unit)) calc(10 * var(--vw-unit)) rgba(0, 0, 0, 0.1);
    overflow-y: auto;
  }

  .post-details {
    height: 100%;
  }

  .top-bar-ep {
    position: sticky;
    top: 0;
    left: 0;
    height: calc(40 * var(--vw-unit));
    display: flex;
    align-items: center;
    border-bottom: calc(0.5 * var(--vw-unit)) solid gray;
  }

  .mode-btn-ep {
    height: calc(37 * var(--vw-unit));
    width: calc(100 * var(--vw-unit));
    border: none;
    border-radius: calc(4 * var(--vw-unit));
    color: rgb(13, 71, 161, 1);
    background-color: white;
    font-size: calc(16 * var(--vw-unit));
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: calc(2 * var(--vw-unit));
    position: relative;
  }

  .info-icon-ep {
    height: calc(16 * var(--vw-unit));
  }

  #share-btn-ep {
    display: flex;
    align-items: center;
    gap: calc(10 * var(--vw-unit));
    padding-left: calc(12 * var(--vw-unit));
  }

  #share-btn-ep img {
    height: 60%;
  }

  .mode-btn-ep-active {
    height: calc(37 * var(--vw-unit));
    width: calc(100 * var(--vw-unit));
    border: none;
    border-radius: calc(4 * var(--vw-unit));
    background-color: white;
    color: rgba(41, 205, 178, 1);
    font-size: calc(16 * var(--vw-unit));
    font-weight: 600;
  }

  .details-container-ep,
  .bids-popup {
    height: calc(100% - 50 * var(--vw-unit));
    width: 100%;
    overflow-y: auto;
    margin-top: calc(25 * var(--vw-unit));
    padding: calc(0 * var(--vw-unit)) 0 calc(85 * var(--vw-unit)) 0;
  }

  .post-details p {
    margin: calc(8 * var(--vw-unit)) 0;
    font-size: calc(16 * var(--vw-unit));
    color: #333;
  }

  .post-details strong {
    font-weight: 600;
  }

  .edit-btn {
    display: inline-block;
    padding: calc(10 * var(--vw-unit)) calc(20 * var(--vw-unit));
    margin-top: calc(20 * var(--vw-unit));
    background-color: rgb(13, 71, 161, 1);
    font-size: calc(16 * var(--vw-unit));
    color: #fff;
    border: none;
    border-radius: calc(4 * var(--vw-unit));
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .edit-btn:hover {
    background-color: #0056b3;
  }

  .bids-popup h2 {
    font-size: calc(24 * var(--vw-unit));
    margin-bottom: calc(20 * var(--vw-unit));
    color: #333;
  }

  .bids-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: calc(20 * var(--vw-unit));
  }

  .bids-table th,
  .bids-table td {
    padding: calc(12 * var(--vw-unit)) calc(15 * var(--vw-unit));
    border: calc(1 * var(--vw-unit)) solid #ddd;
    text-align: left;
  }

  .bids-table th {
    background-color: rgb(13, 71, 161, 1);
    font-size: calc(16 * var(--vw-unit));
    font-weight: 600;
    color: #fff;
  }

  .bids-table td {
    background-color: rgba(241, 255, 252, 1);
    font-size: calc(14 * var(--vw-unit));
  }

  .btn {
    padding: calc(8 * var(--vw-unit)) calc(16 * var(--vw-unit));
    border: none;
    border-radius: calc(4 * var(--vw-unit));
    cursor: pointer;
    font-size: calc(14 * var(--vw-unit));
  }

  .accept-btn {
    background-color: #28a745;
    color: #fff;
    margin-right: calc(10 * var(--vw-unit));
  }

  .accept-btn:hover {
    background-color: #218838;
  }

  .reject-btn {
    background-color: #dc3545;
    color: #fff;
  }

  .reject-btn:hover {
    background-color: #c82333;
  }

  #loader {
    display: block;
    margin: calc(50 * var(--vw-unit)) auto;
  }

  .toggle-container-ep {
    position: relative;
    left: calc(280 * var(--vw-unit));
    display: flex;
    flex-direction: row-reverse;
    width: calc(60 * var(--vw-unit));
    height: calc(34 * var(--vw-unit));
  }

  .toggle-checkbox {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .toggle-label {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: calc(30 * var(--vw-unit));
    width: calc(60 * var(--vw-unit));
    background-color: red;
    border-radius: calc(30 * var(--vw-unit));
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .toggle-label::before {
    position: absolute;
    content: '';
    height: calc(25 * var(--vw-unit));
    width: calc(25 * var(--vw-unit));
    left: calc(0 * var(--vw-unit));
    bottom: calc(2.5 * var(--vw-unit));
    background-color: white;
    border-radius: 50%;
    transition: transform 0.3s ease;
  }

  .toggle-checkbox:checked+.toggle-label {
    background-color: #4caf50;
  }

  .toggle-checkbox:checked+.toggle-label::before {
    transform: translateX(calc(34 * var(--vw-unit)));
  }

  .toggle-container-ep p {
    position: relative;
    right: calc(80 * var(--vw-unit));
    font-size: calc(15 * var(--vw-unit));
    font-weight: 600;
    align-self: center;
  }
}

@media (max-width: 550px) {
  .edit-post-container {
    height: 100%;
    width: 100%;
    max-width: calc(800 * var(--var-mob));
    margin: 0 auto;
    padding: 0;
    padding-bottom: 0;
    background: #fff;
    border-radius: calc(8 * var(--var-mob));
    box-shadow: 0 calc(2 * var(--var-mob)) calc(10 * var(--var-mob)) rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  }

  .tabs {
    display: flex;
    flex-direction: column;
    border-bottom: calc(1 * var(--var-mob)) solid #ddd;
    margin-bottom: calc(20 * var(--var-mob));
  }

  .tab {
    padding: calc(10 * var(--var-mob)) calc(20 * var(--var-mob));
    cursor: pointer;
    font-weight: 600;
    border-bottom: calc(3 * var(--var-mob)) solid transparent;
  }

  .tab.active {
    border-bottom-color: #007bff;
    color: #007bff;
  }

  .tab-content {
    display: none;
  }

  .tab-content.active {
    display: block;
  }

  .post-details {
    height: 100%;
  }

  .top-bar-ep {
    position: sticky;
    top: 0;
    left: 0;
    height: calc(40 * var(--vw-mob));
    display: flex;
    align-items: center;
    border-bottom: calc(0.5 * var(--vw-mob)) solid gray;
  }

  .mode-btn-ep {
    height: calc(37 * var(--vw-mob));
    width: calc(75 * var(--vw-mob));
    border: none;
    border-radius: calc(4 * var(--vw-mob));
    color: rgb(13, 71, 161, 1);
    background-color: white;
    font-size: calc(10 * var(--vw-mob));
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: calc(2 * var(--vw-mob));
    position: relative;
  }

  .info-icon-ep {
    height: calc(10 * var(--vw-mob));
  }

  .mode-btn-ep-active {
    height: calc(37 * var(--vw-mob));
    width: calc(70 * var(--vw-mob));
    border: none;
    border-radius: calc(4 * var(--vw-mob));
    background-color: white;
    color: rgba(41, 205, 178, 1);
    font-size: calc(12 * var(--vw-mob));
    font-weight: 600;
  }

  #share-btn-ep {
    display: flex;
    align-items: center;
    gap: calc(10 * var(--vw-mob));
    padding-left: calc(12 * var(--vw-mob));
  }

  #share-btn-ep img {
    height: 40%;
  }

  .details-container-ep,
  .bids-popup {
    height: calc(100% - 50 * var(--vw-mob));
    width: 100%;
    overflow-y: auto;
    margin: 0;
    margin-top: calc(25 * var(--vw-mob));
    padding: calc(0 * var(--vw-mob)) 0 calc(85 * var(--vw-mob)) 0;
  }

  .post-details p {
    margin: calc(8 * var(--var-mob));
    margin-left: calc(10 * var(--vw-mob));
    margin-right: calc(10 * var(--vw-mob));
    font-size: calc(8 * var(--var-mob));
    color: rgb(13, 71, 161, 1);
    line-height: calc(25 * var(--vw-mob));
  }

  .post-details span {
    margin: 0;
    font-size: calc(10 * var(--vw-mob));
    color: rgb(13, 71, 161, 1);
  }

  .post-details strong {
    font-size: calc(11 * var(--vw-mob));
    line-height: calc(1 * var(--vw-mob));
    font-weight: 600;
    color: black;
  }

  .edit-btn {
    display: inline-block;
    height: calc(30 * var(--vw-mob));
    width: calc(70 * var(--vw-mob));
    padding: calc(10 * var(--var-mob)) calc(20 * var(--var-mob));
    margin-top: calc(30 * var(--vw-mob));
    margin-left: calc(7 * var(--vw-mob));
    margin-bottom: calc(50 * var(--vw-mob));
    background-color: rgb(13, 71, 161, 1);
    color: #fff;
    border: none;
    border-radius: calc(4 * var(--vw-mob));
    font-size: calc(12 * var(--vw-mob));
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .edit-btn:hover {
    background-color: #0056b3;
  }

  .bids-popup h2 {
    font-size: calc(24 * var(--var-mob));
    margin-bottom: calc(20 * var(--var-mob));
    padding-left: 2%;
    color: #333;
  }

  .bids-table {
    width: 96%;
    margin-left: 2%;
    border-collapse: collapse;
    margin-top: calc(10 * var(--var-mob));
    margin-bottom: calc(20 * var(--var-mob));
  }

  .bids-table th,
  .bids-table td {
    padding: calc(12 * var(--var-mob)) calc(15 * var(--var-mob));
    border: calc(1 * var(--var-mob)) solid #ddd;
    text-align: left;
  }

  .bids-table th {
    font-size: calc(12 * var(--vw-mob));
    font-weight: 500;
    background-color: #003366;
    color: #fff;
    text-align: center;
  }

  .bids-table td {
    padding: calc(5 * var(--vw-mob));
    font-size: calc(11 * var(--vw-mob));
    text-align: center;
    background-color: #f2f2f2;
  }

  .btn {
    padding: calc(8 * var(--var-mob)) calc(16 * var(--var-mob));
    border: none;
    border-radius: calc(4 * var(--var-mob));
    cursor: pointer;
    font-size: calc(10 * var(--var-mob));
  }

  .accept-btn {
    background-color: #28a745;
    color: #fff;
    height: calc(18 * var(--vw-mob));
    width: calc(50 * var(--vw-mob));
    margin-right: calc(30 * var(--var-mob));
    font-size: calc(8 * var(--vw-mob));
  }

  .accept-btn:hover {
    background-color: #218838;
  }

  .reject-btn {
    height: calc(18 * var(--vw-mob));
    width: calc(50 * var(--vw-mob));
    font-size: calc(8 * var(--vw-mob));
    margin-left: calc(5 * var(--vw-mob));
    background-color: #dc3545;
    color: #fff;
  }

  .reject-btn:hover {
    background-color: #c82333;
  }

  #loader {
    display: block;
    margin: calc(50 * var(--var-mob)) auto;
  }

  .toggle-container-ep {
    position: relative;
    left: calc(26 * var(--vw-mob));
    top: calc(0 * var(--vw-mob));
    display: flex;
    flex-direction: row-reverse;
    width: calc(60 * var(--vw-mob));
    height: calc(30 * var(--vw-mob));
  }

  .toggle-container-ep p {
    visibility: hidden;
    position: relative;
    right: calc(60 * var(--vw-mob));
    font-size: calc(11 * var(--vw-mob));
    font-weight: 600;
    align-self: center;
  }
}

@media (min-width: 550px) and (max-width: 1200px) {
  .edit-post-container {
    min-height: 100%;
    width: 100%;
    max-width: calc(800 * var(--vw-tab));
    padding: calc(20 * var(--vw-tab));
    padding-bottom: calc(40 * var(--vw-tab));
    background: #fff;
    border-radius: calc(8 * var(--vw-tab));
    box-shadow: 0 calc(2 * var(--vw-tab)) calc(10 * var(--vw-tab)) rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    overflow-x: hidden;
  }

  .post-details {
    height: 100%;
  }

  .tabs {
    display: flex;
    border-bottom: calc(1 * var(--vw-tab)) solid #ddd;
    margin-bottom: calc(20 * var(--vw-tab));
  }

  .tab {
    padding: calc(10 * var(--vw-tab)) calc(20 * var(--vw-tab));
    cursor: pointer;
    font-weight: 600;
    border-bottom: calc(3 * var(--vw-tab)) solid transparent;
  }

  .tab.active {
    border-bottom-color: #007bff;
    color: #007bff;
  }

  .tab-content {
    display: none;
  }

  .tab-content.active {
    display: block;
  }

  .top-bar-ep {
    position: sticky;
    top: 0;
    left: 0;
    height: calc(40 * var(--vw-tab));
    display: flex;
    align-items: center;
    border-bottom: calc(0.5 * var(--vw-tab)) solid gray;
  }

  .mode-btn-ep {
    height: calc(37 * var(--vw-tab));
    width: calc(80 * var(--vw-tab));
    border: none;
    border-radius: calc(4 * var(--vw-tab));
    color: rgb(13, 71, 161, 1);
    background-color: white;
    font-size: calc(12 * var(--vw-tab));
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: calc(1 * var(--vw-tab));
    position: relative;
  }

  .info-icon-ep {
    height: calc(12 * var(--vw-tab));
  }

  .mode-btn-ep-active {
    height: calc(37 * var(--vw-tab));
    width: calc(100 * var(--vw-tab));
    border: none;
    border-radius: calc(4 * var(--vw-tab));
    background-color: white;
    color: rgba(41, 205, 178, 1);
    font-size: calc(16 * var(--vw-tab));
    font-weight: 600;
  }

  #share-btn-ep {
    display: flex;
    align-items: center;
    gap: calc(10 * var(--vw-tab));
    padding-left: calc(12 * var(--vw-tab));
  }

  #share-btn-ep img {
    height: 60%;
  }

  .details-container-ep,
  .bids-popup {
    height: calc(100% - 50 * var(--vw-tab));
    width: 100%;
    overflow-y: auto;
    margin-top: calc(25 * var(--vw-tab));
    padding: calc(0 * var(--vw-tab)) 0 calc(85 * var(--vw-tab)) 0;
  }

  .post-details p {
    margin: calc(8 * var(--vw-tab)) 0;
    font-size: calc(16 * var(--vw-tab));
    color: #333;
  }

  .post-details strong {
    font-weight: 600;
  }

  .edit-btn {
    display: inline-block;
    padding: calc(10 * var(--vw-tab)) calc(20 * var(--vw-tab));
    margin-top: calc(20 * var(--vw-tab));
    background-color: rgb(13, 71, 161, 1);
    color: #fff;
    border: none;
    border-radius: calc(4 * var(--vw-tab));
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .edit-btn:hover {
    background-color: #0056b3;
  }

  .bids-popup h2 {
    font-size: calc(24 * var(--vw-tab));
    margin-bottom: calc(20 * var(--vw-tab));
    color: #333;
  }

  .bids-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: calc(20 * var(--vw-tab));
  }

  .bids-table th,
  .bids-table td {
    padding: calc(12 * var(--vw-tab)) calc(15 * var(--vw-tab));
    border: calc(1 * var(--vw-tab)) solid #ddd;
    text-align: left;
  }

  .bids-table th {
    background-color: #003366;
    color: #fff;
  }

  .bids-table td {
    background-color: #f2f2f2;
  }

  .btn {
    padding: calc(8 * var(--vw-tab)) calc(16 * var(--vw-tab));
    border: none;
    border-radius: calc(4 * var(--vw-tab));
    cursor: pointer;
    font-size: calc(14 * var(--vw-tab));
  }

  .accept-btn {
    background-color: #28a745;
    color: #fff;
    margin-right: calc(10 * var(--vw-tab));
  }

  .accept-btn:hover {
    background-color: #218838;
  }

  .reject-btn {
    background-color: #dc3545;
    color: #fff;
  }

  .reject-btn:hover {
    background-color: #c82333;
  }

  #loader {
    display: block;
    margin: calc(50 * var(--vw-tab)) auto;
  }

  .toggle-container-ep {
    position: relative;
    left: calc(128 * var(--vw-tab));
    display: flex;
    flex-direction: row-reverse;
    width: calc(100 * var(--vw-tab));
    height: calc(34 * var(--vw-tab));
  }

  .toggle-container-ep p {
    position: relative;
    right: calc(120 * var(--vw-tab));
    font-size: calc(18 * var(--vw-tab));
    font-weight: 600;
    align-self: center;
  }
}