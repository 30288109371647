.calculator-mortgage {
  position: fixed;
  top: 10vh;
  height: 88vh;
  width: 60%;
  left: 20%;
  margin: auto;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: scroll;
}

.mortgage-heading {
  text-align: center;
  color: rgba(7, 51, 108, 1);
  font-size: 25px;
  margin: 20px 0;
}

.input-group-mortgage {
  margin-bottom: 15px;
}

.input-group-mortgage label {
  display: block;
  margin-bottom: 5px;
}

.input-group-mortgage input,
.input-group-mortgage select {
  height: 40px;
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.button {
  background-color: rgba(7, 51, 108, 1);
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
}

.button:hover {
  transform: scale(1.02);
}

.result,
.tips {
  margin-top: 20px;
  padding: 15px;
  border-radius: 5px;
}
.admin-fee-section {
  width: 100%;
  color: rgba(7, 51, 108, 1);
  text-decoration: underline;
  margin: 2% 0 5% 0;
  cursor: pointer;
}

@media (min-width: 1000px) {
  .input-group-mortgage {
    width: 60%;
    margin-left: 20%;
  }
  .button {
    width: 60%;
    margin-left: 20%;
  }
}
@media (max-width: 550px) {
  .calculator-mortgage {
    width: 90%;
    left: 5%;
  }
  .input-group-mortgage {
    width: 100%;
  }
  .button {
    width: 60%;
    margin-left: 20%;
  }
}
