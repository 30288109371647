@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

:root {
  --vw-unit: calc((100 / 1440) * 1vw);
}

.pagecontent-buyerpost {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.buyer-post-heading {
  position: absolute;
  top: calc(16 * var(--vw-unit));
  left: calc(25 * var(--vw-unit));
  font-size: calc(18 * var(--vw-unit));
  font-weight: 600;
  color: rgba(46, 46, 46, 1);
}

.newlisting-body {
  position: absolute;
  top: calc(60 * var(--vw-unit));
  height: 87%;
  width: 96%;
  background-color: white;
  overflow: scroll;
  margin-left: 2%;
}

.header-container {
  height: calc(60 * var(--vw-unit));
  width: 96%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 2%;
  border-bottom: calc(1 * var(--vw-unit)) solid rgba(159, 175, 195, 1);
  overflow: hidden;
}

.header-container p {
  font-size: calc(16 * var(--vw-unit));
  font-weight: 600;
}

.toggle-container {
  position: relative;
  left: calc(1000 * var(--vw-unit));
  top: calc(18 * var(--vw-unit));
  display: flex;
  flex-direction: row-reverse;
  width: calc(60 * var(--vw-unit));
  height: calc(34 * var(--vw-unit));
}

.toggle-checkbox {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-label {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: calc(30 * var(--vw-unit));
  width: calc(60 * var(--vw-unit));
  background-color: red;
  border-radius: calc(30 * var(--vw-unit));
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle-label::before {
  position: absolute;
  content: '';
  height: calc(25 * var(--vw-unit));
  width: calc(25 * var(--vw-unit));
  left: calc(0 * var(--vw-unit));
  bottom: calc(2.5 * var(--vw-unit));
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.toggle-checkbox:checked + .toggle-label {
  background-color: #4caf50;
}

.toggle-checkbox:checked + .toggle-label::before {
  transform: translateX(calc(34 * var(--vw-unit)));
}

.toggle-container p {
  position: relative;
  right: calc(80 * var(--vw-unit));
  font-size: calc(16 * var(--vw-unit));
  align-self: center;
}

.buyer-post-container {
  height: 100%;
  width: 80%;
  margin-left: 20%;
  padding: 0;
  overflow-y: auto;
}

.buyer-post-container form {
  width: 60%;
  height: auto;
  margin: 0;
}

.elements::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

.elements::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.elements::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.elements::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.elements {
  width: 100%;
  height: 100%;
}

.form-group-newlisting {
  min-height: calc(70 * var(--vw-unit));
  width: 90%;
  margin-top: calc(9 * var(--vw-unit));
  padding: 0;
  padding-left: calc(10 * var(--vw-unit));
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form-group-newlisting label {
  font-size: calc(14 * var(--vw-unit));
  font-weight: 600;
  color: rgba(46, 46, 46, 1);
  overflow: hidden;
}

.form-group-newlisting input {
  height: calc(40 * var(--vw-unit));
  width: calc(400 * var(--vw-unit));
  border: calc(1 * var(--vw-unit)) solid rgba(5, 66, 106, 0.4);
  border-radius: calc(4 * var(--vw-unit));
  font-size: calc(14 * var(--vw-unit));
  color: black;
  padding-left: calc(10 * var(--vw-unit));
}

.form-group-newlisting select {
  height: calc(40 * var(--vw-unit));
  width: calc(400 * var(--vw-unit));
  border: calc(1 * var(--vw-unit)) solid rgba(5, 66, 106, 0.4);
  border-radius: calc(4 * var(--vw-unit));
  font-size: calc(14 * var(--vw-unit));
}

.form-group-newlisting textarea {
  height: calc(120 * var(--vw-unit));
  width: calc(500 * var(--vw-unit));
  padding: calc(8 * var(--vw-unit));
  margin-bottom: calc(10 * var(--vw-unit));
  border: calc(1 * var(--vw-unit)) solid rgba(5, 66, 106, 0.4);
  border-radius: calc(4 * var(--vw-unit));
  font-size: calc(14 * var(--vw-unit));
  font-weight: 500;
  color: black;
}

.form-group-newlisting input:focus,
.form-group-newlisting select:focus,
.form-group-newlisting textarea:focus {
  outline: calc(3 * var(--vw-unit)) solid rgba(229, 245, 249, 1);
}

.buttonholder-newlisting {
  margin-top: calc(40 * var(--vw-unit));
  min-height: calc(50 * var(--vw-unit));
  width: 100%;
}

.cancel-newlisting {
  height: calc(40 * var(--vw-unit));
  width: calc(102 * var(--vw-unit));
  border: calc(1 * var(--vw-unit)) solid rgba(5, 66, 106, 1);
  border-radius: calc(4 * var(--vw-unit));
  background: transparent;

  margin-left: calc(150 * var(--vw-unit));
  font-size: calc(16 * var(--vw-unit));
  font-weight: 500;
  color: rgba(5, 66, 106, 1);
}

.submit-newlisting {
  height: calc(40 * var(--vw-unit));
  width: calc(102 * var(--vw-unit));
  margin-left: calc(30 * var(--vw-unit));
  border: none;
  border-radius: calc(4 * var(--vw-unit));
  background-color: rgba(5, 66, 106, 1);
  color: white;
  font-size: calc(16 * var(--vw-unit));
  font-weight: 500;
}

@media (min-width: 550px) and (max-width: 1200px) {
  .pagecontent-buyerpost {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  .buyer-post-heading {
    position: absolute;
    top: calc(16 * var(--vw-tab));
    left: calc(25 * var(--vw-tab));
    font-size: calc(18 * var(--vw-tab));
    font-weight: 600;
    color: rgba(46, 46, 46, 1);
  }

  .newlisting-body {
    position: absolute;
    top: calc(60 * var(--vw-tab));
    height: 82vh;
    width: 96%;
    background-color: white;
    overflow: scroll;
    margin-left: 2%;
  }

  .header-container {
    height: calc(60 * var(--vw-tab));
    width: 96%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 2%;
    border-bottom: calc(1 * var(--vw-tab)) solid rgba(159, 175, 195, 1);
    overflow: hidden;
  }

  .header-container p {
    font-size: calc(16 * var(--vw-tab));
    font-weight: 600;
  }

  .toggle-container {
    position: relative;
    display: flex;
    left: calc(670 * var(--vw-tab));
    top: calc(18 * var(--vw-tab));
    flex-direction: row-reverse;
    width: calc(60 * var(--vw-tab));
    height: calc(34 * var(--vw-tab));
  }

  .toggle-checkbox {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .toggle-label {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: calc(30 * var(--vw-tab));
    width: calc(60 * var(--vw-tab));
    background-color: red;
    border-radius: calc(30 * var(--vw-tab));
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .toggle-label::before {
    position: absolute;
    content: '';
    height: calc(25 * var(--vw-tab));
    width: calc(25 * var(--vw-tab));
    left: calc(0 * var(--vw-tab));
    bottom: calc(2.5 * var(--vw-tab));
    background-color: white;
    border-radius: 50%;
    transition: transform 0.3s ease;
  }

  .toggle-checkbox:checked + .toggle-label {
    background-color: #4caf50;
  }

  .toggle-checkbox:checked + .toggle-label::before {
    transform: translateX(calc(34 * var(--vw-tab)));
  }

  .toggle-container p {
    position: relative;
    right: calc(80 * var(--vw-tab));
    font-size: 1rem;
    align-self: center;
  }

  .buyer-post-container {
    height: 99%;
    width: 80%;
    margin-left: 20%;
    overflow: scroll;
  }

  .buyer-post-container form {
    width: 100%;
    margin: 0;
    padding-top: calc(30 * var(--vw-unit));
  }

  .form-group-newlisting {
    min-height: calc(76 * var(--vw-tab));
    width: 100%;
    padding-left: calc(10 * var(--vw-tab));
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .form-group-newlisting label {
    width: 60%;
    font-size: calc(14 * var(--vw-tab));
    font-weight: 600;
    color: rgba(46, 46, 46, 1);
    overflow: hidden;
  }

  .form-group-newlisting input {
    height: calc(42 * var(--vw-tab));
    width: calc(400 * var(--vw-tab));
    border: calc(1 * var(--vw-tab)) solid rgba(5, 66, 106, 0.4);
    border-radius: calc(4 * var(--vw-tab));
    font-size: calc(14 * var(--vw-tab));
    color: black;
    padding-left: calc(10 * var(--vw-tab));
  }

  .form-group-newlisting select {
    height: calc(42 * var(--vw-tab));
    width: calc(400 * var(--vw-tab));
    border: calc(1 * var(--vw-tab)) solid rgba(5, 66, 106, 0.4);
    border-radius: calc(4 * var(--vw-tab));
    font-size: calc(14 * var(--vw-tab));
  }

  .form-group-newlisting textarea {
    height: calc(120 * var(--vw-tab));
    width: calc(500 * var(--vw-tab));
    margin-bottom: calc(10 * var(--vw-tab));
    border: calc(1 * var(--vw-tab)) solid rgba(5, 66, 106, 0.4);
    border-radius: calc(4 * var(--vw-tab));
    font-size: calc(14 * var(--vw-tab));
    font-weight: 500;
    color: black;
  }

  .form-group-newlisting input:focus,
  .form-group-newlisting select:focus,
  .form-group-newlisting textarea:focus {
    outline: calc(3 * var(--vw-tab)) solid rgba(229, 245, 249, 1);
  }

  .buttonholder-newlisting {
    min-height: calc(48 * var(--vw-tab));
    width: 100%;
    margin-top: calc(20 * var(--vw-tab));
    margin-bottom: calc(50 * var(--vw-tab));
  }

  .cancel-newlisting {
    height: calc(40 * var(--vw-tab));
    width: calc(102 * var(--vw-tab));
    border: calc(1 * var(--vw-tab)) solid rgba(5, 66, 106, 1);
    border-radius: calc(4 * var(--vw-tab));
    background: transparent;
    margin-left: calc(150 * var(--vw-tab));
    font-size: calc(16 * var(--vw-tab));
    font-weight: 500;
    color: rgba(5, 66, 106, 1);
  }

  .submit-newlisting {
    height: calc(40 * var(--vw-tab));
    width: calc(102 * var(--vw-tab));
    margin-left: calc(30 * var(--vw-tab));
    border: none;
    border-radius: calc(4 * var(--vw-tab));
    background-color: rgba(5, 66, 106, 1);
    color: white;
    font-size: calc(16 * var(--vw-tab));
    font-weight: 500;
  }
}

@media (max-width: 550px) {
  .pagecontent-buyerpost {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .buyer-post-heading {
    position: absolute;
    top: calc(16 * var(--vw-mob));
    left: calc(25 * var(--vw-mob));
    font-size: calc(18 * var(--vw-mob));
    font-weight: 600;
    color: rgba(46, 46, 46, 1);
  }

  .newlisting-body {
    position: absolute;
    top: calc(60 * var(--vw-mob));
    height: calc(680 * var(--vw-mob));
    width: 96%;
    background-color: white;
    overflow: scroll;
    margin-left: 2%;
  }

  .header-container {
    height: calc(60 * var(--vw-mob));
    width: 96%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 2%;
    border-bottom: calc(1 * var(--vw-mob)) solid rgba(159, 175, 195, 1);
    overflow: hidden;
  }

  .header-container p {
    font-size: calc(16 * var(--vw-mob));
    font-weight: 600;
  }

  .toggle-container {
    position: relative;
    display: flex;
    left: calc(310 * var(--vw-mob));
    top: calc(18 * var(--vw-mob));
    flex-direction: row-reverse;
    width: calc(60 * var(--vw-mob));
    height: calc(34 * var(--vw-mob));
  }

  .toggle-checkbox {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .toggle-label {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: calc(30 * var(--vw-mob));
    width: calc(60 * var(--vw-mob));
    background-color: red;
    border-radius: calc(30 * var(--vw-mob));
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .toggle-label::before {
    position: absolute;
    content: '';
    height: calc(25 * var(--vw-mob));
    width: calc(25 * var(--vw-mob));
    left: calc(0 * var(--vw-mob));
    bottom: calc(2.5 * var(--vw-mob));
    background-color: white;
    border-radius: 50%;
    transition: transform 0.3s ease;
  }

  .toggle-checkbox:checked + .toggle-label {
    background-color: #4caf50;
  }

  .toggle-checkbox:checked + .toggle-label::before {
    transform: translateX(calc(34 * var(--vw-mob)));
  }

  .toggle-container p {
    position: relative;
    right: calc(80 * var(--vw-mob));
    font-size: calc(20 * var(--vw-mob));
    align-self: center;
  }

  .buyer-post-container {
    height: calc(620 * var(--vw-mob));
    width: 100%;
    margin-left: 1%;
    padding-left: calc(15 * var(--vw-mob));
    margin-right: 0;
    overflow: scroll;
    overflow-x: hidden;
  }

  .buyer-post-container form {
    width: 120%;
    padding-right: 0;
    overflow: scroll;
    overflow-x: visible;
  }
  .elements {
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: visible;
  }

  .form-group-newlisting {
    min-height: calc(80 * var(--vw-mob));
    width: 100%;
    padding-left: calc(10 * var(--vw-mob));
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-y: visible;
  }

  .form-group-newlisting label {
    width: 60%;
    font-size: calc(14 * var(--vw-mob));
    font-weight: 600;
    color: rgba(46, 46, 46, 1);
    overflow: hidden;
  }

  .form-group-newlisting input {
    height: calc(40 * var(--vw-mob));
    width: calc(294 * var(--vw-mob));
    border: calc(1 * var(--vw-mob)) solid rgba(5, 66, 106, 0.4);
    border-radius: calc(4 * var(--vw-mob));
    font-size: calc(14 * var(--vw-mob));
    color: black;
    padding-left: calc(10 * var(--vw-mob));
  }

  .form-group-newlisting select {
    height: calc(40 * var(--vw-mob));
    width: calc(294 * var(--vw-mob));
    border: calc(1 * var(--vw-mob)) solid rgba(5, 66, 106, 0.4);
    border-radius: calc(4 * var(--vw-mob));
    font-size: calc(14 * var(--vw-mob));
  }

  .form-group-newlisting textarea {
    height: calc(120 * var(--vw-mob));
    width: calc(294 * var(--vw-mob));
    margin-bottom: calc(10 * var(--vw-mob));
    border: calc(1 * var(--vw-mob)) solid rgba(5, 66, 106, 0.4);
    border-radius: calc(4 * var(--vw-mob));
    font-size: calc(14 * var(--vw-mob));
    font-weight: 500;
    color: black;
  }

  .form-group-newlisting input:focus,
  .form-group-newlisting select:focus,
  .form-group-newlisting textarea:focus {
    outline: calc(3 * var(--vw-mob)) solid rgba(229, 245, 249, 1);
  }

  .buttonholder-newlisting {
    height: calc(150 * var(--vw-mob));
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    gap: calc(10 * var(--vw-mob));
    padding-left: calc(10 * var(--vw-mob));
    padding-bottom: calc(30 * var(--vw-mob));
    margin-top: calc(20 * var(--vw-mob));
  }

  .cancel-newlisting {
    height: calc(48 * var(--vw-mob));
    width: calc(294 * var(--vw-mob));
    border: calc(1 * var(--vw-mob)) solid rgba(5, 66, 106, 1);
    border-radius: calc(4 * var(--vw-mob));
    background: transparent;
    margin: 0;
    font-size: calc(16 * var(--vw-mob));
    font-weight: 500;
    color: rgba(5, 66, 106, 1);
  }

  .submit-newlisting {
    height: calc(48 * var(--vw-mob));
    width: calc(294 * var(--vw-mob));
    margin-left: calc(30 * var(--vw-mob));
    border: none;
    border-radius: calc(4 * var(--vw-mob));
    margin: 0;
    background-color: rgba(5, 66, 106, 1);
    color: white;
    font-size: calc(16 * var(--vw-mob));
    font-weight: 500;
  }
}
