@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

:root {
  --vw-unit: calc((100 / 1440) * 1vw);
  --vw-tab: calc((100 / 768) * 1vw);
  --vw-mob: calc((100 / 390) * 1vw);
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

p {
  padding: 0;
  margin: 0;
}

.support-page-holder {
  height: 100%;
  width: 100%;
  background-color: white;
}

.help-heading {
  position: absolute;
  top: calc(16 * var(--vw-unit));
  left: calc(25 * var(--vw-unit));
  font-size: calc(18 * var(--vw-unit));
  font-weight: 600;
  color: rgba(46, 46, 46, 1);
}

.support-body {
  height: calc(490 * var(--vw-unit));
  width: calc(1060 * var(--vw-unit));
  overflow: scroll;
}

.right-holder-s {
  height: calc(460 * var(--vw-unit));
  width: 96%;
  margin: 2%;
  border: calc(1 * var(--vw-unit)) solid rgba(5, 66, 106, 0.4);
  border-radius: calc(4 * var(--vw-unit));
  padding: calc(10 * var(--vw-unit));
}

.form-s {
  height: 96%;
  width: 96%;
}

.name-holder-s {
  height: calc(67 * var(--vw-unit));
  width: 100%;
  display: flex;
  margin-bottom: calc(10 * var(--vw-unit));
}

.name-details-s {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: calc(280 * var(--vw-unit));
  margin-right: calc(150 * var(--vw-unit));
}

.name-details-s p {
  font-size: calc(14 * var(--vw-unit));
  font-weight: 600;
  color: rgba(32, 34, 36, 1);
}

.name-details-s input {
  margin: 0;
  height: calc(42 * var(--vw-unit));
  width: calc(280 * var(--vw-unit));
  border: calc(1 * var(--vw-unit)) solid rgba(208, 208, 208, 1);
  padding-left: calc(10 * var(--vw-unit));
  color: black;
  font-size: calc(13 * var(--vw-unit));
}

.name-details-s input:focus {
  outline: calc(5 * var(--vw-unit)) solid rgba(229, 249, 245, 1);
}

.email-topic-holder-s {
  display: flex;
  margin-bottom: calc(15 * var(--vw-unit));
}

.email-holder-s,
.topic-holder-s {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: calc(280 * var(--vw-unit));
  margin-right: calc(150 * var(--vw-unit));
}

.email-holder-s p,
.topic-holder-s p {
  font-size: calc(14 * var(--vw-unit));
  font-weight: 600;
}

.email-holder-s input,
.topic-holder-s input {
  height: calc(42 * var(--vw-unit));
  width: calc(280 * var(--vw-unit));
  border: calc(1 * var(--vw-unit)) solid rgba(208, 208, 208, 1);
  padding-left: calc(10 * var(--vw-unit));
  font-size: calc(13 * var(--vw-unit));
}

.email-holder-s input:focus,
.topic-holder-s input:focus {
  outline: calc(5 * var(--vw-unit)) solid rgba(229, 249, 245, 1);
}

.message-holder-s {
  height: calc(144 * var(--vw-unit));
  width: 100%;
  margin-bottom: calc(10 * var(--vw-unit));
}

.message-holder-s p {
  font-size: calc(14 * var(--vw-unit));
  font-weight: 600;
  color: rgba(46, 46, 46, 1);
}

.message-holder-s textarea {
  height: calc(119 * var(--vw-unit));
  width: calc(584 * var(--vw-unit));
  border: calc(1 * var(--vw-unit)) solid rgba(208, 208, 208, 1);
  padding: calc(10 * var(--vw-unit));
  font-size: calc(13 * var(--vw-unit));
  font-weight: 400;
}

.message-holder-s textarea:focus {
  outline: calc(2 * var(--vw-unit)) solid rgba(229, 249, 245, 1);
}

.cancel-s {
  height: calc(48 * var(--vw-unit));
  width: calc(102 * var(--vw-unit));
  margin-left: calc(360 * var(--vw-unit));
  border: calc(1 * var(--vw-unit)) solid rgba(5, 66, 106, 1);
  border-radius: calc(4 * var(--vw-unit));
  color: rgba(5, 66, 106, 1);
  font-size: calc(16 * var(--vw-unit));
  font-weight: 500;
}

.submit-s {
  height: calc(48 * var(--vw-unit));
  width: calc(102 * var(--vw-unit));
  margin-left: calc(20 * var(--vw-unit));
  border: none;
  border-radius: calc(4 * var(--vw-unit));
  background-color: rgba(5, 66, 106, 1);
  color: white;
  font-size: calc(16 * var(--vw-unit));
  font-weight: 500;
}

.left-holder-s {
  height: calc(300 * var(--vw-unit));
  width: 96%;
  margin: 2%;
}

.get-in-touch {
  font-size: calc(18 * var(--vw-unit));
  font-weight: 600;
  color: rgba(46, 46, 46, 1);
  margin-bottom: calc(10 * var(--vw-unit));
}

.side-head-left-s {
  width: calc(475 * var(--vw-unit));
  margin-bottom: calc(20 * var(--vw-unit));
  font-size: calc(16 * var(--vw-unit));
  font-weight: 400;
  line-height: calc(24 * var(--vw-unit));
}

.left-holder-s button {
  height: calc(48 * var(--vw-unit));
  width: calc(148 * var(--vw-unit));
  border: none;
  border-radius: calc(4 * var(--vw-unit));
  background-color: rgba(5, 66, 106, 1);
  color: white;
  font-size: calc(16 * var(--vw-unit));
  font-weight: 500;
}

.faq-page-holder {
  position: absolute;
  top: 0;
  left: 0;
  min-height: calc(600 * var(--vw-unit));
  height: 100%;
  width: 100%;
}

@media (min-width: 550px) and (max-width: 1200px) {
  .pagecontent-support {
    position: absolute;
    top: calc(10 * var(--vw-unit));
    width: 100%;
    min-height: calc(900 * var(--vw-unit));
    height: 92vh;
    overflow: hidden;
  }

  .support-page-holder {
    height: 100%;
    width: 98%;
    margin-left: 2%;
    margin-top: 6%;
    background-color: white;
    overflow: hidden;
  }

  .help-heading {
    position: absolute;
    top: calc(16 * var(--vw-tab));
    left: calc(25 * var(--vw-tab));
    font-size: calc(18 * var(--vw-tab));
    font-weight: 600;
    color: rgba(46, 46, 46, 1);
  }

  .support-body {
    height: 98%;
    width: 98%;
    margin-left: 1%;
    overflow: scroll;
  }

  .right-holder-s {
    height: calc(460 * var(--vw-tab));
    width: 96%;
    margin: 2%;
    border: calc(1 * var(--vw-tab)) solid rgba(5, 66, 106, 0.4);
    border-radius: calc(4 * var(--vw-tab));
  }

  .form-s {
    height: 96%;
    width: 96%;
  }

  .name-holder-s {
    height: calc(67 * var(--vw-tab));
    width: 100%;
    display: flex;
    margin-bottom: calc(10 * var(--vw-tab));
  }

  .name-details-s {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: calc(280 * var(--vw-tab));
    margin-right: calc(150 * var(--vw-tab));
  }

  .name-details-s p {
    font-size: calc(14 * var(--vw-tab));
    font-weight: 600;
    color: rgba(32, 34, 36, 1);
  }

  .name-details-s input {
    margin: 0;
    height: calc(42 * var(--vw-tab));
    width: calc(260 * var(--vw-tab));
    border: calc(1 * var(--vw-tab)) solid rgba(208, 208, 208, 1);
    padding-left: calc(10 * var(--vw-tab));
    color: black;
    font-size: calc(13 * var(--vw-tab));
  }

  .name-details-s input:focus {
    outline: calc(5 * var(--vw-tab)) solid rgba(229, 249, 245, 1);
  }

  .email-topic-holder-s {
    display: flex;
    margin-bottom: calc(15 * var(--vw-tab));
  }

  .email-holder-s,
  .topic-holder-s {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: calc(280 * var(--vw-tab));
    margin-right: calc(150 * var(--vw-tab));
  }

  .email-holder-s p,
  .topic-holder-s p {
    font-size: calc(14 * var(--vw-tab));
    font-weight: 600;
  }

  .email-holder-s input,
  .topic-holder-s input {
    height: calc(42 * var(--vw-tab));
    width: calc(260 * var(--vw-tab));
    border: calc(1 * var(--vw-tab)) solid rgba(208, 208, 208, 1);
    padding-left: calc(10 * var(--vw-tab));
    font-size: calc(13 * var(--vw-tab));
  }

  .email-holder-s input:focus,
  .topic-holder-s input:focus {
    outline: calc(5 * var(--vw-tab)) solid rgba(229, 249, 245, 1);
  }

  .message-holder-s {
    height: calc(144 * var(--vw-tab));
    width: 100%;
    margin-bottom: calc(10 * var(--vw-tab));
  }

  .message-holder-s p {
    font-size: calc(14 * var(--vw-tab));
    font-weight: 600;
    color: rgba(46, 46, 46, 1);
  }

  .message-holder-s textarea {
    height: calc(119 * var(--vw-tab));
    width: calc(584 * var(--vw-tab));
    border: calc(1 * var(--vw-tab)) solid rgba(208, 208, 208, 1);
    padding: calc(10 * var(--vw-tab));
    font-size: calc(13 * var(--vw-tab));
    font-weight: 400;
  }

  .message-holder-s textarea:focus {
    outline: calc(2 * var(--vw-tab)) solid rgba(229, 249, 245, 1);
  }

  .cancel-s {
    height: calc(48 * var(--vw-tab));
    width: calc(102 * var(--vw-tab));
    margin-left: calc(360 * var(--vw-tab));
    border: calc(1 * var(--vw-tab)) solid rgba(5, 66, 106, 1);
    border-radius: calc(4 * var(--vw-tab));
    color: rgba(5, 66, 106, 1);
    font-size: calc(16 * var(--vw-tab));
    font-weight: 500;
  }

  .submit-s {
    height: calc(48 * var(--vw-tab));
    width: calc(102 * var(--vw-tab));
    margin-left: calc(20 * var(--vw-tab));
    border: none;
    border-radius: calc(4 * var(--vw-tab));
    background-color: rgba(5, 66, 106, 1);
    color: white;
    font-size: calc(16 * var(--vw-tab));
    font-weight: 500;
  }

  .left-holder-s {
    height: calc(300 * var(--vw-tab));
    width: 96%;
    margin: 2%;
  }

  .get-in-touch {
    font-size: calc(18 * var(--vw-tab));
    font-weight: 600;
    color: rgba(46, 46, 46, 1);
    margin-bottom: calc(10 * var(--vw-tab));
  }

  .side-head-left-s {
    width: calc(475 * var(--vw-tab));
    margin-bottom: calc(20 * var(--vw-tab));
    font-size: calc(16 * var(--vw-tab));
    font-weight: 400;
    line-height: calc(24 * var(--vw-tab));
  }

  .left-holder-s button {
    height: calc(48 * var(--vw-tab));
    width: calc(148 * var(--vw-tab));
    border: none;
    border-radius: calc(4 * var(--vw-tab));
    background-color: rgba(5, 66, 106, 1);
    color: white;
    font-size: calc(16 * var(--vw-tab));
    font-weight: 500;
  }

  .faq-page-holder {
    position: absolute;
    top: 0;
    left: 0;
    height: 96%;
    width: 100%;
  }
}

@media (max-width: 550px) {
  .page-content-support {
    width: 100%;
    height: 100%;
  }

  .support-page-holder {
    height: 100%;
    width: calc(390 * var(--vw-mob));
    background-color: white;
  }

  .help-heading {
    position: absolute;
    top: calc(16 * var(--vw-mob));
    left: calc(25 * var(--vw-mob));
    font-size: calc(18 * var(--vw-mob));
    font-weight: 500;
    color: rgba(46, 46, 46, 1);
  }

  .support-body {
    height: calc(660 * var(--vw-mob));
    width: 100%;
    overflow: scroll;
  }

  .right-holder-s {
    height: calc(660 * var(--vw-mob));
    width: 96%;
    margin: 2%;
    padding-left: calc(10 * var(--vw-mob));
    border: calc(1 * var(--vw-mob)) solid rgba(5, 66, 106, 0.4);
    border-radius: calc(4 * var(--vw-mob));
    overflow: scroll;
  }

  .form-s {
    height: 96%;
    width: 100%;
  }

  .name-holder-s {
    height: calc(160 * var(--vw-mob));
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: calc(10 * var(--vw-mob));
  }

  .name-details-s {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 50%;
    width: calc(280 * var(--vw-mob));
  }

  .name-details-s p {
    font-size: calc(14 * var(--vw-mob));
    font-weight: 600;
    color: rgba(32, 34, 36, 1);
  }

  .name-details-s input {
    margin: 0;
    height: calc(42 * var(--vw-mob));
    width: calc(280 * var(--vw-mob));
    border: calc(1 * var(--vw-mob)) solid rgba(208, 208, 208, 1);
    padding-left: calc(10 * var(--vw-mob));
    color: black;
    font-size: calc(13 * var(--vw-mob));
  }

  .name-details-s input:focus {
    outline: calc(5 * var(--vw-mob)) solid rgba(229, 249, 245, 1);
  }

  .email-topic-holder-s {
    height: calc(160 * var(--vw-mob));
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: calc(15 * var(--vw-mob));
  }

  .email-holder-s,
  .topic-holder-s {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: calc(280 * var(--vw-mob));
    margin-right: calc(150 * var(--vw-mob));
  }

  .email-holder-s p,
  .topic-holder-s p {
    font-size: calc(14 * var(--vw-mob));
    font-weight: 600;
  }

  .email-holder-s input,
  .topic-holder-s input {
    height: calc(42 * var(--vw-mob));
    width: calc(280 * var(--vw-mob));
    border: calc(1 * var(--vw-mob)) solid rgba(208, 208, 208, 1);
    padding-left: calc(10 * var(--vw-mob));
    font-size: calc(13 * var(--vw-mob));
  }

  .email-holder-s input:focus,
  .topic-holder-s input:focus {
    outline: calc(5 * var(--vw-mob)) solid rgba(229, 249, 245, 1);
  }

  .message-holder-s {
    height: calc(144 * var(--vw-mob));
    width: 100%;
    margin-bottom: calc(20 * var(--vw-mob));
  }

  .message-holder-s p {
    font-size: calc(14 * var(--vw-mob));
    font-weight: 600;
    color: rgba(46, 46, 46, 1);
  }

  .message-holder-s textarea {
    height: calc(119 * var(--vw-mob));
    width: calc(350 * var(--vw-mob));
    border: calc(1 * var(--vw-mob)) solid rgba(208, 208, 208, 1);
    padding: calc(10 * var(--vw-mob));
    font-size: calc(13 * var(--vw-mob));
    font-weight: 400;
  }

  .message-holder-s textarea:focus {
    outline: calc(2 * var(--vw-mob)) solid rgba(229, 249, 245, 1);
  }

  .buttons-holder-s {
    height: calc(120 * var(--vw-mob));
    display: flex;
    flex-direction: column-reverse;
    align-items: start;
    justify-content: center;
    gap: calc(16 * var(--vw-mob));
  }

  .cancel-s {
    margin: 0;
    height: calc(48 * var(--vw-mob));
    width: calc(294 * var(--vw-mob));
    border: calc(1 * var(--vw-mob)) solid rgba(5, 66, 106, 1);
    border-radius: calc(4 * var(--vw-mob));
    color: rgba(5, 66, 106, 1);
    font-size: calc(16 * var(--vw-mob));
    font-weight: 500;
  }

  .submit-s {
    margin: 0;
    height: calc(48 * var(--vw-mob));
    width: calc(294 * var(--vw-mob));
    border: none;
    border-radius: calc(4 * var(--vw-mob));
    background-color: rgba(5, 66, 106, 1);
    color: white;
    font-size: calc(16 * var(--vw-mob));
    font-weight: 500;
  }

  .left-holder-s {
    height: calc(250 * var(--vw-mob));
    width: 96%;
    margin: 2%;
    margin-top: 0;
    border: calc(1 * var(--vw-mob)) solid rgba(5, 66, 106, 0.4);
    padding: calc(10 * var(--vw-mob));
    border-radius: calc(4 * var(--vw-mob));
  }

  .get-in-touch {
    font-size: calc(18 * var(--vw-mob));
    font-weight: 600;
    color: rgba(46, 46, 46, 1);
    margin-bottom: calc(10 * var(--vw-mob));
  }

  .side-head-left-s {
    width: calc(275 * var(--vw-mob));
    margin-bottom: calc(20 * var(--vw-mob));
    font-size: calc(16 * var(--vw-mob));
    font-weight: 400;
    line-height: calc(24 * var(--vw-mob));
  }

  .left-holder-s button {
    height: calc(48 * var(--vw-mob));
    width: calc(148 * var(--vw-mob));
    border: none;
    border-radius: calc(4 * var(--vw-mob));
    background-color: rgba(5, 66, 106, 1);
    color: white;
    font-size: calc(16 * var(--vw-mob));
    font-weight: 500;
  }

  .faq-page-holder {
    position: absolute;
    top: 0;
    left: 0;
    min-height: calc(600 * var(--vw-mob));
    height: 96%;
    width: 100%;
  }
}
