.mobile-verification-container {
  width: 100%;
  max-width: 400px;
  margin: 50px auto 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-family: Arial, sans-serif;
}

.mobile-verification-container h2 {
  color: rgba(5, 66, 106, 1);
  margin-bottom: 20px;
  font-size: 24px;
}

.input-group-mv {
  margin-bottom: 15px;
  text-align: left;
}

.input-group-mv label {
  display: block;
  font-size: 14px;
  color: #555;
  margin-bottom: 5px;
}

.input-group-mv input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
  transition: border 0.3s;
}

.input-group-mv input:focus {
  border-color: #007bff;
}

.input-group-mv button {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  background-color: rgba(5, 66, 106, 1);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.input-group-mv button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.input-group-mv button:hover:not(:disabled) {
  background-color: rgba(5, 66, 106, 1);
}

.message-mv {
  margin-top: 20px;
  font-size: 14px;
  color: #333;
}
