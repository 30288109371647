.auto-insurance-page {
  background-image: linear-gradient(
    45deg,
    rgba(226, 255, 249, 1),
    rgb(149, 201, 223)
  );
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}
.loading-auto-insurance {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}
.loading-auto-insurance img {
  max-width: 100%;
  max-height: 100%;
}
.best-auto-insurance {
  text-align: center;
  font-size: calc(40 * var(--vw-unit));
  font-weight: 600;
}
.best-auto-insurance-rates {
  width: 50%;
  margin: calc(30 * var(--vw-unit)) 25% calc(60 * var(--vw-unit)) 25%;
  text-align: center;
  font-size: calc(30 * var(--vw-unit));
  font-weight: 600;
}

.step1-auto-insurance {
  width: 90%;
  margin: calc(30 * var(--vw-unit)) 5% calc(0 * var(--vw-unit)) 5%;
  text-align: center;
  font-size: calc(35 * var(--vw-unit));
  font-weight: 600;
}

.cheap-insurance-btn-auto-insurance {
  height: auto;
  width: 30%;
  margin: calc(30 * var(--vw-unit)) 35%;
  font-size: calc(20 * var(--vw-unit));
  font-weight: 600;
  background-color: rgba(226, 255, 249, 1);
  border-radius: calc(50 * var(--vw-unit));
  padding: calc(18 * var(--vw-unit)) calc(5 * var(--vw-unit));
  transition: all 0.3s ease-in-out;
}
.cheap-insurance-btn-auto-insurance:hover {
  transform: scale(1.05);
}
.step2-auto-insurance {
  width: 90%;
  margin: calc(30 * var(--vw-unit)) 5%;
  text-align: center;
  font-size: calc(35 * var(--vw-unit));
  font-weight: 600;
}
.step3-auto-insurance {
  width: 90%;
  margin: calc(70 * var(--vw-unit)) 5%;
  text-align: center;
  font-size: calc(35 * var(--vw-unit));
  font-weight: 600;
}

.step4-auto-insurance,
.step5-auto-insurance {
  width: 80%;
  margin: calc(70 * var(--vw-unit)) 10%;
  text-align: center;
  font-size: calc(35 * var(--vw-unit));
  font-weight: 600;
}
.stay-updated-auto-insurance {
  width: 80%;
  margin: calc(30 * var(--vw-unit)) 10%;
  text-align: center;
  font-size: calc(35 * var(--vw-unit));
  font-weight: 600;
}
.join-us-auto-insurance {
  display: flex;
  height: calc(150 * var(--vw-unit));
  width: 80%;
  margin: calc(30 * var(--vw-unit)) 10%;
  background-image: linear-gradient(90deg, rgb(5, 66, 106), rgb(86, 225, 197));
  border-radius: calc(10 * var(--vw-unit));
  justify-content: space-between;
  align-items: center;
  padding: 0 5%;
}
.join-us-auto-insurance p {
  width: 40%;
  font-size: calc(26 * var(--vw-unit));
  color: white;
  font-weight: 600;
}
.join-us-auto-insurance button {
  width: 20%;
  border: none;
  border-radius: calc(30 * var(--vw-unit));
  padding: calc(10 * var(--vw-unit)) calc(7 * var(--vw-unit));
  color: rgb(5, 60, 5);
  font-size: calc(20 * var(--vw-unit));
  font-weight: 600;
}

.new-listing-btn-auto-insurance {
  width: 100%;
  display: flex;
  justify-content: center;
}
.new-listing-btn-auto-insurance button {
  height: auto;
  width: 30%;
  margin: calc(30 * var(--vw-unit)) 35%;
  font-size: calc(20 * var(--vw-unit));
  font-weight: 600;
  background-color: rgba(226, 255, 249, 1);
  border-radius: calc(50 * var(--vw-unit));
  padding: calc(18 * var(--vw-unit)) calc(5 * var(--vw-unit));
  transition: all 0.3s ease-in-out;
}
.new-listing-btn-auto-insurance button:hover {
  transform: scale(1.09);
}
.add-listing-auto-insurance {
  height: calc(500 * var(--vw-unit));
  width: 60%;
  margin: 0 20%;
  overflow: hidden;
  display: flex;
  flex-direction: column-reverse;
}
.add-listing-auto-insurance img {
  width: 100%;
  z-index: 0;
}
.need-insurance-box-auto-insurance {
  position: absolute;
  height: calc(400 * var(--vw-unit));
  width: 50%;
  margin: 5% 5%;
  background-color: white;
  z-index: 100;
  border-radius: calc(9 * var(--vw-unit));
  background-color: white;
  padding: calc(20 * var(--vw-unit));
  box-shadow: calc(-8 * var(--vw-unit)) calc(8 * var(--vw-unit))
    calc(34 * var(--vw-unit)) 0 rgba(0, 0, 0, 0.08);
}
.btn-holder-auto-insurance {
  height: 30%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.select-wrapper-auto-insurance {
  height: calc(52 * var(--vw-unit));
  width: calc(370 * var(--vw-unit));
  border: calc(1 * var(--vw-unit)) solid rgba(7, 51, 108, 0.2);
  border-radius: calc(100 * var(--vw-unit));
  display: flex;
  justify-content: center;
}
.select-wrapper-auto-insurance select {
  height: 100%;
  width: 90%;
  border: none;
  background: transparent;
  color: rgba(5, 66, 106, 1);
  font-size: calc(24 * var(--vw-unit));
  font-weight: 600;
  padding: 0;
}
.need-insurance-heading-auto-insurance {
  height: calc(37 * var(--vw-unit));
  text-align: center;
  font-size: calc(32 * var(--vw-unit));
  font-weight: 700;
  color: rgba(5, 66, 106, 1);
  margin-top: calc(10 * var(--vw-unit));
}
.need-insurance-sidehead-auto-insurance {
  height: calc(37 * var(--vw-unit));
  text-align: center;
  font-size: calc(20 * var(--vw-unit));
  font-weight: 500;
  color: rgba(5, 66, 106, 1);
  margin-top: calc(20 * var(--vw-unit));
}
.need-insurance-info-auto-insurance {
  height: calc(50 * var(--vw-unit));
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: calc(30 * var(--vw-unit));
  margin-top: calc(50 * var(--vw-unit));
}
.need-insurance-info-auto-insurance div {
  height: auto;
  border-radius: calc(100 * var(--vw-unit));
  background-color: rgba(115, 246, 201, 1);
  font-size: calc(18 * var(--vw-unit));
  font-weight: 500;
  color: rgba(5, 66, 106, 1);
  padding: calc(8 * var(--vw-unit)) calc(10 * var(--vw-unit));
  display: flex;
  justify-content: center;
  align-items: center;
}
.auto-insurance-cities {
  width: 100%;
  height: auto;
  background-color: rgb(5, 66, 106);
  display: flex;
  flex-wrap: wrap;
  padding: calc(30 * var(--vw-unit)) 10%;
  gap: 5%;
}
.auto-insurance-cities a {
  color: white !important;
  font-size: calc(20 * var(--vw-unit));
  text-decoration: underline !important;
  cursor: pointer;
}
.auto-insurance-cities a:visited {
  color: rgb(86, 225, 197) !important;
}
.footer-auto-insurance {
  width: 100%;
  height: calc(70 * var(--vw-unit));
  background-color: rgba(5, 66, 106, 1);
  display: flex;
  align-items: center;
  color: #fff;
}

@media (max-width: 1000px) {
  .best-auto-insurance {
    width: 90%;
    text-align: center;
    margin: calc(40 * var(--vw-tab)) 5%;
    font-size: calc(40 * var(--vw-tab));
    font-weight: 600;
  }
  .best-auto-insurance-rates {
    width: 80%;
    margin: calc(30 * var(--vw-tab)) 10%;
    text-align: center;
    font-size: calc(30 * var(--vw-tab));
    font-weight: 600;
  }

  .step1-auto-insurance {
    width: 90%;
    margin: calc(70 * var(--vw-tab)) 5% calc(0 * var(--vw-tab)) 5%;
    text-align: center;
    font-size: calc(35 * var(--vw-tab));
    font-weight: 600;
  }
  .cheap-insurance-btn-auto-insurance {
    height: auto;
    width: 60%;
    margin: calc(30 * var(--vw-tab)) 20%;
    font-size: calc(20 * var(--vw-tab));
    font-weight: 600;
    background-color: rgba(226, 255, 249, 1);
    border-radius: calc(50 * var(--vw-tab));
    padding: calc(18 * var(--vw-tab)) calc(5 * var(--vw-tab));
    transition: all 0.3s ease-in-out;
  }
  .cheap-insurance-btn-auto-insurance:hover {
    transform: scale(1.05);
  }
  .step2-auto-insurance {
    width: 90%;
    margin: calc(30 * var(--vw-tab)) 5%;
    text-align: center;
    font-size: calc(35 * var(--vw-tab));
    font-weight: 600;
  }

  .step3-auto-insurance {
    width: 90%;
    margin: calc(60 * var(--vw-tab)) 5%;
    text-align: center;
    font-size: calc(35 * var(--vw-tab));
    font-weight: 600;
  }

  .step4-auto-insurance,
  .step5-auto-insurance {
    width: 80%;
    margin: calc(60 * var(--vw-tab)) 10%;
    text-align: center;
    font-size: calc(35 * var(--vw-tab));
    font-weight: 600;
  }
  .stay-updated-auto-insurance {
    width: 90%;
    margin: calc(30 * var(--vw-tab)) 5%;
    text-align: center;
    font-size: calc(35 * var(--vw-tab));
    font-weight: 600;
  }
  .join-us-auto-insurance {
    display: flex;
    height: calc(180 * var(--vw-tab));
    width: 80%;
    margin: calc(30 * var(--vw-tab)) 10%;
    background-image: linear-gradient(
      90deg,
      rgb(5, 66, 106),
      rgb(86, 225, 197)
    );
    border-radius: calc(10 * var(--vw-tab));
    justify-content: space-between;
    align-items: center;
    padding: 0 5%;
  }
  .join-us-auto-insurance p {
    width: 60%;
    font-size: calc(26 * var(--vw-tab));
    color: white;
    font-weight: 600;
  }
  .join-us-auto-insurance button {
    width: 25%;
    border: none;
    border-radius: calc(30 * var(--vw-tab));
    padding: calc(10 * var(--vw-tab)) calc(7 * var(--vw-tab));
    color: rgb(5, 60, 5);
    font-size: calc(20 * var(--vw-tab));
    font-weight: 600;
  }
  .new-listing-btn-auto-insurance {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .new-listing-btn-auto-insurance button {
    height: auto;
    width: 60%;
    margin: calc(30 * var(--vw-tab)) 20%;
    font-size: calc(20 * var(--vw-tab));
    font-weight: 600;
    background-color: rgba(226, 255, 249, 1);
    border-radius: calc(50 * var(--vw-tab));
    padding: calc(18 * var(--vw-tab)) calc(5 * var(--vw-tab));
    transition: all 0.3s ease-in-out;
  }
  .new-listing-btn-auto-insurance button:hover {
    transform: scale(1.09);
  }
  .add-listing-auto-insurance {
    height: calc(500 * var(--vw-tab));
    width: 90%;
    margin: 0 5%;
    overflow: hidden;
    display: flex;
    flex-direction: column-reverse;
  }
  .add-listing-auto-insurance img {
    width: 100%;
    z-index: 0;
  }
  .need-insurance-box-auto-insurance {
    position: absolute;
    height: calc(400 * var(--vw-tab));
    width: 80%;
    margin: 5% 5%;
    background-color: white;
    z-index: 100;
    border-radius: calc(9 * var(--vw-tab));
    background-color: white;
    padding: calc(20 * var(--vw-tab));
    box-shadow: calc(-8 * var(--vw-tab)) calc(8 * var(--vw-tab))
      calc(34 * var(--vw-tab)) 0 rgba(0, 0, 0, 0.08);
  }
  .btn-holder-auto-insurance {
    height: 30%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .select-wrapper-auto-insurance {
    height: calc(52 * var(--vw-tab));
    width: calc(370 * var(--vw-tab));
    border: calc(1 * var(--vw-tab)) solid rgba(7, 51, 108, 0.2);
    border-radius: calc(100 * var(--vw-tab));
    display: flex;
    justify-content: center;
  }
  .select-wrapper-auto-insurance select {
    height: 100%;
    width: 90%;
    border: none;
    background: transparent;
    color: rgba(5, 66, 106, 1);
    font-size: calc(24 * var(--vw-tab));
    font-weight: 600;
    padding: 0;
  }
  .need-insurance-heading-auto-insurance {
    height: calc(37 * var(--vw-tab));
    text-align: center;
    font-size: calc(32 * var(--vw-tab));
    font-weight: 700;
    color: rgba(5, 66, 106, 1);
    margin-top: calc(10 * var(--vw-tab));
  }
  .need-insurance-sidehead-auto-insurance {
    height: calc(37 * var(--vw-tab));
    text-align: center;
    font-size: calc(20 * var(--vw-tab));
    font-weight: 500;
    color: rgba(5, 66, 106, 1);
    margin-top: calc(20 * var(--vw-tab));
  }
  .need-insurance-info-auto-insurance {
    height: calc(50 * var(--vw-tab));
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: calc(30 * var(--vw-tab));
    margin-top: calc(50 * var(--vw-tab));
  }
  .need-insurance-info-auto-insurance div {
    height: auto;
    border-radius: calc(100 * var(--vw-tab));
    background-color: rgba(115, 246, 201, 1);
    font-size: calc(18 * var(--vw-tab));
    font-weight: 500;
    color: rgba(5, 66, 106, 1);
    padding: calc(5 * var(--vw-tab)) calc(25 * var(--vw-tab));
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .auto-insurance-cities a {
    font-size: calc(15 * var(--vw-tab));
  }
  .footer-auto-insurance {
    height: calc(70 * var(--vw-tab));
  }
}
