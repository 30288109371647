.calculator-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-image: linear-gradient(
    45deg,
    rgba(226, 255, 249, 1),
    rgba(231, 245, 255, 1)
  );
  z-index: 10;
}
.calculator-header-bar {
  position: fixed;
  top: 0;
  display: flex;

  width: 100%;
  justify-content: space-between;
  padding: 0 5%;
  align-items: center;
  height: 10vh;
  max-height: 80px;
  overflow: hidden;
}
.logo-calculator {
  height: 50%;
  max-height: 100px;
  width: auto;
  max-width: 95%;
  cursor: pointer;
}
.home-icon-calculator {
  height: 40%;
  cursor: pointer;
}
.form-container {
  position: fixed;
  top: 10vh;
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  overflow-y: scroll;
  width: 100vw;
  height: 90vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: linear-gradient(
    45deg,
    rgba(226, 255, 249, 1),
    rgba(231, 245, 255, 1)
  );
}

.form-container form {
  width: 35%;
}

.calculator-heading {
  text-align: center;
  margin-bottom: 20px;
  font-family: 'Arial', sans-serif;
  color: rgba(7, 51, 108, 1);
  font-weight: 600;
  width: calc(100% - 50px);
  font-size: calc(28 * var(--vw-unit));
}

.calculator-labels {
  display: block;
  margin-bottom: 4px;
  margin-top: 15px;
  font-weight: bold;
  color: #555;
}

.submit-btn-calculator {
  width: 100%;
  padding: 10px;
  background-color: rgba(7, 51, 108, 1);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 35px;
  font-size: 16px;
}

.submit-btn-calculator:hover {
  transform: scale(1.01);
  background-color: rgba(7, 51, 108, 0.9);
}

.Select__control {
  margin-bottom: 15px;
}

.loading-img {
  max-width: 100vw;
  max-height: 100vh;
  height: 100%;
  width: auto;
}

@media (max-width: 1200px) and (min-width: 700px) {
  .logo-calculator {
    height: 60%;
  }
  .calculator-heading {
    font-size: 2rem;
  }
  .home-icon-calculator {
    height: 40%;
  }
}

@media (max-width: 700px) {
  .form-container form {
    width: 75%;
  }
  .logo-calculator {
    height: 4vh;
  }
  .home-icon-calculator {
    width: 0;
    visibility: hidden;
    position: fixed;
  }
  .calculator-heading {
    font-size: 1.5rem;
  }
}
