.sellers-b-content-mobile {
  height: 0;
  width: 0;
  visibility: hidden;
}
.buyers-b-page {
  background-image: linear-gradient(180deg, #e2fff9 0%, #e7f5ff 103.19%);
  height: max(100vh, 840 * var(--vw-unit));
  width: 100vw;
}
.sellers-b-logo {
  position: absolute;
  top: calc(46 * var(--vw-unit));
  left: calc(80 * var(--vw-unit));
  width: calc(209 * var(--vw-unit));
}
.buyers-b-main-head {
  position: absolute;
  top: calc(206 * var(--vw-unit));
  left: calc(80 * var(--vw-unit));
  height: calc(264 * var(--vw-unit));
  width: calc(639 * var(--vw-unit));
  font-size: calc(55 * var(--vw-unit));
  font-weight: 600;
  line-height: calc(72 * var(--vw-unit));
  color: rgba(5, 66, 106, 1);
}
.buyers-b-main-head span {
  font-weight: 800;
}
.buyers-b-side-head {
  position: absolute;
  top: calc(500 * var(--vw-unit));
  left: calc(80 * var(--vw-unit));
  height: calc(39 * var(--vw-unit));
  width: calc(627 * var(--vw-unit));
  font-size: calc(20 * var(--vw-unit));
  color: rgba(30, 30, 30, 1);
  font-weight: 600;
}
.new-b-listings {
  position: absolute;
  top: calc(580 * var(--vw-unit));
  left: calc(80 * var(--vw-unit));
  height: calc(64 * var(--vw-unit));
  width: calc(296 * var(--vw-unit));
  border: none;
  border-radius: calc(100 * var(--vw-unit));
  background-color: rgba(5, 66, 106, 1);
  color: white;
  font-size: calc(24 * var(--vw-unit));
  font-weight: 500;
}
.sellers-b-content {
  position: absolute;
  top: calc(184 * var(--vw-unit));
  left: calc(720 * var(--vw-unit));
  height: calc(504 * var(--vw-unit));
  cursor: pointer;
}
.buyers-b-comment1 {
  position: absolute;
  top: calc(121 * var(--vw-unit));
  left: calc(690 * var(--vw-unit));
  height: calc(154 * var(--vw-unit));
}
.buyers-b-comment2 {
  position: absolute;
  top: calc(608 * var(--vw-unit));
  left: calc(1175 * var(--vw-unit));
  width: calc(215 * var(--vw-unit));
}

@media (min-width: 550px) and (max-width: 1200px) {
  .buyers-b-page {
    background-image: linear-gradient(180deg, #e2fff9 0%, #e7f5ff 103.19%);
    height: max(100vh, 1279 * var(--vw-htab));
    width: 100vw;
  }
  .sellers-b-logo {
    position: absolute;
    top: calc(30 * var(--vw-htab));
    left: calc(32 * var(--vw-htab));
    width: calc(209 * var(--vw-htab));
  }
  .buyers-b-main-head {
    position: absolute;
    top: calc(157 * var(--vw-htab));
    left: calc(78 * var(--vw-htab));
    height: calc(198 * var(--vw-htab));
    width: calc(678 * var(--vw-htab));
    font-size: calc(55 * var(--vw-htab));
    font-weight: 600;
    line-height: calc(66 * var(--vw-htab));
    color: rgba(5, 66, 106, 1);
    text-align: center;
  }
  .buyers-b-main-head span {
    font-weight: 800;
  }
  .buyers-b-side-head {
    position: absolute;
    top: calc(380 * var(--vw-htab));
    left: calc(32 * var(--vw-htab));
    height: calc(39 * var(--vw-htab));
    width: calc(770 * var(--vw-htab));
    font-size: calc(20 * var(--vw-htab));
    color: rgba(30, 30, 30, 1);
    font-weight: 600;
    text-align: center;
  }
  .new-b-listings {
    position: absolute;
    top: calc(450 * var(--vw-htab));
    align-self: center;
    left: calc(269 * var(--vw-htab));
    height: calc(64 * var(--vw-htab));
    width: calc(296 * var(--vw-htab));
    border: none;
    border-radius: calc(100 * var(--vw-htab));
    background-color: rgba(5, 66, 106, 1);
    color: white;
    font-size: calc(24 * var(--vw-htab));
    font-weight: 500;
  }
  .sellers-b-content {
    position: absolute;
    top: calc(615 * var(--vw-htab));
    left: calc(66 * var(--vw-htab));
    width: calc(701 * var(--vw-htab));
    height: auto;
    cursor: pointer;
  }
  .buyers-b-comment1 {
    position: absolute;
    top: calc(542 * var(--vw-htab));
    left: calc(30 * var(--vw-htab));
    height: calc(174 * var(--vw-htab));
  }
  .buyers-b-comment2 {
    position: absolute;
    top: calc(1041 * var(--vw-htab));
    left: calc(541 * var(--vw-htab));
    width: calc(235 * var(--vw-htab));
  }
}

@media (max-width: 550px) {
  .buyers-b-page {
    background-image: linear-gradient(180deg, #e2fff9 0%, #e7f5ff 103.19%);
    height: max(100vh, 1042 * var(--vw-mob));
    width: 100vw;
    overflow-x: hidden;
  }
  .sellers-b-logo {
    position: absolute;
    top: calc(26 * var(--vw-mob));
    left: calc(16 * var(--vw-mob));
    width: calc(162 * var(--vw-mob));
  }
  .buyers-b-main-head {
    position: absolute;
    top: calc(94 * var(--vw-mob));
    left: calc(31 * var(--vw-mob));
    height: calc(108 * var(--vw-mob));
    width: calc(328 * var(--vw-mob));
    font-size: calc(30 * var(--vw-mob));
    font-weight: 600;
    line-height: calc(36 * var(--vw-mob));
    color: rgba(5, 66, 106, 1);
    text-align: center;
  }
  .buyers-b-main-head span {
    font-weight: 800;
  }
  .buyers-b-side-head {
    position: absolute;
    top: calc(255 * var(--vw-mob));
    left: calc(85 * var(--vw-mob));
    height: calc(24 * var(--vw-mob));
    width: calc(221 * var(--vw-mob));
    font-size: calc(14 * var(--vw-mob));
    color: rgba(30, 30, 30, 1);
    font-weight: 600;
    text-align: center;
  }
  .new-b-listings {
    position: absolute;
    top: calc(324 * var(--vw-mob));
    align-self: center;
    left: calc(108 * var(--vw-mob));
    height: calc(41 * var(--vw-mob));
    width: calc(174 * var(--vw-mob));
    border: none;
    border-radius: calc(100 * var(--vw-mob));
    background-color: rgba(5, 66, 106, 1);
    color: white;
    font-size: calc(14 * var(--vw-mob));
    font-weight: 500;
  }
  .sellers-b-content {
    visibility: hidden;
    width: 0;
    height: 0;
  }
  .sellers-b-content-mobile {
    visibility: visible;
    position: absolute;
    top: calc(445 * var(--vw-mob));
    left: calc(8 * var(--vw-mob));
    width: calc(381 * var(--vw-mob));
    height: auto;
    cursor: pointer;
  }
  .buyers-b-comment1 {
    position: absolute;
    top: calc(403 * var(--vw-mob));
    left: calc(0 * var(--vw-mob));
    width: calc(200 * var(--vw-mob));
    height: auto;
  }
  .buyers-b-comment2 {
    position: absolute;
    top: calc(840 * var(--vw-mob));
    left: calc(194 * var(--vw-mob));
    width: calc(194 * var(--vw-mob));
  }
}
