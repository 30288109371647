.pageholder-faq {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
}

.contentholder {
  height: 100%;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.home-btn-faq {
  height: 20px;
  cursor: pointer;
}
.headingholder {
  height: 60px;
  display: flex;
  align-items: center;
}

.innercontent {
  height: 90%;
  width: 100%;
  overflow: scroll;
}

.faq-item {
  min-height: 50px;
  width: 100%;
  background-color: #f2f2f2;
  color: black;
  margin-bottom: 10px;
}

.question {
  min-height: 50px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  overflow: scroll;
}

.answer {
  min-height: 50px;
  width: 100%;
  padding-left: 10px;
  padding-right: 15px;
  margin-top: 10px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  overflow: scroll;
}

.chevron-icon {
  transition: transform 0.3s ease;
}

.chevron-icon.rotate {
  transform: rotate(180deg);
}
