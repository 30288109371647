#pagecontent-getposts *::-webkit-scrollbar {
  width: 0;
  height: 0;
  color: transparent;
}

@media (min-width: 1200px) {
  #pagecontent-getposts {
    height: 100%;
    width: 100%;
    background: transparent;
  }

  .get-posts-heading {
    position: absolute;
    top: calc(16 * var(--vw-unit));
    left: calc(16 * var(--vw-unit));
    font-size: calc(18 * var(--vw-unit));
    font-weight: 600;
    color: rgba(46, 46, 46, 1);
  }

  .get-posts-body {
    position: absolute;
    top: 10%;
    left: 2%;
    height: 90%;
    width: 96%;
    background-color: white;
    padding: calc(10 * var(--vw-unit));
    overflow: scroll;
  }

  .container {
    margin: 0;
    padding: 0;
  }

  .row-mb-3 {
    width: 100%;
  }

  .col-md-2 {
    width: 30%;
  }

  .col-md-2 label {
    font-size: calc(14 * var(--vw-unit));
    font-weight: 700;
    color: rgba(32, 34, 36, 1);
  }

  .col-md-2 input,
  .col-md-2 select {
    height: 50%;
    color: black;
    font-size: calc(13 * var(--vw-unit));
    margin-bottom: calc(20 * var(--vw-unit));
  }

  .col-md-2 input:focus {
    outline: calc(3 * var(--vw-unit)) solid rgba(220, 249, 245, 1);
  }

  .low-icon {
    position: absolute;
    top: calc(55 * var(--vw-unit));
    left: calc(290 * var(--vw-unit));
    height: calc(0 * var(--vw-unit));
    visibility: hidden;
  }

  .table-responsive {
    overflow-x: auto;
  }

  .table {
    width: 100%;
    border-collapse: collapse;
  }

  .table th,
  .table td {
    padding: 8px;
    border-bottom: 1px solid #ddd;
  }

  .table th {
    background-color: rgb(13, 71, 161, 1);
    color: white;
    text-align: left;
    font-weight: bold;
    font-size: calc(14 * var(--vw-unit));
    font-weight: 500;
  }

  .table tr,
  .table td {
    background-color: rgb(232, 246, 248);
    font-size: calc(14 * var(--vw-unit));
    font-weight: 500;
  }

  .table tbody tr:hover {
    background-color: rgb(232, 246, 248, 1);
    cursor: pointer;
  }

  .dataholder {
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: row;
    overflow: scroll;
  }

  .tableholder {
    width: 100%;
    overflow: scroll;
  }

  .home-icon-si {
    position: absolute;
    top: 2%;
    right: 5%;
    height: calc(30 * var(--vw-unit));
    cursor: pointer;
  }
}

@media (max-width: 550px) {
  #pagecontent-getposts {
    height: 100%;
    width: 100%;
  }

  .get-posts-heading {
    position: absolute;
    top: calc(16 * var(--vw-mob));
    left: calc(16 * var(--vw-mob));
    font-size: calc(16 * var(--vw-mob));
    font-weight: 600;
    color: rgba(46, 46, 46, 1);
  }

  .get-posts-body {
    position: absolute;
    top: 8%;
    left: 2%;
    height: 90%;
    width: 96%;
    background-color: white;
    padding: calc(10 * var(--vw-mob));
    overflow: scroll;
  }

  .col-md-2 {
    height: 60px;
  }

  .col-md-2 label {
    font-size: calc(14 * var(--vw-mob));
    font-weight: 700;
    color: rgba(32, 34, 36, 1);
  }

  .col-md-2 input,
  .col-md-2 select {
    height: 50%;
    font-size: calc(11 * var(--vw-mob));
  }

  .col-md-2 label {
    font-size: 0.8rem;
    color: black;
    margin-bottom: 1px;
  }

  .low-icon {
    position: absolute;
    top: calc(42 * var(--vw-mob));
    left: calc(340 * var(--vw-mob));
    height: calc(7 * var(--vw-mob));
  }

  .table-responsive {
    overflow-x: auto;
  }

  .table {
    font-size: 0.8rem;
    width: 100%;
    border-collapse: collapse;
  }

  .table th,
  .table td {
    padding: 8px;
    border-bottom: 1px solid #ddd;
  }

  .table th {
    background-color: rgb(13, 71, 161, 1);
    color: white;
    text-align: center;
    font-weight: 500;
    font-size: calc(10 * var(--vw-mob));
  }

  .table td {
    background-color: rgba(220, 249, 245, 1);
  }

  .table tbody tr:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }

  .selected-details {
    height: 100%;
    width: 100%;
    border: 1px solid #ccc;
    padding: 10px;
    background-color: #f9f9f9;
  }

  .selected-details h3 {
    margin-top: 0;
    color: black;
  }

  .selected-details p {
    margin: 5px 0;
  }

  .dataholder {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: row;
  }

  .tableholder {
    width: 100%;
    overflow: scroll;
  }
  .home-icon-si {
    position: absolute;
    top: 2%;
    right: 5%;
    height: calc(20 * var(--vw-mob));
  }
}

@media (min-width: 550px) and (max-width: 1200px) {
  #pagecontent-getposts {
    height: 90%;
    width: 90%;
    font-size: small;
  }

  .get-posts-heading {
    position: absolute;
    top: calc(16 * var(--vw-tab));
    left: calc(16 * var(--vw-tab));
    font-size: calc(16 * var(--vw-tab));
    font-weight: 600;
    color: rgba(46, 46, 46, 1);
  }

  .get-posts-body {
    position: absolute;
    top: 8%;
    left: 2%;
    height: 90%;
    width: 96%;
    background-color: white;
    padding: calc(10 * var(--vw-tab));
    overflow: scroll;
  }

  .container {
    margin: 0;
    padding: 0;
  }

  .col-md-2 {
    width: 50%;
  }

  .col-md-2 label {
    font-size: calc(14 * var(--vw-tab));
    font-weight: 700;
    color: rgba(32, 34, 36, 1);
    margin: 0;
    margin-top: calc(10 * var(--vw-tab));
  }

  .col-md-2 input,
  .col-md-2 select {
    height: 50%;
    width: 100%;
    color: black;
    font-size: small;
  }

  .low-icon {
    position: absolute;
    top: calc(52 * var(--vw-tab));
    left: calc(330 * var(--vw-tab));
    height: calc(8 * var(--vw-tab));
  }

  .table-responsive {
    overflow-x: auto;
  }

  .table {
    width: 100%;
    border-collapse: collapse;
  }

  .table th,
  .table td {
    padding: 8px;
    border-bottom: 1px solid #ddd;
  }

  .table th {
    background-color: rgb(13, 71, 161, 1);
    color: white;
    font-size: calc(14 * var(--vw-tab));
    text-align: left;
    font-weight: 500;
  }

  .table td {
    background-color: rgba(220, 249, 245, 1);
    font-size: calc(14 * var(--vw-tab));
    font-weight: 500;
  }

  .table tbody tr:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }

  .dataholder {
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: row;
    overflow: scroll;
  }

  .tableholder {
    width: 100%;
    overflow: scroll;
  }

  .home-icon-si {
    position: absolute;
    top: 2%;
    right: 5%;
    height: calc(20 * var(--vw-tab));
  }
}
