@media (min-width: 1200px) {
  .service-page1 {
    padding: 30px;
    background-image: linear-gradient(45deg, rgb(34, 48, 116), rgb(7, 47, 58));
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .service-page1 h1 {
    font-size: 2.5rem;
    font-weight: 700;
    color: yellow;
  }
  .service-page1-container {
    display: flex;
    justify-content: center;
  }
  .service-page1-container img {
    width: 35%;
  }
  .service-page1-container-mid {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: end;
  }
  .service-page1-container-mid img {
    width: 100%;
  }
  .service-page1-container-end {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
  .service-page-benefit-points {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .service-page-benefit-points img {
    height: 60px;
    width: auto;
  }
  .service-page-benefit-points p {
    font-size: 1.5rem;
    font-weight: 600;
    color: white;
  }
  .service-page-list-for-free {
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: visible;
  }
  .service-page-list-for-free button {
    padding: 10px 25px;
    box-shadow: 6px 8px 4px rgb(48, 46, 46, 0.5);
    border-radius: 40px;
    background-color: white;
    font-size: 1.8rem;
    font-weight: 800;
    color: blue;
    cursor: pointer;
  }
  .how-it-works-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 60px;
    align-items: center;
    margin: 50px 0;
  }
  .how-it-works-container h2 {
    font-size: 2rem;
    font-weight: 600;
    color: rgb(13, 71, 161);
  }
  .how-it-works-container-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10%;
    padding: 30px 0;
  }
  .how-it-works-container-content-left {
    background-color: bisque;
    border-radius: 70px;
    padding: 0px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .how-it-works-container-content-right {
    background-color: bisque;
    border-radius: 70px;
    padding: 10px 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .how-it-works-container-content-left img {
    width: 240px;
    position: relative;
    bottom: 60px;
  }
  .how-it-works-container-content-right img {
    width: 140px;
    position: relative;
    bottom: 60px;
  }

  .how-it-works-container-content-left p,
  .how-it-works-container-content-right p {
    text-align: center;
    position: relative;
    bottom: 20px;
  }
  .service-page-for-buyers {
    font-size: 1.7rem;
    font-weight: 500;
    text-shadow: 1px 1px 1px rgb(91, 89, 89);
    margin-bottom: 10px;
  }
  .service-page-for-buyers-content {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 10px;
  }
  .service-page-signup-text {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 20px 0 5px 0;
  }
  .service-page-for-buyers-button {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .service-page-for-buyers-button button {
    font-size: 1.5rem;
    background-color: rgb(13, 71, 161);
    color: white;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    margin-bottom: 20px;
  }
  .tired-of-dealing-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 1% 6% 1% 5%;
  }
  .tired-of-dealing-container img {
    max-width: 45%;
  }
  .tired-of-dealing-right {
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 30px;
  }
  .tired-of-dealing-right h3 {
    text-align: right;
    color: rgb(39, 97, 229);
    font-size: 2.2rem;
    font-weight: 700;
  }
  .tired-of-dealing-right p {
    font-size: 1.2rem;
    text-align: end;
    color: black;
  }
  .tired-of-dealing-right p span {
    font-weight: 700;
    font-size: 1.3rem;
  }
  .mobile-app-download-buttons {
    display: flex;
    flex-direction: column;
    align-items: end;
  }
  .mobile-app-download-buttons img {
    cursor: pointer;
  }
  .service-page-features-container {
    display: flex;
    width: 90%;
    margin: 5%;
    background-color: aliceblue;
    border-radius: 30px;
    padding: 40px 10px 10px 10px;
  }
  .service-page-features-left,
  .service-page-features-middle,
  .service-page-features-right {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .service-page-features-left p,
  .service-page-features-middle p,
  .service-page-features-right p {
    text-align: center;
    font-size: 1.2rem;
    color: black;
    margin: 30px 50px;
    height: 100px;
  }
  .service-page-features-left img,
  .service-page-features-middle img,
  .service-page-features-right img {
    width: 300px;
  }
  .service-page-features-top {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .service-page-features-top img {
    height: 50px;
    width: auto;
  }
  .service-page-features-top h3 {
    font-size: 1.4rem;
    color: rgb(13, 71, 161);
    font-weight: 600;
  }
  .find-cheap-car-insurance-container {
    display: flex;
    width: 100%;
    padding: 20px 80px;
    align-items: center;
    gap: 30px;
  }
  .find-cheap-car-insurance-left {
    width: 65%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 50px;
    background-color: aliceblue;
    padding: 30px 70px;
  }
  .find-cheap-car-insurance-left h2 {
    text-align: center;
    font-size: 1.3rem;
    font-weight: 600;
    color: black;
  }
  .find-cheap-car-insurance-left p {
    font-size: 0.8rem;
  }
  .find-cheap-car-insurance-right {
    width: 30%;
    display: flex;
    flex-direction: column;
  }
  .find-cheap-car-insurance-right div {
    display: flex;
    flex-direction: column;
    width: 90%;
    padding: 10px;
    gap: 30px;
  }
  .find-cheap-car-insurance-right div p {
    text-align: center;
    font-size: 1rem;
  }
  .find-cheap-car-insurance-right button {
    padding: 10px;
    border: none;
    border-radius: 30px;
    background-color: rgb(13, 71, 161);
    color: white;
    font-size: 1.2rem;
    font-weight: 600;
  }
  .service-page-people-talks {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px 0;
  }
  .service-page-people-talks h2 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 30px;
  }
  .service-page-people-talks div {
    width: 96%;
    margin-left: 2%;
  }
  .service-page-qa-item {
    min-height: 50px;
    width: 100%;
    color: black;
    margin-bottom: 10px;
  }

  .service-page-question {
    font-size: 1.2rem;
    font-weight: 600;
    color: black;
    min-height: 50px;
    width: 100%;
    border-radius: 10px;
    padding: 30px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
    background-color: aliceblue;
    overflow: scroll;
  }

  .service-page-answer {
    min-height: 50px;
    width: 100%;
    padding-left: 10px;
    padding-right: 15px;
    margin: 20px 5px;
    font-size: 1.2rem;
    overflow: scroll;
  }

  .service-page-chevron-icon {
    transition: transform 0.3s ease;
  }

  .service-page-chevron-icon.rotate {
    transform: rotate(180deg);
  }
}

@media (min-width: 760px) and (max-width: 1200px) {
  .service-page1 {
    padding: 30px;
    background-image: linear-gradient(45deg, rgb(34, 48, 116), rgb(7, 47, 58));
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .service-page1 h1 {
    font-size: 2rem;
    font-weight: 700;
    color: yellow;
  }
  .service-page1-container {
    display: flex;
    justify-content: center;
  }
  .service-page1-container img {
    width: 35%;
  }
  .service-page1-container-mid {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: end;
  }
  .service-page1-container-mid img {
    width: 100%;
  }
  .service-page1-container-end {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
  .service-page-benefit-points {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .service-page-benefit-points img {
    height: 60px;
    width: auto;
  }
  .service-page-benefit-points p {
    font-size: 1.5rem;
    font-weight: 600;
    color: white;
  }
  .service-page-list-for-free {
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: visible;
    margin-top: 20px;
  }
  .service-page-list-for-free button {
    padding: 10px 25px;
    box-shadow: 6px 8px 4px rgb(48, 46, 46, 0.5);
    border-radius: 40px;
    background-color: white;
    font-size: 1.2rem;
    font-weight: 800;
    color: blue;
    cursor: pointer;
  }
  .how-it-works-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 60px;
    align-items: center;
    margin: 50px 0;
  }
  .how-it-works-container h2 {
    font-size: 2rem;
    font-weight: 600;
    color: rgb(13, 71, 161);
  }
  .how-it-works-container-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 100px;
    padding: 30px 0;
  }
  .how-it-works-container-content-left {
    background-color: bisque;
    border-radius: 70px;
    padding: 0px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .how-it-works-container-content-right {
    background-color: bisque;
    border-radius: 70px;
    padding: 10px 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .how-it-works-container-content-left img {
    width: 240px;
    position: relative;
    bottom: 60px;
  }
  .how-it-works-container-content-right img {
    width: 140px;
    position: relative;
    bottom: 60px;
  }

  .how-it-works-container-content-left p,
  .how-it-works-container-content-right p {
    text-align: center;
    position: relative;
    bottom: 20px;
  }
  .service-page-for-buyers {
    font-size: 1.7rem;
    font-weight: 500;
    text-shadow: 1px 1px 1px rgb(91, 89, 89);
    margin-bottom: 10px;
  }
  .service-page-for-buyers-content {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 10px;
  }
  .service-page-signup-text {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 20px 0 5px 0;
  }
  .service-page-for-buyers-button {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .service-page-for-buyers-button button {
    font-size: 1.5rem;
    background-color: rgb(13, 71, 161);
    color: white;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    margin-bottom: 20px;
  }
  .tired-of-dealing-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 1% 6% 1% 5%;
  }
  .tired-of-dealing-container img {
    max-width: 45%;
  }
  .tired-of-dealing-right {
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 30px;
  }
  .tired-of-dealing-right h3 {
    text-align: right;
    color: rgb(39, 97, 229);
    font-size: 2.2rem;
    font-weight: 700;
  }
  .tired-of-dealing-right p {
    font-size: 1.2rem;
    text-align: end;
    color: black;
  }
  .tired-of-dealing-right p span {
    font-weight: 700;
    font-size: 1.3rem;
  }
  .mobile-app-download-buttons {
    display: flex;
    flex-direction: column;
    align-items: end;
  }
  .service-page-features-container {
    display: flex;
    flex-direction: column;
    gap: 50px;
    width: 90%;
    margin: 5%;
    background-color: aliceblue;
    border-radius: 30px;
    padding: 40px 10px 10px 10px;
  }
  .service-page-feature-bars {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  .service-page-features-left,
  .service-page-features-middle,
  .service-page-features-right {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .service-page-features-left p,
  .service-page-features-middle p,
  .service-page-features-right p {
    text-align: center;
    font-size: 1.2rem;
    color: black;
    margin: 30px 50px;
  }
  .service-page-features-left img,
  .service-page-features-middle img,
  .service-page-features-right img {
    width: min(300px, 90%);
  }
  .service-page-features-top {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .service-page-features-top img {
    height: 50px;
    width: auto;
  }
  .service-page-features-top h3 {
    font-size: 1.4rem;
    color: rgb(13, 71, 161);
    font-weight: 600;
  }
  .find-cheap-car-insurance-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px 80px;
    align-items: center;
    gap: 30px;
  }
  .find-cheap-car-insurance-left {
    width: 95%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 50px;
    background-color: aliceblue;
    padding: 30px 70px;
  }
  .find-cheap-car-insurance-left h2 {
    text-align: center;
    font-size: 1.3rem;
    font-weight: 600;
    color: black;
  }
  .find-cheap-car-insurance-left p {
    font-size: 0.8rem;
  }
  .find-cheap-car-insurance-right {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .find-cheap-car-insurance-right div {
    display: flex;
    flex-direction: column;
    width: 90%;
    padding: 10px;
    gap: 10px;
  }
  .find-cheap-car-insurance-right div p {
    text-align: center;
    font-size: 1rem;
  }
  .find-cheap-car-insurance-right button {
    padding: 10px;
    border: none;
    border-radius: 30px;
    background-color: rgb(13, 71, 161);
    color: white;
    font-size: 1.2rem;
    font-weight: 600;
  }
  .service-page-people-talks {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px 0;
  }
  .service-page-people-talks h2 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 30px;
    width: 90%;
    margin-left: 5%;
  }
  .service-page-people-talks div {
    width: 96%;
    margin-left: 2%;
  }
  .service-page-qa-item {
    min-height: 50px;
    width: 100%;
    color: black;
    margin-bottom: 10px;
  }

  .service-page-question {
    font-size: 1.2rem;
    font-weight: 600;
    color: black;
    min-height: 50px;
    width: 100%;
    border-radius: 10px;
    padding: 30px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
    background-color: aliceblue;
    overflow: scroll;
  }

  .service-page-answer {
    min-height: 50px;
    width: 100%;
    padding-left: 10px;
    padding-right: 15px;
    margin: 20px 5px;
    font-size: 1.2rem;
    overflow: scroll;
  }

  .service-page-chevron-icon {
    transition: transform 0.3s ease;
  }

  .service-page-chevron-icon.rotate {
    transform: rotate(180deg);
  }
}
@media (max-width: 760px) {
  .service-page1 {
    padding: 30px;

    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .service-page1 h1 {
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
    color: black;
  }
  .service-page1-container {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  .service-page1-container img {
    width: 100%;
  }
  .service-page1-container-mid {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: end;
  }
  .service-page1-container-mid img {
    width: 100%;
  }
  .service-page1-container-end {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
  .service-page-benefit-points {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .service-page-benefit-points img {
    height: 60px;
    width: auto;
  }
  .service-page-benefit-points p {
    font-size: 1.5rem;
    font-weight: 600;
    color: white;
  }
  .service-page-list-for-free {
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: visible;
    margin-top: 20px;
  }
  .service-page-list-for-free button {
    padding: 10px 25px;
    box-shadow: 6px 8px 4px rgb(48, 46, 46, 0.5);
    border-radius: 40px;
    background-color: white;
    font-size: 1.2rem;
    font-weight: 800;
    color: blue;
    cursor: pointer;
  }
  .how-it-works-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 60px;
    align-items: center;
    margin: 10px 0;
  }
  .how-it-works-container h2 {
    font-size: 2rem;
    font-weight: 600;
    color: rgb(13, 71, 161);
  }
  .how-it-works-container-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 100px;
    padding: 30px 0;
  }
  .how-it-works-container-content-left {
    background-color: bisque;
    border-radius: 50px;
    padding: 0px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .how-it-works-container-content-right {
    background-color: bisque;
    border-radius: 50px;
    padding: 10px 55px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .how-it-works-container-content-left img {
    width: 240px;
    position: relative;
    bottom: 60px;
  }
  .how-it-works-container-content-right img {
    width: 140px;
    position: relative;
    bottom: 60px;
  }

  .how-it-works-container-content-left p,
  .how-it-works-container-content-right p {
    text-align: center;
    position: relative;
    bottom: 20px;
  }
  .service-page-for-buyers {
    font-size: 1.7rem;
    font-weight: 500;
    text-shadow: 1px 1px 1px rgb(91, 89, 89);
    margin-bottom: 10px;
  }
  .service-page-for-buyers-content {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 10px;
  }
  .service-page-signup-text {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 20px 0 5px 0;
  }
  .service-page-for-buyers-button {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .service-page-for-buyers-button button {
    font-size: 1.5rem;
    background-color: rgb(13, 71, 161);
    color: white;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    margin-bottom: 20px;
  }
  .tired-of-dealing-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: 40px;
    padding: 1% 6% 1% 5%;
  }
  .tired-of-dealing-container img {
    max-width: 100%;
  }
  .tired-of-dealing-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
  .tired-of-dealing-right h3 {
    text-align: center;
    color: rgb(39, 97, 229);
    font-size: 2.2rem;
    font-weight: 700;
  }
  .tired-of-dealing-right p {
    font-size: 1.2rem;
    text-align: center;
    color: black;
  }
  .tired-of-dealing-right p span {
    font-weight: 700;
    font-size: 1.3rem;
  }
  .mobile-app-download-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
  }
  .mobile-app-download-buttons img {
    width: 160px;
  }
  .service-page-features-container {
    display: flex;
    flex-direction: column;
    gap: 50px;
    width: 90%;
    margin: 5%;
    background-color: aliceblue;
    border-radius: 30px;
    padding: 40px 10px 10px 10px;
  }
  .service-page-feature-bars {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  .service-page-features-left,
  .service-page-features-middle,
  .service-page-features-right {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .service-page-features-left p,
  .service-page-features-middle p,
  .service-page-features-right p {
    text-align: center;
    font-size: 1.2rem;
    color: black;
    margin: 30px 50px;
  }
  .service-page-features-left img,
  .service-page-features-middle img,
  .service-page-features-right img {
    width: min(300px, 90%);
  }
  .service-page-features-top {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .service-page-features-top img {
    height: 50px;
    width: auto;
  }
  .service-page-features-top h3 {
    width: auto;
    font-size: 1.4rem;
    color: rgb(13, 71, 161);
    font-weight: 600;
  }
  .find-cheap-car-insurance-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px 5px;
    align-items: center;
    gap: 30px;
  }
  .find-cheap-car-insurance-left {
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    border-radius: 30px;
    background-color: aliceblue;
    padding: 20px 15px;
  }
  .find-cheap-car-insurance-left h2 {
    text-align: center;
    font-size: 1.3rem;
    font-weight: 600;
    color: black;
  }
  .find-cheap-car-insurance-left p {
    font-size: 1rem;
  }
  .find-cheap-car-insurance-right {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .find-cheap-car-insurance-right div {
    display: flex;
    flex-direction: column;
    width: 90%;
    padding: 10px;
    gap: 10px;
  }
  .find-cheap-car-insurance-right div p {
    text-align: center;
    font-size: 1rem;
  }
  .find-cheap-car-insurance-right button {
    padding: 10px;
    border: none;
    border-radius: 30px;
    background-color: rgb(13, 71, 161);
    color: white;
    font-size: 1.2rem;
    font-weight: 600;
  }
  .service-page-people-talks {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px 0;
  }
  .service-page-people-talks h2 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 30px;
    width: 90%;
    margin-left: 5%;
  }
  .service-page-people-talks div {
    width: 96%;
    margin-left: 2%;
  }
  .service-page-qa-item {
    min-height: 50px;
    width: 100%;
    color: black;
    margin-bottom: 10px;
  }

  .service-page-question {
    font-size: 1.2rem;
    font-weight: 600;
    color: black;
    min-height: 50px;
    width: 100%;
    border-radius: 10px;
    padding: 30px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
    background-color: aliceblue;
    overflow: scroll;
  }

  .service-page-answer {
    min-height: 50px;
    width: 100%;
    padding-left: 10px;
    padding-right: 15px;
    margin: 20px 5px;
    font-size: 1.2rem;
    overflow: scroll;
  }

  .service-page-chevron-icon {
    transition: transform 0.3s ease;
  }

  .service-page-chevron-icon.rotate {
    transform: rotate(180deg);
  }
}
