.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full height */
  background-color: #f4f4f4; /* Light grey background */
  color: #333; /* Dark text */
  text-align: center;
}

.not-found-title {
  font-size: 6rem; /* Large font size for the 404 */
  font-weight: bold;
  color: #e74c3c; /* Red color for emphasis */
  margin: 0;
}

.not-found-message {
  font-size: 1.5rem; /* Medium font size for the message */
  margin: 20px 0; /* Margin for spacing */
}

.not-found-link {
  text-decoration: none; /* No underline */
  padding: 10px 20px; /* Padding for the button */
  background-color: #3498db; /* Blue background */
  color: white; /* White text */
  border-radius: 5px; /* Rounded corners */
  transition: background-color 0.3s; /* Smooth transition */
}

.not-found-link:hover {
  background-color: #2980b9; /* Darker blue on hover */
}
